import React from 'react';
import BaseComponent from './../../baseComponent';
import IPageDesign from '../../../interfaces/IPageDesign';
import IFormError from '../../forms/IFormError';
import { Accordion, Card } from 'react-bootstrap';
import Textarea from '../../inputs/textarea';
import SelectBox from '../../inputs/selectBox';
import LanguageHelper from '../../../helpers/languageHelper';
import InputText from '../../inputs/inputText';
import ISource from '../../../interfaces/ISource';
import ITranslationCosts from '../../../interfaces/ITranslationCosts';
import { TranslationCostsTable } from '../translationCostsTable';
import { TranslationPathCostsRow } from '../translationPathCostsRow';
import LoadingScreen from '../../screens/loadingScreen';
import { SeparateThousends } from '../../others/separateThousends';
import { round } from 'lodash';
import IOptionData from '../../inputs/IOptionData';
import { TranslationApi } from '../../../enums/translationApi';
import CheckBox from '../../inputs/checkBox';

interface IState {
    translationCosts: ITranslationCosts,
    loading: {
        translation: boolean,
    }
}

interface IProps {
    pageDesignEdit: IPageDesign,
    pageDesignServer: IPageDesign,
    onChange(value: any, key: string): void,
    serverErrors: IFormError[],
    source: ISource,
}

export default class SourcePageDesignTranslationCard extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            translationCosts: null,
            loading: {
                translation: false
            }
        }
    }

    componentDidMount() {
        const pageDesignEdit = this.props.pageDesignEdit;
        const paths = pageDesignEdit.translation === null ? [] : (pageDesignEdit.translation.paths || []);
        this.getTranslationCosts(paths);
    }

    getTranslationCosts(paths: string[]) {
        if ((paths || []).length === 0) {
            return;
        }

        const source = this.props.source;
        this.setState({
            loading: {
                ...this.state.loading,
                translation: true
            }
        })

        this.sourcesApiService.calculateTranslationCosts(source.id, paths)
            .then(translationCosts => {
                this.setState({
                    loading: {
                        ...this.state.loading,
                        translation: false,
                    },
                    translationCosts: translationCosts
                })
            })
    }

    validateForm() {
        const inputs: any = [

        ];
        const isValid = this.validateInputs(inputs);
        return isValid;
    }

    getTranslationApiOptions(): IOptionData[] {
        return [
            { label: 'Google Translate Api', value: TranslationApi.GoogleTranslateApi, },
            { label: 'Lindat Api', value: TranslationApi.LindatApi, }
        ]
    }

    renderTranslationCosts() {
        const { loading, translationCosts } = this.state;

        if (loading.translation) {
            return <LoadingScreen />
        }
        else if (translationCosts) {
            return <TranslationCostsTable>
                {translationCosts.translationPathCosts.map(cost => {
                    return <TranslationPathCostsRow key={cost.path} translationPathCost={cost} />
                })}

                <tr>
                    <td className="font-weight-bold">Celkem</td>

                    <td className="font-weight-bold">
                        <SeparateThousends number={translationCosts.totalCharacters} />
                    </td>

                    <td className="font-weight-bold">
                        <SeparateThousends number={round(translationCosts.totalEstimatedCost, 1)} /> USD (<SeparateThousends number={round(translationCosts.totalEstimatedCost * 21.6, 0)} /> CZK)
                    </td>
                </tr>
            </TranslationCostsTable>
        }
        else {
            return <p>Žádné cesty k překladu.</p>
        }
    }

    render() {
        const pageDesignEdit = this.props.pageDesignEdit;
        var onChange = this.props.onChange;
        const translation = pageDesignEdit.translation;
        const paths = translation === null ? [] : (translation.paths || []);
        const doNotTranslate = translation === null ? [] : (translation.doNotTranslate || []);
        const fromLanguage = translation === null ? null : (translation.fromLanguage || null);
        const translationApi = translation === null ? 0 : (translation.translationApi || 0);

        return (
            <Card className="mb-0 pb-0">
                <Accordion.Toggle as={Card.Header} className="clickable" eventKey="translation">
                    Překlady <span className="float-right"><i className="fas fa-angle-down ml-2 mt-1"></i></span>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="translation">
                    <Card.Body>
                        <SelectBox
                            uid="translationApi"
                            value={translationApi}
                            defaultValue="Vyberte"
                            label="API pro překlad"
                            options={this.getTranslationApiOptions()}
                            onChange={onChange.bind(this)}
                        />

                        <SelectBox
                            uid="fromLanguage"
                            value={fromLanguage}
                            defaultValue="Vyberte"
                            label="Přeložit z jazyka"
                            options={LanguageHelper.getLanguageOptions()}
                            onChange={onChange.bind(this)}
                        />

                        {translationApi === TranslationApi.LindatApi &&
                            <>
                                <CheckBox
                                    uid="reverseTranslation"
                                    label="Reverzní překlad"
                                    onChange={onChange.bind(this)}
                                    checked={translation.reverseTranslation}
                                />

                                <CheckBox
                                    uid="ignoreSavedTranslations"
                                    label="Ignorovat uložené překlady"
                                    onChange={onChange.bind(this)}
                                    checked={translation.ignoreSavedTranslations}
                                />
                            </>}

                        <Textarea
                            uid="paths"
                            label="Vložte cesty k překladu (na každý řádek jednu)"
                            value={(paths || []).map(path => path || '').join('\n').toString()}
                            onChange={(value: string, key: string) => onChange(value.split('\n'), key)}
                            onBlur={() => this.getTranslationCosts(paths)}
                        />

                        {translationApi === TranslationApi.GoogleTranslateApi && < InputText
                            uid="doNotTranslate"
                            label="Vložte klíčové slova, která nechcete překládat (oddělte čárkou)"
                            value={(doNotTranslate || []).map(keyword => keyword || '').join(',').toString()}
                            onChange={(value: string, key: string) => onChange(value.split(','), key)}
                        />}

                        <hr />

                        <h5 className="card-title">Náklady na překlad</h5>
                        {this.renderTranslationCosts()}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>)
    }
}