import React from 'react';
import { orderBy } from 'lodash';
import BaseComponent from './../baseComponent';
import ISource from '../../interfaces/ISource';
import LoadingScreen from '../screens/loadingScreen';
import IWebsite from '../../interfaces/IWebsite';
import ISourceElement from '../../interfaces/ISourceElement';

interface IState {
    loading: boolean,
    elements: ISourceElement[],
    selectedElement: ISourceElement,
    code: any
}

interface IProps {
    source: ISource,
    website: IWebsite,
    goToNextStep():void,
}

export default class SourceSelectElementStep extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            elements: [],
            selectedElement: null,
            code: null,
        }
    }

    componentDidMount() {
        const source = this.props.source;

        this.sourcesApiService.getSourceElements(source.id)
            .then(elements => {
                this.setState({
                    loading: false,
                    elements: elements
                })
            })
    }

    handleSelectElement(element: ISourceElement) {
        const source = this.props.source;

        this.sourcesApiService.getSourceElementJson(source.id, element.name)
            .then(json => {
                this.setState({
                    selectedElement: element,
                    code: json
                })
            })
    }

    handleSubmit() {
        const source = this.props.source;
        const selectedElement = this.state.selectedElement;

        this.setState({
            loading: true
        })

        this.sourcesApiService.setSourceRootElement(source.id, selectedElement.name)
            .then(data => {
                this.props.goToNextStep();
            })
    }

    render() {
        const loading = this.state.loading;
        const elements = orderBy(this.state.elements, ['count'], ['desc']);
        const selectedElement = this.state.selectedElement;

        if (loading) {
            return <LoadingScreen />;
        }

        return (
            <>
                <div className="text-center">
                    <div className="mb-5 mt-4">
                        <h4>Vyberte element</h4>
                        <p>Vybraný element bude sloužit k vytvoření vašeho obsahu.</p>
                    </div>
                </div>

                <div className="row"
                    style={{ borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray' }}
                >
                    <div className={`${selectedElement ? 'col-12 col-md-4' : 'col-12'} col-elements p-0`}>
                        <ul className="list-group" style={{ height: '660px', overflowY: 'scroll', fontSize: '13px' }}>
                            {elements.map(element => {
                                const isSelected = element === selectedElement;

                                return <li
                                    key={element.name}
                                    className={`list-group-item ${isSelected ? ' list-group-item-primary' : 'list-group-item-light'}`}
                                    onClick={this.handleSelectElement.bind(this, element)}
                                >
                                    {element.name}
                                    <span className="float-right">{element.count}x</span>
                                </li>
                            })}
                        </ul>
                    </div>

                    <div className={`col-12 col-md-8 p-0 ${!selectedElement ? 'd-none' : ''}`}>
                        {selectedElement &&
                            <>
                                < pre
                                    className="position-relative"
                                    style={{ height: '600px', overflowY: 'scroll', backgroundColor: '#b8daff', color: 'black', padding: '20px', fontSize: '12px' }}
                                >
                                    {JSON.stringify(this.state.code, null, 2)}

                                </pre>
                                <div className="position-absolute"
                                    style={{ bottom: 0, width: '100%', height: '60px', backgroundColor: '#f1f1f1', paddingRight: '20px', paddingTop: '15px', paddingLeft: '0px' }}
                                >
                                    <div className="btn-group float-left">
                                        <button className="btn btn-light">
                                            <i className="fas fa-angle-left"></i>
                                        </button>
                                        <button className="btn btn-light">
                                            <i className="fas fa-angle-right"></i>
                                        </button>
                                    </div>

                                    {/* <p className="ml-2">Z tohoto elementu vytvoříte {selectedElement.count} položek obsahu</p> */}

                                <button
                                    className="btn btn-primary float-right"
                                    onClick={this.handleSubmit.bind(this)}
                                >
                                    Potvrdit výběr
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>)
    }
}