import React from 'react';
import IFormError from '../../components/forms/IFormError';
import { BtnSubmit } from '../../components/inputs/btnSubmit';
import InputText from '../../components/inputs/inputText';
import { CustomDocumentTitle } from '../../utils/customDocumentTitle';
import BaseComponent from './../../components/baseComponent';
import { history } from './../../App';
import { FormErrorSummary } from '../../components/forms/FormErrorSummary';
import SelectBox from '../../components/inputs/selectBox';
import IInputField from '../../components/inputs/IInputField';
import { filter, isNil } from 'lodash';
import LanguageHelper from '../../helpers/languageHelper';
import { Language } from '../../enums/language';

interface IState {
    form: {
        name: string,
        domain: string,
        isCustomDomain: string,
        language: Language,
    },
    loading: boolean,
    serverErrors: IFormError[],
}

interface IProps {

}

export default class CreateWebsitePage extends BaseComponent<IProps, IState> {

    private inputName = React.createRef<InputText>();
    private inputLanguage = React.createRef<SelectBox>();
    private inputDomain = React.createRef<InputText>();
    private inputDomainType = React.createRef<SelectBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            form: {
                name: '',
                domain: '',
                isCustomDomain: null,
                language: null,
            },
            loading: false,
            serverErrors: [],
        }

        this.inputName = React.createRef();
        this.inputLanguage = React.createRef();
        this.inputDomain = React.createRef();
        this.inputDomainType = React.createRef();
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false  })
        })
    }

    handleSubmit() {
        const form = this.state.form;

        let inputs: React.RefObject<IInputField>[] = [
            this.inputName,
            this.inputLanguage,
            this.inputDomainType,
        ]

        if (!isNil(form.isCustomDomain)) {
            inputs.push(this.inputDomain)
        }

        let domain = form.domain;

        const isValid = this.validateInputs(inputs);
        const isCustomDomain: boolean = (form.isCustomDomain === 'true');

        if (!isCustomDomain) {
            domain = `${domain}.clonty.dev`;
        }

        if (isValid) {
            this.setState({
                loading: true
            })

            this.websitesApiService.create(form.name, domain, isCustomDomain, form.language)
                .then(data => {
                    if (data.success === true) {
                        history.push('/websites');
                    }
                    else {
                        this.setState({
                            loading: false
                        })

                        this.setState({
                            serverErrors: data.formErrors,
                        })

                        this.validateInputs(inputs);
                    }
                })
        }
    }

    validateCustomDomain(key: string, value: string, errors: Array<string>, validateInput: Function) {
        const regexExp = new RegExp(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/);

        if (!regexExp.test(value)) {
            errors.push('Doména je neplatná')
        }
        return validateInput(value, errors);
    }

    validateTestDomain(key: string, value: string, errors: Array<string>, validateInput: Function) {
        const regexExp = new RegExp(/^[\da-z-]*$/);

        if (!regexExp.test(value)) {
            errors.push('Doménové jméno není validní. Zadejte jenom část domény. Např. reality nebo autopujcovna-praha')
        }
        return validateInput(value, errors);
    }

    render() {
        const form = this.state.form;
        const serverErrors = this.state.serverErrors;

        return (
            <>
                <CustomDocumentTitle title="Vytvořit nový web">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="dashboard-header-title">
                                <h5 className="mb-0">Vytvořit nový web</h5>
                            </div>

                            <div className="card mt-4">
                                <div className="card-body">
                                    <InputText
                                        uid={'name'}
                                        ref={this.inputName}
                                        label="Veřejný název webu"
                                        value={form.name}
                                        required
                                        onChange={this.handleChange.bind(this)}
                                        maxLength={50}
                                        minLength={3}
                                    />

                                    <SelectBox
                                        uid="language"
                                        ref={this.inputLanguage}
                                        label="Jazyk"
                                        value={form.language}
                                        options={LanguageHelper.getLanguageOptions()}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        defaultValue="Vyberte jazyk"
                                    />

                                    <SelectBox
                                        uid='isCustomDomain'
                                        ref={this.inputDomainType}
                                        label="Doména"
                                        defaultValue="Vyberte"
                                        options={[
                                            { label: 'Použít vlastní doménu', value: true },
                                            { label: 'Použít testovací doménu', value: false }
                                        ]}
                                        required
                                        onChange={this.handleChange.bind(this)}
                                        value={form.isCustomDomain}
                                    />


                                    {form.isCustomDomain === 'true' &&
                                        <div style={{ minWidth: '200px', maxWidth: '50%' }}>
                                            <InputText
                                                uid='domain'
                                                ref={this.inputDomain}
                                                label="Vaše doména"
                                                value={form.domain}
                                                required
                                                placeholder="např. mujweb.cz"
                                                onChange={this.handleChange.bind(this)}
                                                validate={this.validateCustomDomain.bind(this)}
                                                serverErrors={serverErrors}
                                            />
                                        </div>
                                    }

                                    {form.isCustomDomain === 'false' &&
                                        <>
                                            <div style={{ minWidth: '210px', maxWidth: '50%' }}>
                                                <InputText
                                                    uid='domain'
                                                    ref={this.inputDomain}
                                                    label="Zvolte testovací doménu"
                                                    value={form.domain}
                                                    required
                                                    placeholder="např. mujweb"
                                                    inputAdded=".clonty.dev"
                                                    onChange={this.handleChange.bind(this)}
                                                    validate={this.validateTestDomain.bind(this)}
                                                    serverErrors={serverErrors}
                                                    minLength={3}
                                                />

                                            </div>
                                            <div className="alert alert-secondary">
                                                <small>
                                                    {form.domain && <span>
                                                        Váš web umístíme na testovací doménu <a target="_blank" href={`https://${form.domain}.clonty.dev`}>https://{form.domain}.clonty.dev</a>
                                                    </span>}
                                                    <span className="d-block">Vlastní doménu můžete nastavit kdykoliv později.</span>
                                                </small>
                                            </div>
                                        </>
                                    }

                                    <FormErrorSummary errors={filter(this.state.serverErrors, { key: '' })} />

                                    <BtnSubmit
                                        loading={this.state.loading}
                                        onClick={this.handleSubmit.bind(this)}
                                    >
                                        Vytvořit web
                                    </BtnSubmit>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomDocumentTitle>
            </>
        )
    }
}