import React from 'react';
import BaseComponent from './../../baseComponent';
import InputText from '../../inputs/inputText';
import EditorConvertToHTML from '../../others/editorConvertToHTML';
import IPageDesign from '../../../interfaces/IPageDesign';
import IFormError from '../../forms/IFormError';
import { Card } from 'react-bootstrap';
import SelectBox from '../../inputs/selectBox';
import { PageDateType } from '../../../enums/pageDateType';
import InputDatePicker from '../../inputs/inputDatePicker';
import { isNil } from 'lodash';
import Textarea from '../../inputs/textarea';
import ErrorBoundary from '../../../utils/ErrorBoundary';

interface IState {
    rawHtmlEditor: boolean,
}

interface IProps {
    pageDesignEdit: IPageDesign,
    pageDesignServer: IPageDesign,
    onChange(value: any, key: string): void,
    serverErrors: IFormError[],
}

export default class SourcePageDesignBasicCard extends BaseComponent<IProps, IState> {

    private inputUid: React.RefObject<InputText>;
    private inputName: React.RefObject<InputText>;
    private inputSlug: React.RefObject<InputText>;
    private inputLayout: React.RefObject<InputText>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            rawHtmlEditor: false,
        }

        this.inputUid = React.createRef();
        this.inputName = React.createRef();
        this.inputSlug = React.createRef();
        this.inputLayout = React.createRef();
    }

    validateForm() {
        const inputs: any = [
            this.inputUid,
            this.inputName,
            this.inputSlug,
        ];

        const isValid = this.validateInputs(inputs);

        return isValid;
    }

    handleChangePageDate(value: any, key: string) {
        let pageDate = this.props.pageDesignEdit.pageDate;
        if (isNil(pageDate)) {
            pageDate = {
                path: '',
                startDate: null,
                type: null,
                pathFormat: '',
            }
        }
        // @ts-ignore
        pageDate[key] = value;
        this.props.onChange(pageDate, 'pageDate');
    }

    handleSwitchToRawHtmlEditor() {
        this.setState({
            rawHtmlEditor: true
        })
    }

    handleSwitchToWysiwygEditor() {
        this.setState({
            rawHtmlEditor: false
        })
    }

    render() {
        const pageDesignEdit = this.props.pageDesignEdit;
        const pageDesignServer = this.props.pageDesignServer;
        var onChange = this.props.onChange;
        const serverErrors = this.props.serverErrors;
        const rawHtmlEditor = this.state.rawHtmlEditor;

        return (
            <Card className="mb-0 pb-0">

                <Card.Body>
                    <InputText
                        uid="uid"
                        ref={this.inputUid}
                        label="Identifikátor"
                        onChange={onChange.bind(this)}
                        value={pageDesignEdit.uid}
                        maxLength={60}
                        readonly={(pageDesignServer.uid || '').length > 0}
                        required
                    />

                    <InputText
                        uid="name"
                        ref={this.inputName}
                        label="Nadpis"
                        onChange={onChange.bind(this)}
                        serverErrors={serverErrors}
                        value={pageDesignEdit.name}
                        required
                    />

                    <InputText
                        uid="slug"
                        ref={this.inputSlug}
                        label="Slug"
                        onChange={onChange.bind(this)}
                        serverErrors={serverErrors}
                        value={pageDesignEdit.slug}
                        required
                    />

                    <SelectBox
                        uid={"type"}
                        label="Datum"
                        value={pageDesignEdit.pageDate ? (pageDesignEdit.pageDate.type ? pageDesignEdit.pageDate.type : null) : null}
                        options={
                            [
                                { label: 'Náhodné datum', value: PageDateType.RandomDate },
                                { label: 'Cesta', value: PageDateType.ByPath },
                                { label: 'Aktuální datum', value: PageDateType.CurrentDate }
                            ]
                        }
                        onChange={this.handleChangePageDate.bind(this)}
                        defaultValue="Vyberte"
                    />


                    <ErrorBoundary>
                        {pageDesignEdit.pageDate.type === PageDateType.ByPath &&
                            <>
                                <div className="row">
                                    <div className="col">
                                        <InputText
                                            uid="path"
                                            label="Vložte cestu s datem"
                                            onChange={this.handleChangePageDate.bind(this)}
                                            value={pageDesignEdit.pageDate.path}
                                        />
                                    </div>
                                    <div className="col">
                                        <InputText
                                            uid="pathFormat"
                                            label="Formát"
                                            onChange={this.handleChangePageDate.bind(this)}
                                            value={pageDesignEdit.pageDate.pathFormat}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </ErrorBoundary>


                    {pageDesignEdit.pageDate.type === PageDateType.RandomDate &&
                        <InputDatePicker
                            uid="startDate"
                            required
                            label="Náhodné datum od"
                            date={pageDesignEdit.pageDate.startDate}
                            onChange={this.handleChangePageDate.bind(this)}
                        />
                    }


                    {rawHtmlEditor ? <>
                        <p onClick={this.handleSwitchToWysiwygEditor.bind(this)}>Přepnout na editor</p>
                        <Textarea
                            uid="description"
                            label="Popis"
                            value={pageDesignEdit.description}
                            onChange={onChange.bind(this)}
                        />
                    </> :
                        <>
                            <p onClick={this.handleSwitchToRawHtmlEditor.bind(this)}>Přepnout na raw</p>
                            <EditorConvertToHTML
                                uid="description"
                                value={pageDesignEdit.description}
                                onChange={onChange.bind(this)}
                            />
                        </>}

                    <InputText
                        uid="layout"
                        label="Layout"
                        value={pageDesignEdit.layout}
                        onChange={onChange.bind(this)}
                    />
                </Card.Body>
            </Card>)
    }
}