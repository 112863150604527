import React from 'react';
import { Modal } from 'react-bootstrap';
import IWebsite from '../../interfaces/IWebsite';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import { history } from '../../App';
import SelectBox from '../inputs/selectBox';
import SectionHelper from '../../helpers/sectionHelper';
import ISection from '../../interfaces/ISection';

interface IState {
    form: {
        name: string,
        sectionId: string,
    }
    loading: boolean,
}

interface IProps {
    website: IWebsite,
    sections: ISection[],
    onHide(): void,
}

export default class CreateNewPageModal extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputSection: React.RefObject<SelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            form: {
                name: '',
                sectionId: '',
            },
            loading: false,
        }

        this.inputName = React.createRef();
        this.inputSection = React.createRef();
    }

    handleSubmit() {
        const isValid = this.validateInputs([
            this.inputName,
            this.inputSection,
        ])

        const website = this.props.website;
        const form = this.state.form;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.websitesApiService.createPage(website.id, form.name, form.sectionId)
                .then(data => {
                    const page = data.records.pages.items[0];

                    if (data.success) {
                        history.push(`/websites/${website.id}/pages/${page.id}/edit`);
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                    }      
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    render() {
        const loading = this.state.loading;
        const form = this.state.form;
        const sections = this.props.sections;

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Vytvořit novou stránku</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputText
                        uid="name"
                        ref={this.inputName}
                        label="Název"
                        onChange={this.handleChange.bind(this)}
                        value={form.name}
                        required
                        maxLength={30}
                    />

                    <SelectBox
                        ref={this.inputSection}
                        uid="sectionId"
                        label="Sekce"
                        defaultValue="Vyberte"
                        value={form.sectionId}
                        options={SectionHelper.getSectionOptions(sections)}
                        onChange={this.handleChange.bind(this)}
                        required
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Pokračovat  <i className="fas fa-angle-double-right ml-1"></i>
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}