import React from 'react';
import { ClipLoader } from 'react-spinners';
import BaseComponent from './../baseComponent';

interface IState {

}

interface IProps {
    withCard?: boolean,
}

export default class LoadingScreen extends BaseComponent<IProps, IState> {

    render() {
        return (
            <div className="py-5">
                <div className="d-flex justify-content-center mb-3 mt-2">
                    <div className="sweet-loading">
                        <ClipLoader
                            size={60}
                            color={"#449DF7"}
                            loading={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}