import React from 'react';
import BaseComponent from './../baseComponent';
import ISource from '../../interfaces/ISource';
import IWebsite from '../../interfaces/IWebsite';
import IPageDesign from '../../interfaces/IPageDesign';
import IFormError from '../forms/IFormError';
import { BtnSubmit } from '../inputs/btnSubmit';
import CheckBox from '../inputs/checkBox';
import { history } from './../../App';
import IInputField from '../inputs/IInputField';
import { filter } from 'lodash';

interface IState {
    pageDesign: IPageDesign,
    serverErrors: IFormError[],
    loadingSubmit: boolean,
    form: {
        allowCreateNewPages: boolean,
        allowDeletePages: boolean,
        allowUpdatePages: boolean,
    }
}

interface IProps {
    source: ISource,
    website: IWebsite,
    goToStepBack(): void,
}

export default class SourceImportStep extends BaseComponent<IProps, IState> {

   
    constructor(props: IProps) {
        super(props);

        const source = this.props.source;

        this.state = {
            pageDesign: this.props.source.pageDesign,
            serverErrors: [],
            loadingSubmit: false,
            form: {
                allowCreateNewPages: source.allowCreateNewPages,
                allowDeletePages: source.allowDeletePages,
                allowUpdatePages: source.allowUpdatePages,
            }
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key.toLowerCase()) return true; else return false })
        })
    }

    handleSubmit() {
        const source = this.props.source;
        const form = this.state.form;

        let inputs: React.RefObject<IInputField>[] = [
            
        ]

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            this.setState({
                loadingSubmit: true
            })

            this.sourcesApiService.editImportSettings(source.id, form.allowCreateNewPages, form.allowDeletePages, form.allowUpdatePages)
                .then(data => {
                    if (data.success) {
                        history.push(`/websites/${source.websiteId}/sources`);
                    }
                    else {
                        this.setState({
                            loadingSubmit: false,
                            serverErrors: data.formErrors
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    render() {
        const { loadingSubmit, form } = this.state;

        return (
            <>
                <div className="mb-5 mt-4 text-center">
                    <h4>Nastavení importu</h4>
                    <p>Nastavte chování během importu.</p>
                </div>

                <div className="row py-5">
                    <div className="col-12 col-md-8 col-lg-6">
                        <h6 className="mt-5">Pokud se objeví nová nebo pozměněná data...</h6>
                        < hr />
                        <CheckBox
                            uid="allowCreateNewPages"
                            label="Z nových dat vytvořit nové stránky"
                            checked={form.allowCreateNewPages}
                            onChange={this.handleChange.bind(this)}
                        />

                        <CheckBox
                            uid="allowDeletePages"
                            label="Smazat stránky, jejichž obsah se již nevyskytuje v datech"
                            checked={form.allowDeletePages}
                            onChange={this.handleChange.bind(this)}
                        />

                        <CheckBox
                            uid="allowUpdatePages"
                            label="Aktualizovat existující stránky z nových dat"
                            checked={form.allowUpdatePages}
                            onChange={this.handleChange.bind(this)}
                        />
                    </div>
                </div>

                <button
                    className="btn btn-secondary float-left"
                    onClick={this.props.goToStepBack.bind(this)}
                >
                    Zpět
                </button>

                <BtnSubmit
                    className="btn btn-primary float-right"
                    onClick={this.handleSubmit.bind(this)}
                    loading={loadingSubmit}
                >
                    Uložit nastavení
                </BtnSubmit>
            </>)
    }
}