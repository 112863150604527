import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import ImagesHelper from "../../helpers/imagesHelper";
import IImage from "../../interfaces/IImage";
import ImagesApiService from "../../services/imagesApiService";
import WebsiteStore from "../../stores/WebsiteStore";
import { BtnSubmit } from "../inputs/btnSubmit";
import { ShowDate } from "../others/showDate";
import { SetImageVariableModal } from "./setImageVariableModal";

interface IProps {
    image: IImage,
    onHide(): void,
    websiteStore?: WebsiteStore
}

const imagesApiService = new ImagesApiService();

export const ImageDetailModal = inject("websiteStore")(observer((props: IProps) => {
    const { image, onHide } = props;

    const website = props.websiteStore.getById(image.websiteId);
    const url = ImagesHelper.getImageUrl(image, website);

    useEffect(() => {

        return imagesApiService.cancelRequests();
    }, [])

    const [showVariableModal, setShowVariableModal] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    function deleteImage() {
        Swal.fire({
            title: 'Opravdu smazat tento obrázek?',
            text: 'Tato akce je nevratná.',
            confirmButtonText: 'Ano, smazat',
            showCloseButton: true,
            icon: 'question',

        }).then(result => {
            if (result.value) {

                setLoadingDelete(true);
                imagesApiService.deleteImage(image.id)
                    .then(data => {
                        if (data.success) {
                            onHide();
                        }
                    })
                    .finally(() => {
                        setLoadingDelete(false);
                    })
            }
        })
    }

    return (
        <Modal animation={false} size="xl" centered={true} show onHide={onHide}>
            <Modal.Header closeButton onHide={onHide.bind(this)}>
                <Modal.Title>
                    <h5>Podrobné informace</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-8">
                        <img src={url} className="img-fluid" loading="lazy" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-4">

                        {showVariableModal && <SetImageVariableModal image={image} onHide={() => setShowVariableModal(false)} />}

                        <div className="btn-group mb-4">
                            <BtnSubmit
                                className="btn btn-danger"
                                loading={loadingDelete}
                                onClick={deleteImage}
                            >
                                Smazat obrázek
                            </BtnSubmit>

                            <button
                                className="btn btn-primary"
                                onClick={() => setShowVariableModal(true)}
                            >
                                {!image.isVariable ? 'Nastavit jako proměnnou' : 'Upravit proměnnou'}
                            </button>

                        </div>

                        <h6>Úplná cesta k obrázku</h6>
                        <hr />
                        <p className="text-break">{url}</p>

                        <h6>Rozměry</h6>
                        <hr />
                        <p>{image.width}x{image.height} px</p>

                        <h6>Datum nahrání</h6>
                        <hr />
                        <p><ShowDate date={image.uploadedUtc} /></p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}))