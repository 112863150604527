import App from "../App";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class StorageFilesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getStorageFiles(): Promise<IResponseData> {
        return this.getRequest(`storage/files`, {})
            .then(response => {
                return response.data;
            })
    }

    public editStorageFiles(storageFileId: string, form: {
        name: string,
        scheduled: boolean,
        importCronExpression: string,
        url: string,
        downloadFromUrl: boolean,
    }): Promise<IResponseData> {
        return this.putRequest(`storage/files/${storageFileId}`, form, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteStorageFile(storageFileId: string): Promise<IResponseData> {
        return this.deleteRequest(`storage/files/${storageFileId}`, {}, {})
            .then(response => {
                if (response.data.success) {
                    App.instance.storageFileStore.removeById(storageFileId);
                }
                return response.data;
            })
    }
}