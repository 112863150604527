import { inject, observer } from 'mobx-react';
import React from 'react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import CreateNewSourceModal from '../../../components/sources/createNewSourceModal';
import { NoSourcesScreen } from '../../../components/sources/noSourcesScreen';
import { SourcesTable } from '../../../components/sources/sourcesTable';
import SourceTableRow from '../../../components/sources/sourceTableRow';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import ISource from '../../../interfaces/ISource';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import SourceStore from '../../../stores/SourceStore';
import StorageFileStore from '../../../stores/StorageFileStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';
import BaseComponent from './../../../components/baseComponent';

interface IState {
    loading: boolean,
    sourceIds: string[],
    showCreateNewModal: boolean,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    sourceStore?: SourceStore,
    storageFileStore?: StorageFileStore,
}

interface IMatchParams {
    websiteId: string,
}

@inject('currentUserStore', 'websiteStore', 'sourceStore', 'storageFileStore')
@observer
export default class SourcesListPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            sourceIds: [],
            showCreateNewModal: false,
        }
    }

    componentDidMount() {
        const websiteId = this.props.match.params.websiteId;

        this.websitesApiService.getSources(websiteId)
            .then(data => {
                this.setState({
                    loading: false,
                    sourceIds: data.records.sources.items.map(s => s.id)
                })
            })
    }

    handleCreateNew() {
        this.setState({
            showCreateNewModal: true
        })
    }

    renderTable(sources: ISource[]) {
        return (
            <div className={`card`}>
                <div className="card-body">
                    <SourcesTable>
                        {sources.map(source => {
                            const storageFile = this.props.storageFileStore.getById(source.storageFileId);

                            return (
                                <SourceTableRow
                                    key={source.id}
                                    source={source}
                                    storageFile={storageFile}
                                />
                            )
                        })}
                    </SourcesTable>
                </div>
            </div>
        )
    }

    render() {
        const sources = this.props.sourceStore.getByIds(this.state.sourceIds);
        const loading = this.state.loading;
        const websiteId = this.props.match.params.websiteId;
        const website = this.props.websiteStore.getById(websiteId);

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Zdroje dat">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <div className="dashboard-header-title">
                                <h5 className="mb-0">Zdroje dat</h5>
                                <p className="mb-0 font-15">Importujte data z různých zdrojů.</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <button onClick={this.handleCreateNew.bind(this)} className="btn btn-primary ml-1">Přidat nový zdroj</button>
                                    {/* <a href="#" className="btn btn-primary ml-1"><i className="ti-bookmark-alt font-11" /> Export</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showCreateNewModal &&
                        <CreateNewSourceModal
                            website={website}
                            onHide={() => this.setState({ showCreateNewModal: false })}
                        />}

                    {loading && <LoadingScreen />}

                    {!loading && sources.length > 0 && this.renderTable(sources)}

                    {!loading && sources.length === 0 &&
                        <NoSourcesScreen
                            onCreateNewSource={this.handleCreateNew.bind(this)}
                            website={website}
                        />}

                </WebsiteDocumentTitle>
            </>
        )
    }
}