import React from "react";
import ISource from "../../interfaces/ISource";
import { SourceLastImport } from "./sourceLastImport";
import { SourceState } from "./sourceState";
import BaseComponent from './../baseComponent';
import Swal from "sweetalert2";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { history } from './../../App';
import IStorageFile from "../../interfaces/IStorageFile";

interface IState {

}

interface IProps {
    source: ISource
    storageFile: IStorageFile,
}

export default class SourceTableRow extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {

        }
    }

    handleCancelImport() {
        const source = this.props.source;
        this.sourcesApiService.cancelImport(source.id);
    }

    handleRunImport() {
        const source = this.props.source;
        const storageFile = this.props.storageFile;

        if (storageFile.downloadFromUrl === true) {
            Swal.fire({
                title: 'Chcete znovu stáhnout zdrojový soubor?',
                text: 'Před importem se stáhne aktuální zdrojový soubor.',
                confirmButtonText: 'Ano',
                denyButtonText: 'Ne',
                cancelButtonText: 'Zrušit',
                showDenyButton: true,
                icon: 'question',
            }).then(result => {
                if (result.isConfirmed) {
                    this.sourcesApiService.runImport(source.id, true);
                }
                else if (result.isDenied) {
                    this.sourcesApiService.runImport(source.id, false);
                }
            })
        }
    }

    handleDelete() {
        const source = this.props.source;

        Swal.fire({
            title: `Opravdu smazat zdroj dat "${source.name}" včetně obsahu?`,
            html: `Jesliže smažete zdroj dat, smažete tím veškerý importovaný obsah (${source.pagesCount}). <b>Akce je nevratná.</b>`,
            icon: 'warning',
            confirmButtonText: 'Ano, smazat včetně obsahu',
            cancelButtonText: 'Zrušit',
            showCancelButton: true,
            focusCancel: true,
        }).then(result => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `Opravdu chcete pokračovat?`,
                    html: 'Jesliže smažete zdroj dat, smažete tím veškerý importovaný obsah. <b>Akce je nevratná.</b>',
                    icon: 'warning',
                    confirmButtonText: 'Ano, vím, co dělám',
                    cancelButtonText: 'Zrušit',
                    showCancelButton: true,
                    focusCancel: true,
                }).then(result => {
                    if (result.isConfirmed) {
                        this.sourcesApiService.deleteSource(source.id)
                            .then(data => {

                            })
                    }
                })
            }
        })
    }

    renderRunOrCancelImport() {
        const source = this.props.source;

        if (!source.importing) {
            return <Dropdown.Item
                eventKey="run"
                className="text-dark"
                onClick={this.handleRunImport.bind(this)}
            >
                Spustit import
            </Dropdown.Item>
        }
        else if (source.importing) {
            return <Dropdown.Item
                eventKey="cancel"
                className="text-dark"
                onClick={this.handleCancelImport.bind(this)}
            >
                Zrušit běžící import
            </Dropdown.Item>
        }
        return null;
    }

    render() {
        const { source, storageFile } = this.props;

        return (
            <tr>
                <td>{source.name}</td>

                <td>
                    <SourceState source={source} />
                </td>

                <td>
                    {storageFile ? storageFile.name : 'Nevybráno'}
                </td>

                <td>
                    {source.importing ? <> {source.processedElements} / {source.totalFilteredElements}</> : <>{source.pagesCount}</>} stránek
                </td>

                <td>
                    <SourceLastImport source={source} />
                    {storageFile && storageFile.lastUploadedUtc && <><br /><small>Staženo: {new Date(storageFile.lastUploadedUtc).toLocaleString()}</small></>}
                </td>

                {/* <td>
                    {source.scheduled ? <CronDescription cronExpression={source.importCronExpression} /> : 'Nenastaveno'}
                </td> */}

                <td>
                    <DropdownButton
                        variant="info"
                        size="sm"
                        title={"Možnosti"}
                        className="float-right"
                        data-toggle="dropdown"
                        data-boundary="window"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {this.renderRunOrCancelImport()}

                        <Dropdown.Item
                            eventKey="2"
                            className="text-dark"
                            onClick={() => history.push(`sources/${source.id}/settings`)}
                        >
                            Nastavení
                        </Dropdown.Item>

                        <Dropdown.Item
                            eventKey="3"
                            className="text-danger"
                            onClick={this.handleDelete.bind(this)}
                        >
                            Smazat
                        </Dropdown.Item>
                    </DropdownButton>
                </td>
            </tr>)
    }
}