import React from 'react';
import BaseComponent from '../baseComponent';
import IUser from '../../interfaces/IUser';
import OAuth from '../others/oauth';

interface IState {
   
}

interface IProps {
    user: IUser
}

export default class GoogleAuthSettings extends BaseComponent<IProps, IState> {

    render() {
        const userServer = this.props.user;

        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title mb-20">Google účet</h5>
                    <p>Slouží ke správě Google Analytics a Google Search Console.</p>
                    <OAuth
                        provider="google"
                        disabled={false}
                    >
                        <button className="btn btn-primary btn-block">
                            <i className="fab fa-google mr-2"></i>
                            {!userServer.googleConnected ? `Připojit Google účet` : 'Připojeno'}
                        </button>
                    </OAuth>
                </div>
            </div>
        )
    }
}