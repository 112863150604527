import React from 'react';

interface IProps {
    loading: boolean,
    children?: React.ReactNode;
    onClick(): void,
    className?: string,
    disabled?: boolean,
}

export const BtnSubmit = (props: IProps) => {  
    return <button
        className={props.className || 'btn btn-primary btn-block'}
        onClick={props.onClick.bind(this)}
        disabled={props.loading || props.disabled}
        >
            {props.loading ? <i>Načítání...</i> : props.children}
        </button>
}