import Swal from "sweetalert2";
import IWebsite from "../../interfaces/IWebsite"
import { BtnSubmit } from "../inputs/btnSubmit";
import BaseComponent from './../../components/baseComponent';

interface IState {
    loading: boolean,
}

interface IProps {
    website: IWebsite,
}

export default class WebsiteNameServersInstructions extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    handleSubmitCheckDns() {
        this.setState({
            loading: true
        })

        const website = this.props.website;

        this.websitesApiService.verifyDns(website.id)
            .then(data => {
                if (data.success === true) {
                    Swal.fire({
                        title: 'Ověření bylo úspěšné',
                        text: 'Nyní se provede poslední nastavení.',
                        confirmButtonText: 'Rozumím'
                    })
                }
                else {
                    Swal.fire({
                        title: 'Ověření se nezdařilo', 
                        text: 'Jestliže jste změnili DNS servery vaší domény, změna může trvat až 48 hodin. Prosím, vyčkejte.',
                        confirmButtonText: 'Rozumím'
                    })
                }
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }

    render() {
        const website = this.props.website;

        return (
            <div className="card mt-0">
                <div className="card-body">
                    <div className="py-5">
                        <div className="text-center">
                            <h5>Prosím, proveďte změnu DNS serverů vaší domény</h5>
                        </div>
                        <div className="text-center">
                            <p>Změnu provedete u vašeho registrátora domény. Změna může trvat až 48 hodin než se projeví napříč internetem.</p>

                            <div className="mt-2" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                <textarea style={{ width: '300px' }} className="form-control" rows={5} readOnly value={website.awsNameServers} />
                            </div>

                            <BtnSubmit
                                loading={this.state.loading}
                                onClick={this.handleSubmitCheckDns.bind(this)}
                                className="btn btn-primary mt-3"
                            >
                                Ověřit nastavení DNS
                           </BtnSubmit>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}