import IUser from "../../interfaces/IUser";
import IWebsite from '../../interfaces/IWebsite';
import { AccountBottomNavbar } from './accountBottomNavbar';
import TopNavbar from './topNavbar';
import { WebsiteBottomNavbar } from './websiteBottomNavbar';

interface IProps {
    user: IUser,
    selectedWebsite?: IWebsite,
    websites: IWebsite[]
}

export const DefaultHeader = (props: IProps) => {

    const user = props.user;
    const selectedWebsite = props.selectedWebsite;

    return (<div className="horizontal-menu">
        <TopNavbar selectedWebsite={selectedWebsite} websites={props.websites} user={user} />
        {selectedWebsite ? <WebsiteBottomNavbar user={user} website={selectedWebsite} /> : <AccountBottomNavbar />}
    </div>)
}