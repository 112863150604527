import { filter, isNil, replace } from 'lodash';
import IOptionData from "../components/inputs/IOptionData";

export default class StringHelper {
    public static addFixSpace(value: string, count: number): string {
        for (var i = value.length; i < count; i++) {
            value = '&#160;' + value;

        }
        return value;
    }

    public static separateThousands(number: any): string {
        if (!number && number !== 0) {
            return null;
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;")
    }

    public static escapeHtml(unsafe: string) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    public static getUnorderedListFromText(text: string, classNameUl?: string) {
        let items: JSX.Element[] = [];

        if (!text) {
            return items;
        }

        let rows = text.split("\n");

        for (var i = 0; i < rows.length; i++) {
            let z = rows[i].replace(/\s/g, "");
            if (z.length > 0) {
                items.push(
                    <li className="pd-3" key={i} dangerouslySetInnerHTML={{ __html: this.escapeHtml(rows[i].toString()) }}>

                    </li>
                )
            }
        }
        return <ul className={classNameUl}> {items}</ ul>;
    }

    public static replaceAllSpacesToNonBreakingSpace(text: string) {
        for (var i = 0; i < text.length; i++) {

            text = replace(text, ' ', '&nbsp;');
        }
        return text;
    }

    public static getParagraphs(text: string, maxLength?: number) {
        let paragraphs: JSX.Element[] = [];

        if (text === undefined || text === null) {
            return paragraphs;
        }

        if (maxLength) {
            if (text.length > maxLength) {
                text = text.slice(0, maxLength);
                text = text + "...";
            }
        }

        let rows = text.split("\n");

        for (var i = 0; i < rows.length; i++) {
            paragraphs.push(
                <p key={i} dangerouslySetInnerHTML={{ __html: this.escapeHtml(rows[i].toString()) }}>
                </p>
            )
        }
        return paragraphs;
    }

    public static truncateLongString(text: string, length: number) {

        if (isNil(text)) {
            return '';
        }
        if (text.length > length) {
            return text.slice(0, length) + '...';
        }
        return text;
    }

    public static enumerationFromOptions(array: IOptionData[], selectedItemIds?: any[], lowerCase?: boolean) {
    {
            let items: JSX.Element[] = [];

            array = filter(array, (item: IOptionData) => {
                if (selectedItemIds && selectedItemIds.indexOf(item.value) >= 0) {
                    return true;
                }
                if (!selectedItemIds) {
                    return true;
                }
            });

            array.map((item: IOptionData, index) => {
                items.push(<span key={item.value}> {lowerCase ? item.label.toLowerCase() : item.label}{index != array.length - 1 ? "," : ""}
                </span>)

            })
            return items;
        }
    }

    public static generateRandomString(length: number) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    public static stripHtml(html: string) : string {
        var stripedHtml = (html || '').replace(/<[^>]+>/g, '');
        return stripedHtml;
    }
}