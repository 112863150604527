interface IProps {
    children?: any,
    title: string
    subtitle?: string
}

export const PageTitle = (props: IProps) => {
    return (
        <div className="dashboard-header-title">
            <h5 className="mb-0">{props.title}</h5>
            {props.subtitle && <p className="mb-0 font-15">{props.subtitle}</p>}
        </div>)
}