import { CustomDocumentTitle } from '../../utils/customDocumentTitle';
import BaseComponent from './../../components/baseComponent';
import IRouteComponentProps from '../../interfaces/IRouteComponentProps';
import WebsiteStore from '../../stores/WebsiteStore';
import { inject, observer } from 'mobx-react';
import WebsiteNameServersInstructions from '../../components/websites/websiteNameServersInstructions';
import { WebsitePendingSetupScreen } from '../../components/websites/websitePendingSetupScreen';
import { NotFoundScreen } from '../../components/screens/notFoundScreen';

interface IState {
    loading: boolean,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    websiteStore?: WebsiteStore
}

interface IMatchParams {
    websiteId: string,
}

@inject('websiteStore')
@observer
export default class ManageWebsitePage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    render() {
        const website = this.props.websiteStore.getById(this.props.match.params.websiteId);

        if (!website) {
            return <NotFoundScreen />
        }

        return (
            <>
                <CustomDocumentTitle title={`${website.name}`}>
                    <>
                        <div className="row align-items-center">
                            <div className="col-6">
                                <div className="dashboard-header-title mb-3">
                                    <h5 className="mb-0">{website.name}</h5>
                                    <p className="mb-0 font-15">
                                        <a target="_blank" href={`https://${website.domain}`}>
                                            {website.domain}
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="dashboard-infor-mation d-flex flex-wrap align-items-center mb-30">
                                    <div className="dashboard-btn-group d-flex align-items-center">
                                        

                                        <a href={`/api/websites/${website.id}/hugo`} className="btn btn-primary ml-1"><i className="ti-bookmark-alt font-11" /> Stáhnout Hugo</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {website.awsPendingDistributionSetup && website.awsWaitingChangeNameServers === false &&
                            <WebsitePendingSetupScreen />
                        }

                        {website.awsWaitingChangeNameServers &&
                            <WebsiteNameServersInstructions
                                website={website}
                            />}

                    </>
                </CustomDocumentTitle>
            </>
        )
    }
}