
import App from "../App";
import IMenuItem from "../interfaces/IMenuItem";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class MenusApiService extends BaseApiService {

    constructor() {
        super();
    }

    public deleteMenu(id: string): Promise<IResponseData> {
        return this.deleteRequest(`menus/${id}`, {}, {})
            .then(response => {
                if (response.data.success) {
                    App.instance.menuItemStore.removeByMenuId(id);
                    App.instance.menuStore.removeById(id);
                } 
                return response.data;
            })
    }

    public editMenuItem(menuId: string, menuItemId: string, menuItem: IMenuItem): Promise<IResponseData> {
        return this.putRequest(`menus/${menuId}/item/${menuItemId}`, menuItem, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteMenuItem(menuId: string, menuItemId: string): Promise<IResponseData> {
        return this.deleteRequest(`menus/${menuId}/item/${menuItemId}`, {}, {})
            .then(response => {
                if (response.data.success) {
                    App.instance.menuItemStore.removeChildrenItems(menuItemId);
                    App.instance.menuItemStore.removeById(menuItemId);
                }
                return response.data;
            })
    }

    public createMenuItem(menuId: string, item: IMenuItem): Promise<IResponseData> {
        return this.postRequest(`menus/${menuId}/item`, item, {})
            .then(response => {
                return response.data;
            })
    }

    public editMenu(menuId: string, name: string, position: string): Promise<IResponseData> {
        return this.putRequest(`menus/${menuId}`, { name, position }, {})
            .then(response => {
                return response.data;
            })
    }
}