import React from 'react';
import { Modal } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';
import IMenu from '../../interfaces/IMenu';

interface IState {
    form: {
        name: string,
        position: string,
    }
    loading: boolean,
    serverErrors: IFormError[]
}

interface IProps {
    menu: IMenu,
    onHide(): void,
}

export default class EditMenuModal extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputPosition: React.RefObject<InputText>;
 
    constructor(props: IProps) {
        super(props);

        this.state = {
            form: {
                name: this.props.menu.name,
                position: this.props.menu.position,
            },
            loading: false,
            serverErrors: []
        }

        this.inputName = React.createRef();
        this.inputPosition = React.createRef();
    }

    handleSubmit() {
        const inputs = [
            this.inputName,
            this.inputPosition,
        ];

        const isValid = this.validateInputs(inputs);
        const menu = this.props.menu;
        const form = this.state.form;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.menusApiService.editMenu(menu.id, form.name, form.position)
                .then(data => {
                    if (data.success) {
                        this.props.onHide();
                    }
                    else {
                        this.setState({
                            loading: false,
                            serverErrors: data.formErrors
                        })
                        this.validateInputs(inputs);
                    }      
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    render() {
        const { loading, form, serverErrors } = this.state;
        const menuServer = this.props.menu;

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Úprava menu "{menuServer.name}"</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputText
                        uid="name"
                        ref={this.inputName}
                        label="Název"
                        onChange={this.handleChange.bind(this)}
                        value={form.name}
                        required
                        maxLength={30}
                        serverErrors={serverErrors}
                    />

                    <InputText
                        uid="position"
                        ref={this.inputPosition}
                        label="Umístění"
                        onChange={this.handleChange.bind(this)}
                        value={form.position}
                        required
                        maxLength={30}
                        serverErrors={serverErrors}
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Uložit změny
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}