import React from 'react';
import BaseComponent from './../../baseComponent';
import IPageDesign from '../../../interfaces/IPageDesign';
import IFormError from '../../forms/IFormError';
import { Accordion, Card } from 'react-bootstrap';
import SelectBox from '../../inputs/selectBox';
import DictionaryKeyValueInput from '../../../components/pages/pagePropertiesEditTable';

interface IState {
    createNewProperty: boolean,
}

interface IProps {
    pageDesignEdit: IPageDesign,
    pageDesignServer: IPageDesign,
    onChange(value: any, key: string): void,
    serverErrors: IFormError[],   
}

export default class SourcePageDesignCustomFieldsCard extends BaseComponent<IProps, IState> {

    private inputSection: React.RefObject<SelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            createNewProperty: false
        }

        this.inputSection = React.createRef();
    }

    validateForm() {
        const inputs: any = [
            this.inputSection,
        ];
        const isValid = this.validateInputs(inputs);
        return isValid;
    }

    render() {
        const { pageDesignEdit, onChange, } = this.props;

        return (
            <Card className="mb-0 pb-0">
                <Accordion.Toggle as={Card.Header} className="clickable" eventKey="customFields">
                    Custom fields <span className="float-right"><i className="fas fa-angle-down ml-2 mt-1"></i></span>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="customFields">
                    <Card.Body>

                        <DictionaryKeyValueInput
                            uid="properties"
                            dictionary={pageDesignEdit.properties}
                            onChange={onChange.bind(this)}
                            onCreateNew={() => this.setState({ createNewProperty: true })}
                        />
          
                        {/*
                        <MultiSelectBox
                            uid="categoryIds"
                            options={categoryOptions}
                            selected={CategoryHelper.getCategoryOptions(selectedCategories)}
                            label="Kategorie"
                            onChange={this.handleChange.bind(this)}
                        />*/}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>)
    }
}