import { inject, observer } from "mobx-react";
import { useState } from "react";
import IImage from "../../interfaces/IImage";
import WebsiteStore from "../../stores/WebsiteStore";
import { ImageDetailModal } from "./imageDetailModal";

interface IProps {
    image: IImage,
    websiteStore?: WebsiteStore
}

export const ImageGridItem = inject("websiteStore")(observer((props: IProps) => {
    const { image } = props;

    const [showDetail, setShowDetail] = useState(false);
    const website = props.websiteStore.getById(image.websiteId);

    return (
        <div className="col-2">
            {showDetail && <ImageDetailModal image={image} onHide={() => setShowDetail(false)} />}
            <img loading="lazy" src={`https://${website.domain}/images/${image.fileName}`} onClick={() => setShowDetail(true)} className="img-fluid clickable m-1" style={{ border: '1px solid #b5b5b5' }} />
        </div>);
}))