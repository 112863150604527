import { each, } from 'lodash';
import App from '../App';
import ICategory from '../interfaces/ICategory';
import IGaAccount from '../interfaces/IGaAccount';
import IImage from '../interfaces/IImage';
import IMenu from '../interfaces/IMenu';
import IMenuItem from '../interfaces/IMenuItem';
import IPage from '../interfaces/IPage';
import ISection from '../interfaces/ISection';
import ISource from '../interfaces/ISource';
import IStorageFile from '../interfaces/IStorageFile';
import ITheme from '../interfaces/ITheme';
import IUser from '../interfaces/IUser';
import IVariable from '../interfaces/IVariable';
import IWebsite from '../interfaces/IWebsite';
import IWebsiteGroup from '../interfaces/IWebsiteGroup';
import IRecord from '../interfaces/records/IRecord';
import IResponseRecords from '../interfaces/records/IResponseRecords';

export default class StoreUtil {

    public static process(data: any) {
        let records = data.records as IResponseRecords;

        each(records, (record, type: string) => {
            if (type === 'currentUser') {
                App.instance.currentUserStore.setCurrentUser(record as IUser);
            }
            else if (type === 'pages') {
                var pageRecord = record as IRecord<IPage>;
                App.instance.pageStore.addRecords(pageRecord.items);
            }
            else if (type === 'sources') {
                var sourceRecord = record as IRecord<ISource>;
                App.instance.sourceStore.addRecords(sourceRecord.items);
            }
            else if (type === 'websites') {
                var websiteRecord: IRecord<IWebsite> = record as IRecord<IWebsite>;
                App.instance.websiteStore.addRecords(websiteRecord.items);
            }
            else if (type === 'categories') {
                var categoryRecord: IRecord<ICategory> = record as IRecord<ICategory>;
                App.instance.categoryStore.addRecords(categoryRecord.items);
            }
            else if (type === 'themes') {
                var themeRecord: IRecord<ITheme> = record as IRecord<ITheme>;
                App.instance.themeStore.addRecords(themeRecord.items);
            }
            else if (type === 'variables') {
                var variableRecord: IRecord<IVariable> = record as IRecord<IVariable>;
                App.instance.variableStore.addRecords(variableRecord.items);
            }
            else if (type === 'sections') {
                var sectionRecord: IRecord<ISection> = record as IRecord<ISection>;
                App.instance.sectionStore.addRecords(sectionRecord.items);
            }
            else if (type === 'menus') {
                var menuRecord: IRecord<IMenu> = record as IRecord<IMenu>;
                App.instance.menuStore.addRecords(menuRecord.items);
            }
            else if (type === 'menuItems') {
                var menuItemRecord: IRecord<IMenuItem> = record as IRecord<IMenuItem>;
                App.instance.menuItemStore.addRecords(menuItemRecord.items);
            }
            else if (type === 'images') {
                var imageRecord: IRecord<IImage> = record as IRecord<IImage>;
                App.instance.imageStore.addRecords(imageRecord.items);
            }
            else if (type === 'gaAccounts') {
                var gaAccountRecord: IRecord<IGaAccount> = record as IRecord<IGaAccount>;
                App.instance.gaAccountStore.addRecords(gaAccountRecord.items);
            }
            else if (type === 'websiteGroups') {
                var websiteGroupRecord: IRecord<IWebsiteGroup> = record as IRecord<IWebsiteGroup>;
                App.instance.websiteGroupStore.addRecords(websiteGroupRecord.items);
            }
            else if (type === 'storageFiles') {
                var storageFileRecord: IRecord<IStorageFile> = record as IRecord<IStorageFile>;
                App.instance.storageFileStore.addRecords(storageFileRecord.items);
            }
        });
    }
}