import { Link } from 'react-router-dom';
import IUser from "../../interfaces/IUser";
import IWebsite from '../../interfaces/IWebsite';

interface IProps {
    user: IUser,
    website: IWebsite
}

export const WebsiteBottomNavbar = (props: IProps) => {
    const website = props.website;

    if (!website) {
        return null;
    }

    return (
        <nav className="bottom-navbar">
            <div className="container-fluid">
                <ul className="nav page-navigation">
                    <li className="nav-item">
                        <Link to={`/websites/${website.id}/manage`} className="nav-link">
                            <i className="zmdi zmdi-home zmdi-hc-fw feather feather-feather menu-icon mr-1" style={{ fontSize: '19px' }}></i>
                            <span className="menu-title">{website.name}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={`/websites/${website.id}/pages`} className="nav-link">
                            <i className="zmdi zmdi-format-list-bulleted feather feather-feather menu-icon mr-1" style={{ fontSize: '19px' }}></i>
                            <span className="menu-title">Stránky</span>
                        </Link>

                        <div className="submenu">
                            <ul className="submenu-item">
                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/categories`}
                                        className='nav-link'
                                    >
                                        Kategorie
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/sections`}
                                        className='nav-link'
                                    >
                                        Sekce
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <Link to={`/websites/${website.id}/sources`} className="nav-link">
                            <i className="fa fa-database feather feather-feather menu-icon mr-1 pt-1"></i>
                            <span className="menu-title">Zdroje dat</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to={`/websites/${website.id}/media`} className="nav-link">
                            <i className="zmdi zmdi-folder-star-alt feather feather-feather menu-icon mr-1" style={{ fontSize: '18px' }}></i>
                            <span className="menu-title">Média</span>
                        </Link>

                        <div className="submenu">
                            <ul className="submenu-item">
                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/media/images`}
                                        className='nav-link'
                                    >
                                        Obrázky
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <div className="nav-link" >
                            <i className="zmdi zmdi-settings zmdi-hc-fw feather feather-feather menu-icon mr-1" style={{ fontSize: '19px' }}></i>
                            <span className="menu-title">Nastavení</span>
                        </div>

                        <div className="submenu">
                            <ul className="submenu-item">
                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/settings/basic`}
                                        className='nav-link'
                                    >
                                        Obecné
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/menus`}
                                        className='nav-link'
                                    >
                                        Menu
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/settings/google-analytics`}
                                        className='nav-link'
                                    >
                                       Google Analytics
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/variables`}
                                        className='nav-link'
                                    >
                                        Proměnné
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/css`}
                                        className='nav-link'
                                    >
                                        Vlastní CSS
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link
                                        to={`/websites/${website.id}/settings/change-domain`}
                                        className='nav-link'
                                    >
                                        Změna domény
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>)
}