import React from 'react';
import { Accordion, Dropdown, DropdownButton } from 'react-bootstrap';
import BaseComponent from '../baseComponent';
import { filter, find, findIndex, last, orderBy } from 'lodash';
import IMenuItem from '../../interfaces/IMenuItem';
import IMenu from '../../interfaces/IMenu';
import Nestable, { Item } from 'react-nestable';
import { NoMenusItems } from './noMenuItems';
import CreateMenuItemSectionCard from './createMenuItemSectionCard';
import CreateMenuItemCategoryCard from './createMenuItemCategoryCard';
import CreateMenuItemUserLinkCard from './createMenuItemUserLinkCard';
import IOptionData from '../inputs/IOptionData';
import Swal from 'sweetalert2';
import MenuItem from './menuItem';
import EditMenuModal from './editMenuModal';
import CreateMenuItemPageCard from './createMenuItemPageCard';
import CreateMenuItemHomepageCard from './createMenuItemHomepageCard';

interface IState {
    loadingDelete: boolean,
    loadingEdit: boolean,
    loadingChangeOrder: boolean,
    showEditModal: boolean,
}

interface IProps {
    menu: IMenu,
    menuItems: IMenuItem[],
    sectionOptions: IOptionData[],
    categoryOptions: IOptionData[],
}

export default class MenuTabContent extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loadingDelete: false,
            loadingEdit: false,
            loadingChangeOrder: false,
            showEditModal: false,
        }
    }

    handleDelete(menu: IMenu) {
        Swal.fire({
            title: `Opravdu chcete smazat menu "${menu.name}"?`,
            html: `Akce je nevratná.`,
            icon: 'warning',
            confirmButtonText: 'Ano, smazat',
            cancelButtonText: 'Zrušit',
            showCancelButton: true,
            focusCancel: true,
        }).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loadingDelete: true
                })

                this.menusApiService.deleteMenu(menu.id)
                    .then(data => {

                    })
            }
        })
    }

    handleChange(data: { items: Item[], dragItem: Item, targetPath: number[] }) {
        const position = data.targetPath;
        const dragItem = data.dragItem as IMenuItem;
        const menuItems = this.props.menuItems;

        if (position.length === 1) {
            let index = last(position);
            dragItem.index = index;
            dragItem.parentMenuItemId = null;
        }

        if (position.length === 2) {
            let indexParent = position[0];

            if (indexParent === dragItem.index && !dragItem.parentMenuItemId) {
                indexParent++;
            }

            const parentItem = find(menuItems, { index: indexParent, parentMenuItemId: '' })
            dragItem.index = position[1];
            dragItem.parentMenuItemId = parentItem.id;
        }
        Swal.showLoading();

        this.setState({
            loadingChangeOrder: true
        })

        this.menusApiService.editMenuItem(dragItem.menuId, dragItem.id, dragItem)
            .then(data => {
                this.setState({
                    loadingChangeOrder: false
                })
                this.toastSuccess('Změna uložena');
                Swal.close();
            })
    }

    handleEdit() {
        this.setState({
            showEditModal: true
        })
    }

    renderItem(inputItem: any, handler: React.ReactNode) {
        const item = inputItem as IMenuItem;
        return <MenuItem key={item.id} item={item} handler={handler} />
    }

    render() {
        const { menu, menuItems, sectionOptions, } = this.props;
        let { categoryOptions } = this.props;
        const { loadingDelete } = this.state;

        const nestedItems: any = [];

        menuItems.map(menuItem => {
            if ((menuItem.parentMenuItemId || '').length === 0) {
                const childrens = orderBy(filter(menuItems, { parentMenuItemId: menuItem.id }), ['index'], ['asc'])
                nestedItems.push({ ...menuItem, children: childrens });
            }
        })
/*
        categoryOptions.map(option => {
            const usedCount = filter(menuItems.map(s => s.categoryId), option.value).length;
            option.label += ` ${usedCount}`;
        })*/

        return (
            <div className="row">
                <div className="col-12 col-md-6 col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            {this.state.showEditModal &&
                                <EditMenuModal
                                    menu={menu}
                                    onHide={() => this.setState({ showEditModal: false })}
                                />
                            }

                            <Accordion style={{ marginBottom: '50px' }}>
                                <Nestable
                                    items={nestedItems}
                                    renderItem={(item) => this.renderItem(item.item, item.handler)}
                                    onChange={this.handleChange.bind(this)}
                                    maxDepth={2}
                                    handler={<i className="fas fa-arrows-alt mr-1"></i>}
                                />
                            </Accordion>

                            {nestedItems.length === 0 && <NoMenusItems />}

                            <small>
                                <span className="font-italic">Umístění: {menu.position}</span>
                            </small>

                            <DropdownButton
                                variant="outline-dark"
                                size="sm"
                                title={loadingDelete ? "Načítání..." : "Možnosti"}
                                className="float-right"
                                disabled={loadingDelete}
                            >
                                <Dropdown.Item
                                    eventKey="2"
                                    className="text-dark"
                                    onClick={this.handleEdit.bind(this)}
                                >
                                    Upravit
                                </Dropdown.Item>

                                <Dropdown.Item
                                    eventKey="1"
                                    className="text-danger"
                                    onClick={this.handleDelete.bind(this, menu)}
                                >
                                    Smazat
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <Accordion style={{ marginBottom: '50px' }}>
                        <CreateMenuItemSectionCard
                            menu={menu}
                            sectionOptions={sectionOptions}
                        />

                        <CreateMenuItemCategoryCard
                            menu={menu}
                            categoryOptions={categoryOptions}
                        />

                        <CreateMenuItemPageCard
                            menu={menu}
                        />

                        <CreateMenuItemUserLinkCard
                            menu={menu}
                        />

                        <CreateMenuItemHomepageCard
                            menu={menu}
                        />
                    </Accordion>
                </div>
            </div>)
    }
}