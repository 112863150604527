import { Language } from "../enums/language";
import IWebsiteBasicSettings from "../interfaces/IWebsiteBasicSettings";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class WebsitesApiService extends BaseApiService {

    public create(name: string, domain: string, isCustomDomain: boolean, language: Language): Promise<IResponseData> {
        return this.postRequest(`websites`, { name, domain, isCustomDomain, language }, {})
            .then(response => {
                return response.data;
            })
    }

    public getWebsites(): Promise<IResponseData> {
        return this.getRequest(`websites`, {})
            .then(response => {
                return response.data;
            })
    }

    public getWebsite(id: string): Promise<IResponseData> {
        return this.getRequest(`websites/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteWebsite(id: string): Promise<IResponseData> {
        return this.deleteRequest(`websites/${id}`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public verifyDns(websiteId: string): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/dns/verification`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public syncWebsite(websiteId: string): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/sync`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public getSources(websiteId: string): Promise<IResponseData> {
        return this.getRequest(`websites/${websiteId}/sources`, {})
            .then(response => {
                return response.data;
            })
    }

    public createSource(websiteId: string, name: string): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/sources`, { name: name }, {})
            .then(response => {
                return response.data;
            })
    }

    public getPages(websiteId: string, skipCount: number, takeCount: number, search: string, sourceId: string, categoryId: string, sectionId: string): Promise<IResponseData> {
        return this.getRequest(`websites/${websiteId}/pages`, {
            skipCount: skipCount.toString(),
            takeCount: takeCount.toString(),
            search: search,
            sourceId: sourceId || '',
            categoryId: categoryId || '',
            sectionId: sectionId || '',
        })
            .then(response => {
                return response.data;
            });
    }

    public createPage(websiteId: string, name: string, sectionId: string): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/pages`, { name: name, sectionId: sectionId }, {})
            .then(response => {
                return response.data;
            })
    }

    public getCategories(websiteId: string, skipCount: number, takeCount: number, search: string): Promise<IResponseData> {
        return this.getRequest(`websites/${websiteId}/categories`, {
            skipCount: skipCount.toString(),
            takeCount: takeCount.toString(),
            search: search,
        })
            .then(response => {
                return response.data;
            });
    }


    public createCategory(websiteId: string, name: string, description: string): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/categories`, { name: name, description: description }, {})
            .then(response => {
                return response.data;
            })
    }

    public getSections(websiteId: string): Promise<IResponseData> {
        return this.getRequest(`websites/${websiteId}/sections`, {})
            .then(response => {
                return response.data;
            });
    }

    public createSection(websiteId: string, name: string, slug: string, primary: boolean, layout: string): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/sections`, { name: name, slug: slug, primary: primary, layout }, {})
            .then(response => {
                return response.data;
            })
    }

    public editWebsiteBasicSettings(websiteId: string, basicSettings: IWebsiteBasicSettings): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/settings/basic`, { ...basicSettings }, {})
            .then(response => {
                return response.data;
            })
    }

    public getMenus(websiteId: string): Promise<IResponseData> {
        return this.getRequest(`websites/${websiteId}/menus`, {})
            .then(response => {
                return response.data;
            });
    }

    public createMenu(websiteId: string, name: string, position: string): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/menus`, { name: name, position: position }, {})
            .then(response => {
                return response.data;
            })
    }

    public setupGoogleAnalytics(websiteId: string, gaCurrencyCode: string, gaTimeZone: string, gaAccountName: string) {
        return this.postRequest(`websites/${websiteId}/google-analytics`, { gaCurrencyCode, gaTimeZone, gaAccountName }, {})
            .then(response => {
                return response.data;
            });
    }

    public getVariables(websiteId: string): Promise<IResponseData> {
        return this.getRequest(`websites/${websiteId}/variables`, {})
            .then(response => {
                return response.data;
            });
    }

    public changeCustomDomain(websiteId: string, newCustomDomain: string): Promise<IResponseData> {
        return this.postRequest(`websites/${websiteId}/change-domain`, { newDomain: newCustomDomain }, {})
            .then(response => {
                return response.data;
            });
    }

    public getImages(websiteId: string): Promise<IResponseData> {
        return this.getRequest(`websites/${websiteId}/images`, {})
            .then(response => {
                return response.data;
            });
    }

    public uploadImage(websiteId: string, file: File): Promise<IResponseData> {
        const formData = new FormData()
        formData.append('file', file, file.name)

        return this.postRequest(`websites/${websiteId}/images`,  formData , {})
            .then(response => {
                return response.data;
            })
    }

    public downloadHugoZip(id: string): Promise<IResponseData> {
        return this.getRequest(`websites/${id}/hugo`, {})
            .then(response => {
                return response.data;
            })
    }


    public setCss(websiteId: string, css: string): Promise<IResponseData> {
        return this.putRequest(`websites/${websiteId}/css`, { css }, {})
            .then(response => {
                return response.data;
            })
    }

    public getCss(websiteId: string): Promise<string> {
        return this.getRequest(`websites/${websiteId}/css`, {})
            .then(response => {
                const s = response.data as unknown;
                return s as string;
            })
    }
}