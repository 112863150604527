import React from 'react';
import LocaleUtils from "react-day-picker/moment";
import "moment/locale/cs";
import { DateUtils } from 'react-day-picker';
import BaseComponent from './../baseComponent';
import IInputField from './IInputField';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

interface IProps {
    uid?: string,
    required?: boolean;
    onChange(value: any, key: string): void;
    label: string;
    date: Date,
   // mustBeGreaterThan?: Date,
   // fromMonth: Date,
   // disabledDaysBefore?: Date
}

interface IState {
    errors: string[],
}

export default class InputDatePicker extends BaseComponent<IProps, IState> implements IInputField {
    private input: React.RefObject<DayPickerInput>;

    constructor(props: IProps) {
        super(props);

        this.input = React.createRef();

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            errors: [],
        };
    }

    handleChange(date: Date) {
        this.validate(date);
        // Change the from date and focus the "to" input field
        this.props.onChange(date, this.props.uid);

    }

    getErrors(date?: Date): string[] {
        let errors: string[] = [];

        if (this.props.required && (!date && !this.props.date)) {
            errors.push("Vyberte datum")
        }

       /* if (this.props.mustBeGreaterThan) {
            if (moment(new Date((date || this.props.date))) <= moment(new Date(this.props.mustBeGreaterThan))) {
                errors.push(`Datum musí být větší než ${new Date(this.props.mustBeGreaterThan).toLocaleDateString()}`)
            }
        }*/

        return errors;
    }

    formatDate(date: Date, format: string, locasle: string) {
        return dateFnsFormat(date, format, {});
    }

    parseDate(str: string, format: string, locale: string) {
        const parsed = dateFnsParse(str, format, new Date(), {  });
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

    isValid() {
        return this.getErrors().length === 0;
    }

    validate(date?: Date) {
        this.setState({
            errors: this.getErrors(date)
        })
    }

    focus() {
        // this.input.current.getInput().focus();
    }

    renderErrors() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    render() {
        const FORMAT = 'dd.MM.yyyy';

        return (
            <div className="form-group">
                <label className="form-control-label">{this.props.label} {this.props.required ? <span className="text-danger">*</span> : null}</label>
 
                <DayPickerInput
                    ref={this.input}
                    placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                    formatDate={this.formatDate}
                    format={FORMAT}
                    parseDate={this.parseDate}
                    dayPickerProps={{
                        locale: 'cs',
                        localeUtils: LocaleUtils,
                    }}
                    inputProps={{
                        className: 'form-control'
                    }}
                    value={new Date(this.props.date)}
                    onDayChange={this.handleChange.bind(this)}
                />
                {this.renderErrors()}
            </div>
        );
    }
}