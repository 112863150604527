import BaseComponent from '../baseComponent';
import IVariable from '../../interfaces/IVariable';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import EditVariableModal from './editVariableModal';
import StringHelper from '../../helpers/stringHelper';

interface IState {
    loading: boolean,
    showEditModal: boolean,
}

interface IProps {
    variable: IVariable,
}

export default class VariableTableRow extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            showEditModal: false,
        }
    }

    handleDelete() {
        const variable = this.props.variable;

        Swal.fire({
            title: `Opravdu chcete proměnnou "${variable.key}"?`,
            html: `Akce je nevratná.`,
            icon: 'warning',
            confirmButtonText: 'Ano, smazat',
            cancelButtonText: 'Zrušit',
            showCancelButton: true,
            focusCancel: true,
        }).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loading: true
                })

                this.variablesApiService.deleteVariable(variable.id);
            }
        })
    }

    handleEdit() {
        this.setState({
            showEditModal: true
        })
    }

    render() {
        const { variable } = this.props;

        return (
            <tr>
                <td>
                    {!variable.isJsonObject && <b>{`[${variable.key}]`}</b>}
                    <p>{variable.addAsParam && !variable.isJsonObject && `nebo v šabloně {{.Site.Params.${variable.key}}}`}</p>
                    <p>{variable.addAsParam && variable.isJsonObject && `v šabloně {{.Site.Params.${variable.key}}}`}</p>
                </td>

                <td>
                    {StringHelper.truncateLongString(variable.value, 60)}
                </td>

                <td>
                    {this.state.showEditModal &&
                        <EditVariableModal
                            onHide={() => this.setState({ showEditModal: false })}
                            variable={variable}
                        />}

                    {this.state.loading ?
                        <ClipLoader
                            size={30}
                            color={"#449DF7"}
                            loading={true}
                        /> : <div className="btn-group" role="group" aria-label="Basic example">
                            <button
                                className="action-icon"
                                onClick={this.handleEdit.bind(this)}
                            >
                                <i className="zmdi zmdi-edit"></i>
                            </button>

                            <button
                                className="action-icon"
                                onClick={this.handleDelete.bind(this)}
                            >
                                <i className="zmdi zmdi-delete"></i>
                            </button>
                        </div>}
                </td>
            </tr>)
    }
}