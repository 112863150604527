import { isNil } from "lodash";
import ISource from "../../interfaces/ISource"
import { ShowDateTime } from "../others/showDateTime";

interface IProps {
    source: ISource
}

export const SourceLastImport = (props: IProps) => {
    const source = props.source;

    if (!isNil(source.lastImportUtc)) {
        return <ShowDateTime date={source.lastImportUtc} />
    }
    else {
        return <>-</>
    }
}