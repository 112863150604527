import App from "../App";
import IVariable from "../interfaces/IVariable";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class VariablesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getVariable(id: string): Promise<IResponseData> {
        return this.getRequest(`variables/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public getVariables(): Promise<IResponseData> {
        return this.getRequest(`variables`, {})
            .then(response => {
                return response.data;
            })
    }

    public createVariable(variable: IVariable): Promise<IResponseData> {
        return this.postRequest(`variables`, variable, {})
            .then(response => {
                return response.data;
            })
    }

    public editVariable(variable: IVariable): Promise<IResponseData> {
        return this.putRequest(`variables/${variable.id}`, variable, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteVariable(variableId: string): Promise<IResponseData> {
        return this.deleteRequest(`variables/${variableId}`, {}, {})
            .then(response => {
                if (response.data.success) {
                    App.instance.variableStore.removeById(variableId);
                }
                return response.data;
            })
    }
}