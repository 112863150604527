import { inject, observer } from 'mobx-react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import BaseComponent from '../../../components/baseComponent';
import SectionStore from '../../../stores/SectionStore';
import { PageTitle } from '../../../components/others/pageTitle';
import React from 'react';
import CategoryStore from '../../../stores/CategoryStore';
import MenuStore from '../../../stores/MenuStore';
import MenuItemStore from '../../../stores/MenuItemStore';
import 'react-nestable/dist/styles/index.css';
import SectionHelper from '../../../helpers/sectionHelper';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';
import CategoryHelper from '../../../helpers/categoryHelper';
import CreateNewMenuModal from '../../../components/menus/createNewMenuModal';
import { NoMenusScreen } from '../../../components/menus/noMenusScreen';
import { Tab, Tabs } from 'react-bootstrap';
import MenuTabContent from '../../../components/menus/menuTabContent';
import { find } from 'lodash';

interface IState {
    loading: boolean,
    showCreateNewModal: boolean,
    activeKey: string,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    sectionStore?: SectionStore,
    categoryStore?: CategoryStore,
    menuStore?: MenuStore,
    menuItemStore?: MenuItemStore
}

interface IMatchParams {
    websiteId: string,
}

@inject('currentUserStore', 'websiteStore', 'sectionStore', 'categoryStore', 'menuStore', 'menuItemStore')
@observer
export default class MenusManagePage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            showCreateNewModal: false,
            activeKey: '',
        }
    }

    componentDidMount() {
        const websiteId = this.props.match.params.websiteId;
        this.websitesApiService.getMenus(websiteId)
            .then(data => {
                const menus = data.records.menus.items;

                this.setState({
                    loading: false,
                    activeKey: menus[0] ? menus[0].id : '',
                })
            })
    }

    componentDidUpdate() {
        const websiteId = this.props.match.params.websiteId;
        const menus = this.props.menuStore.getByWebsiteId(websiteId);
        const activeKey = this.state.activeKey;
        const activeMenu = find(menus, { id: activeKey });

        if ((this.state.activeKey === '' || !activeMenu) && menus.length > 0) {
            this.setState({
                activeKey: menus[0] ? menus[0].id : ''
            })
        }
    }

    handleCreateNew() {
        this.setState({
            showCreateNewModal: true
        })
    }

    renderContent() {
        const websiteId = this.props.match.params.websiteId;

        const menus = this.props.menuStore.getByWebsiteId(websiteId);

        const sections = this.props.sectionStore.getByWebsiteId(websiteId);
        const sectionOptions = SectionHelper.getSectionOptions(sections);

        const categories = this.props.categoryStore.getByWebsiteId(websiteId);
        const categoryOptions = CategoryHelper.getCategoryOptions(categories);

        const activeKey = this.state.activeKey;

        if (!menus || menus.length === 0) {
            return (
                <NoMenusScreen
                    onCreateNew={this.handleCreateNew.bind(this)}
                />
            )
        }

        return (
            <Tabs activeKey={activeKey} onSelect={(val) => this.setState({ activeKey: val })}>
                {menus.map((menu, index) => {
                    const menuItems = this.props.menuItemStore.getByMenuId(menu.id);
                    return (
                        <Tab key={menu.id} eventKey={menu.id} title={menu.name}>
                            <MenuTabContent
                                menu={menu}
                                menuItems={menuItems}
                                sectionOptions={sectionOptions}
                                categoryOptions={categoryOptions}
                            />
                        </Tab>
                    )
                })}
            </Tabs>
        )
    }

    render() {
        const loading = this.state.loading;
        const websiteId = this.props.match.params.websiteId;
        const website = this.props.websiteStore.getById(websiteId);

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Menu">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <PageTitle
                                title="Menu"
                                subtitle="Správa položek v menu."
                            />
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <button onClick={this.handleCreateNew.bind(this)} className="btn btn-primary ml-1">Vytvořit menu</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showCreateNewModal &&
                        <CreateNewMenuModal
                            onHide={() => this.setState({ showCreateNewModal: false })}
                            website={website}
                            onCreated={(menu) => this.setState({ activeKey: menu.id })}
                        />}

                    {loading ? <LoadingScreen /> : this.renderContent()}
                </WebsiteDocumentTitle>
            </>
        )
    }
}