interface IProps {

}

export const NoMenusItems = (props: IProps) => {
    return (
        <div className="text-center">
            <div className="mt-3 mb-5">
                <div className="d-flex justify-content-center mb-0 mt-2">
                    <h5>Položky přidejte z pravého sloupce</h5>
                </div>
                <p>Menu můžete nadefinovat ze stránek, kategorií, sekcí nebo vlastních odkazů</p>
            </div>
        </div>
    )
}