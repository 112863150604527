interface IProps {
   onCreateNew(): void
}

export const NoCategoriesScreen = (props: IProps) => {
    return (
        <div className="card">
            <div className="card-body text-center">
                <div className="py-5">
                    <div className="d-flex justify-content-center mb-0 mt-2">
                        <h5>Vytvořte novou kategorii</h5>
                    </div>

                    <p>Příspěvky, produkty, cokoliv...</p>
                    <div className="text-center mt-2">
                        <button
                            onClick={props.onCreateNew.bind(this)}
                            className="btn btn-primary mt-2">
                            Vytvořit kategorii
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}