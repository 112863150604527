import { inject, observer } from 'mobx-react';
import { NoPagesScreen } from '../../../components/pages/noPagesScreen';
import { PagesTable } from '../../../components/pages/pagesTable';
import LoadingScreen from '../../../components/screens/loadingScreen';
import IPage from '../../../interfaces/IPage';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import SourceStore from '../../../stores/SourceStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import BaseComponent from '../../../components/baseComponent';
import PageStore from '../../../stores/PageStore';
import CreateNewPageModal from '../../../components/pages/createNewPageModal';
import PageTableRow from '../../../components/pages/pageTableRow';
import { filter, find, orderBy } from 'lodash';
import { CustomPagination } from '../../../components/others/customPagination';
import InputText from '../../../components/inputs/inputText';
import SelectBox from '../../../components/inputs/selectBox';
import ISource from '../../../interfaces/ISource';
import CategoryStore from '../../../stores/CategoryStore';
import ICategory from '../../../interfaces/ICategory';
import CategoryHelper from '../../../helpers/categoryHelper';
import SourceHelper from '../../../helpers/sourceHelper';
import IWebsite from '../../../interfaces/IWebsite';
import SectionHelper from '../../../helpers/sectionHelper';
import ISection from '../../../interfaces/ISection';
import SectionStore from '../../../stores/SectionStore';
import React from 'react';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';

interface IPageSearchForm {
    search: string,
    sourceId: string,
    categoryId: string,
    sectionId: string,
}

interface IState {
    loading: boolean,
    pageIds: string[],
    showCreateNewModal: boolean,
    pageNumber: number,
    totalCount: number,
    searchForm: IPageSearchForm
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    sourceStore?: SourceStore,
    pageStore?: PageStore,
    categoryStore?: CategoryStore,
    sectionStore?: SectionStore,
}

interface IMatchParams {
    websiteId: string,
}

const itemsPerPage = 30;

@inject('currentUserStore', 'websiteStore', 'sourceStore', 'pageStore', 'categoryStore', 'sectionStore')
@observer
export default class PagesListPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            pageIds: [],
            showCreateNewModal: false,
            pageNumber: 1,
            totalCount: 0,
            searchForm: {
                search: '',
                sourceId: '',
                categoryId: '',
                sectionId: '',
            }
        }
    }

    componentDidMount() {
        this.getPages(this.state.pageNumber);
    }

    getPages(pageNumber: number, searchForm: IPageSearchForm = this.state.searchForm) {
        const websiteId = this.props.match.params.websiteId;

        this.setState({
            loading: true
        })

        this.websitesApiService.getPages(websiteId, (pageNumber - 1) * itemsPerPage, itemsPerPage, searchForm.search, searchForm.sourceId, searchForm.categoryId, searchForm.sectionId)
            .then(data => {
                this.setState({
                    loading: false,
                    pageIds: data.records.pages.items.map(s => s.id),
                    pageNumber: pageNumber,
                    totalCount: data.records.pages.others.totalCount
                })
            })
    }

    handleChangePage(pageNumber: number) {
        this.getPages(pageNumber);
    }

    handleFilterOnChange(value: any, key: string) {
        let searhForm = this.state.searchForm;
        // @ts-ignore
        searhForm[key] = value;

        this.setState({
            searchForm: searhForm
        })
        this.getPages(1, searhForm);
    }

    renderFiltering(sources: ISource[], categories: ICategory[], sections: ISection[]) {
        const form = this.state.searchForm;

        return (
            <div className={`card mb-1`}>
                <div className="card-body pb-0 pt-3">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                            <InputText
                                uid="search"
                                label=""
                                value={form.search}
                                placeholder="Klíčové slovo"
                                onChange={this.handleFilterOnChange.bind(this)}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                            <SelectBox
                                uid="sectionId"
                                options={SectionHelper.getSectionOptions(sections)}
                                value={form.sectionId}
                                label=""
                                defaultValue="Sekce"
                                onChange={this.handleFilterOnChange.bind(this)}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                            <SelectBox
                                uid="categoryId"
                                options={CategoryHelper.getCategoryOptions(categories)}
                                value={form.categoryId}
                                label=""
                                defaultValue="Kategorie"
                                onChange={this.handleFilterOnChange.bind(this)}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                            <SelectBox
                                uid="sourceId"
                                options={SourceHelper.getSourceOptions(sources)}
                                value={form.sourceId}
                                label=""
                                defaultValue="Zdroj dat"
                                onChange={this.handleFilterOnChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>)
    }

    renderTable(pages: IPage[], sources: ISource[], categories: ICategory[], website: IWebsite, sections: ISection[]) {
        return (
            <>
                <div className={`card`}>
                    <div className="card-body">
                        <PagesTable>
                            {pages.map(page => {
                                const source = find(sources, { id: page.sourceId });
                                const pageCategories = filter(categories, cat => {
                                    if (page.categoryIds.includes(cat.id)) {
                                        return true;
                                    }
                                    return false;
                                })
                                const section = find(sections, { id: page.sectionId });

                                return (
                                    <PageTableRow
                                        key={page.id}
                                        page={page}
                                        categories={pageCategories}
                                        source={source}
                                        website={website}
                                        section={section}
                                    />
                                )
                            })}
                        </PagesTable>

                        <div className="text-center">
                            {pages.length === 0 && <h6 className="py-3">Nic nenalezeno</h6>}
                        </div>

                        <CustomPagination
                            currentPageNumber={this.state.pageNumber}
                            itemsPerPage={itemsPerPage}
                            loading={this.state.loading}
                            onChangePage={this.handleChangePage.bind(this)}
                            totalItemsCount={this.state.totalCount}
                        />
                    </div>
                </div>
            </>
        )
    }

    handleCreateNew() {
        this.setState({
            showCreateNewModal: true
        })
    }

    render() {
        const pages = orderBy(this.props.pageStore.getByIds(this.state.pageIds), ['createdUtc'], ['desc']);
        const loading = this.state.loading;
        const websiteId = this.props.match.params.websiteId;
        const website = this.props.websiteStore.getById(websiteId);
        const sources = this.props.sourceStore.getByWebsiteId(websiteId);
        const categories = this.props.categoryStore.getByWebsiteId(websiteId);
        const sections = this.props.sectionStore.getByWebsiteId(websiteId);

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Stránky">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <div className="dashboard-header-title">
                                <h5 className="mb-0">Stránky</h5>
                                <p className="mb-0 font-15">Vytvářejte různorodý obsah.</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <button onClick={this.handleCreateNew.bind(this)} className="btn btn-primary ml-1">Vytvořit stránku</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showCreateNewModal &&
                        <CreateNewPageModal
                            website={website}
                            onHide={() => this.setState({ showCreateNewModal: false })}
                            sections={sections}
                        />}

                    {website.pagesCount > 0 && this.renderFiltering(sources, categories, sections)}

                    {loading && <LoadingScreen />}

                    {!loading && website.pagesCount > 0 && this.renderTable(pages, sources, categories, website, sections)}

                    {!loading && website.pagesCount === 0 &&
                        <NoPagesScreen
                            onCreateNew={this.handleCreateNew.bind(this)}
                        />}
                </WebsiteDocumentTitle>
            </>
        )
    }
}