interface IProps {
    onCreateNew(): void
}

export const NoSectionsScreen = (props: IProps) => {

    return (
        <div className="card">
            <div className="card-body text-center">
                <div className="py-5">
                    <div className="d-flex justify-content-center mb-0 mt-2">
                        <h5>Sekce je skupina stránek stejného typu obsahu</h5>
                    </div>

                    <p>Můžete mít sekci s příspěvky, produkty atd.</p>
                    <div className="text-center mt-2">
                        <button
                            onClick={props.onCreateNew.bind(this)}
                            className="btn btn-primary mt-2">
                            Vytvořit sekci
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}