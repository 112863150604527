import IFormError from '../../../components/forms/IFormError';
import BaseComponent from '../../../components/baseComponent';
import { filter } from 'lodash';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import React from 'react';
import InputText from '../../../components/inputs/inputText';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import { inject, observer } from 'mobx-react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import { BtnSubmit } from '../../../components/inputs/btnSubmit';
import { history } from './../../../App';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';
import GaAccountStore from '../../../stores/GaAccountStore';
import SmartSelectBox from '../../../components/inputs/smartSelectBox';
import GaAccountHelper from '../../../helpers/gaAccountHelper';
import { Alert } from 'react-bootstrap';

interface IState {
    loading: boolean,
    serverErrors: IFormError[],
    form: {
        gaCurrencyCode: string,
        gaTimeZone: string,
        gaAccountName: string,
    },
    loadingSubmit: boolean,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    gaAccountStore?: GaAccountStore,
}

interface IMatchParams {
    websiteId: string,
}

@inject('currentUserStore', 'websiteStore', 'gaAccountStore')
@observer
export default class WebsiteGaSettingsPage extends BaseComponent<IProps, IState> {

    private inputGaCurrencyCode = React.createRef<InputText>();
    private inputGaTimeZone = React.createRef<InputText>();
    private inputGaAccountName = React.createRef<SmartSelectBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            serverErrors: [],
            form: {
                gaAccountName: '',
                gaCurrencyCode: '',
                gaTimeZone: '',
            },
            loadingSubmit: false,
        }

        this.inputGaCurrencyCode = React.createRef();
        this.inputGaTimeZone = React.createRef();
        this.inputGaAccountName = React.createRef();
    }

    componentDidMount() {
        const websiteId = this.props.match.params.websiteId;

        this.usersApiService.getGaAccounts()
            .then(data => {
                this.setState({

                })
            })

        this.websitesApiService.getWebsite(websiteId)
            .then(data => {
                const website = this.props.websiteStore.getById(websiteId);

                this.setState({
                    loading: false,
                    form: {
                        gaAccountName: website.gaAccountName,
                        gaCurrencyCode: website.gaCurrencyCode,
                        gaTimeZone: website.gaTimeZone,
                    },
                })
            })
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleSubmit() {
        const inputs = [
            this.inputGaCurrencyCode,
            this.inputGaTimeZone,
            this.inputGaAccountName,
        ];

        const isValid = this.validateInputs(inputs);
        const websiteId = this.props.match.params.websiteId;
        const form = this.state.form;

        if (isValid) {
            this.setState({ loadingSubmit: true });

            this.websitesApiService.setupGoogleAnalytics(websiteId, form.gaCurrencyCode, form.gaTimeZone, form.gaAccountName)
                            .then(data => {
                                this.setState({
                                    loadingSubmit: false
                                })
            
                                if (data.success) {
                                    this.toastSuccess('Změny uloženy');
                                    history.push(`/websites/${websiteId}/manage`)
                                }
                                else {
                                    this.toastError('Došlo k chybě');
                                } 
                            })
        }
    }

    render() {
        const websiteId = this.props.match.params.websiteId;
        const websiteServer = this.props.websiteStore.getById(websiteId);
        const form = this.state.form;
        const { loading, serverErrors, loadingSubmit } = this.state;
        const website = this.props.websiteStore.getById(websiteId);
        const gaAccounts = this.props.gaAccountStore.getByUserId(website.userId);

        if (loading) {
            return <LoadingScreen />;
        }

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Google Analytics">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title mb-20">Google Analytics</h5>

                                    {website.gaEnabled && < Alert variant={'success'}>
                                        Google Analytics je nastaveno :-)
                                    </Alert>}

                                    <InputText
                                        uid="gaCurrencyCode"
                                        ref={this.inputGaCurrencyCode}
                                        label="Měna"
                                        value={form.gaCurrencyCode}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                    />

                                    <InputText
                                        uid="gaTimeZone"
                                        ref={this.inputGaTimeZone}
                                        label="Časová zóna"
                                        value={form.gaTimeZone}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                    />

                                    <SmartSelectBox
                                        uid="gaAccountName"
                                        ref={this.inputGaAccountName}
                                        label="Účet"
                                        value={form.gaAccountName}
                                        options={GaAccountHelper.getGaAccountOptions(gaAccounts)}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                    />

                                  
                                        <BtnSubmit
                                            loading={loadingSubmit}
                                            onClick={this.handleSubmit.bind(this)}
                                            className="btn btn-primary"
                                        >
                                        {website.gaEnabled ? 'Uložit změny' : 'Nastavit propojení'}
                                    </BtnSubmit> 
                                </div>
                            </div>
                        </div>
                    </div>
                </WebsiteDocumentTitle>
            </>
        )
    }
}