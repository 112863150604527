import axios, { AxiosError } from 'axios';
import StoreUtil from '../stores/storeUtil';
import { history } from './../App';

axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
    // Edit request config
    return request;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    if (response.data) {
        if ((response.data.format || '') === 'universal') {
            StoreUtil.process(response.data);
        }
    }
   
    return response;
}, (error: AxiosError) => {
    if (axios.isCancel(error) === false) {
        if (error.response) {
            if (error.response.status === 403) {
               /* Swal.fire(
                    'K této akci nemáš patřičná oprávnění',
                    error.response.data.error,
                    'error')*/
            }
            if (error.response.status === 503) {
              /*  Swal.fire(
                    'Aplikace je dočasně nedostupná',
                    'Zřejmě probíhá údržba aplikace. Zkuste to později.',
                    'error')*/
            }
            if (error.response.status === 401 && window.location.pathname !== 'login') {
                history.push('/login');
            }
            if (error.response.data.format === 'universal') {
                StoreUtil.process(error.response.data);
            }
        }
    }
    return Promise.reject(error);
});