import React from "react";
import IWebsite from "../../interfaces/IWebsite";

interface IProps {
    website: IWebsite,
}

export const WebsiteStatus = (props: IProps) => {
    const website = props.website;

    if (website.pendingDelete) {
        return <span className="text-danger"><i className="text-danger fa fa-circle blink d-none"></i> Odstraňování</span>
    }
    else if (website.deleted) {
        return <span className="text-danger"><i className="text-danger fa fa-circle d-none"></i> Smazáno</span>
    }
    else if (website.awsWaitingChangeNameServers) {
        return <span className="text-warning "><i className="fa fa-circle blink d-none"></i> Čekající na změnu DNS</span>
    }
    else if (website.awsPendingDistributionSetup) {
        return <span className="text-warning"><i className="fa fa-circle blink d-none"></i> Zřizování</span>
    }
    else if (website.active) {
        return null;
       //  return <span className="text-success "><i className="fa fa-circle "></i> Online</span>
    }
    else if (website.active === false) {
        return <span className="text-danger"><i className="fa fa-circle d-none"></i> Offline</span>
    }
}