import App from "../App";
import ISection from "../interfaces/ISection";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class SectionsApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getSection(id: string): Promise<IResponseData> {
        return this.getRequest(`sections/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteSection(id: string): Promise<IResponseData> {
        return this.deleteRequest(`sections/${id}`, {}, {})
            .then(response => {
                App.instance.pageStore.removeById(id);
                return response.data;
            })
    }

    public editSection(section: ISection): Promise<IResponseData> {
        return this.putRequest(`sections/${section.id}`, section, {})
            .then(response => {
                return response.data;
            })
    }
}