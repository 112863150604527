import React from 'react';
import BaseComponent from '../../baseComponent';
import IPageDesign from '../../../interfaces/IPageDesign';
import IFormError from '../../forms/IFormError';
import { Accordion, Card } from 'react-bootstrap';
import Textarea from '../../inputs/textarea';

interface IState {
}

interface IProps {
    pageDesignEdit: IPageDesign,
    pageDesignServer: IPageDesign,
    onChange(value: any, key: string): void,
    serverErrors: IFormError[],
}

export default class SourcePageDesignImagesCard extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {

        }
    }

    validateForm() {
        const inputs: any = [

        ];

        const isValid = this.validateInputs(inputs);

        return isValid;
    }

    render() {
        const pageDesignEdit = this.props.pageDesignEdit;
        var onChange = this.props.onChange;

        return (
            <Card className="mb-0 pb-0">
                <Accordion.Toggle as={Card.Header} className="clickable" eventKey="images">
                    Obrázky <span className="float-right"><i className="fas fa-angle-down ml-2 mt-1"></i></span>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="images">
                    <Card.Body>

                        <Textarea
                            uid="imageUrls"
                            label="Cesty k obrázkům"
                            value={(pageDesignEdit.imageUrls || []).map(im => im || '').toString()}
                            onChange={(value: string, key: string) => this.props.onChange(value.split(/\n/), key)}
                        />

                        <Textarea
                            uid="imageIds"
                            label="Vložte idečka obrázků, které chcete přiřadit"
                            value={(pageDesignEdit.imageIds || []).map(id => id || '').join('\n').toString()}
                            onChange={(value: string, key: string) => onChange(value.split('\n'), key)}
                        />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>)
    }
}