import IOptionData from "../components/inputs/IOptionData";
import ITheme from "../interfaces/ITheme";

export default class ThemeHelper {

    public static getThemeOptions(themes: ITheme[]): IOptionData[] {
        const options: IOptionData[] = [];
        themes.map(theme => {
            options.push({ label: theme.name, value: theme.id });
        })
        return options;
    }
}