import React from 'react';
import IFormError from './IFormError';

interface IProps {
    errors: IFormError[]
}

export const FormErrorSummary = (props: IProps) => {

    if (props.errors.length === 0) {
        return null;
    }
    else {
        return <>
            <div className="alert alert-danger text-center">
                <ul className="list-unstyled">
                    {props.errors.map((error: IFormError, index: number) => {
                        return (<li key={index}>{error.message}</li>)
                    })}
                </ul>
            </div>
        </>
    }
}