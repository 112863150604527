import IOptionData from "../components/inputs/IOptionData";
import ISource from "../interfaces/ISource";

export default class SourceHelper {
    public static getSourceOptions(sources: ISource[]): IOptionData[] {
        const sourceOptions: IOptionData[] = [];
        sources.map(source => {
            sourceOptions.push({ label: source.name, value: source.id });
        })
        return sourceOptions;
    }
}