import React from 'react';
import BaseComponent from '../components/baseComponent';
import ErrorPage from '../pages/special/ErrorPage';

interface IState {
    hasError: boolean
}

interface IProps {

}

export default class ErrorBoundary extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error: boolean) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any) {
        // You can also log the error to an error reporting service
        // ErrorReporter.error(error);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />
        }
        return this.props.children;
    }
}