import BaseComponent from '../baseComponent';
import Swal from "sweetalert2";
import ICategory from '../../interfaces/ICategory';
import EditCategoryModal from './editCategoryModal';
import { ClipLoader } from 'react-spinners';
import StringHelper from '../../helpers/stringHelper';

interface IState {
    loading: boolean,
    showEditModal: boolean,
}

interface IProps {
    category: ICategory
}

export default class CategoryTableRow extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            showEditModal: false,
        }
    }

    handleEdit() {
        this.setState({
            showEditModal: true
        })
    }


    handleDelete() {
        const category = this.props.category;

        Swal.fire({
            title: `Opravdu chcete smazat kategorii "${category.name}"?`,
            html: `Akce je nevratná.`,
            icon: 'warning',
            confirmButtonText: 'Ano, smazat',
            cancelButtonText: 'Zrušit',
            showCancelButton: true,
            focusCancel: true,
        }).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loading: true
                })

                this.categoriesApiService.deleteCategory(category.id);
            }
        })
    }

    renderActions() {
        const loading = this.state.loading;

        if (loading) {
            return (
                <ClipLoader
                    size={30}
                    color={"#449DF7"}
                    loading={true}
                />)
        }
        else {
            return (
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button
                        className="action-icon"
                        onClick={this.handleEdit.bind(this)}
                    >
                        <i className="zmdi zmdi-edit"></i>
                    </button>

                    <button
                        className="action-icon"
                        onClick={this.handleDelete.bind(this)}
                    >
                        <i className="zmdi zmdi-delete"></i>
                    </button>
                </div>
            )
        }
    }

    render() {
        const category = this.props.category;
       
        return (
            <tr>
                <td>
                    {category.name}
                </td>

                <td>
                    {category.slug}
                </td>

                <td>{StringHelper.truncateLongString(StringHelper.stripHtml(category.description), 25)}</td>

                <td>
                    {category.pagesCount}
                </td>

                <td>
                    {this.state.showEditModal &&
                        <EditCategoryModal
                            onHide={() => this.setState({ showEditModal: false })}
                            category={category}
                        />}

                    {this.renderActions()}
                </td>
            </tr>)
    }
}