import React, { Component } from 'react'
import BaseComponent from './../baseComponent';

interface IProps {
    provider: any,
    disabled: boolean,
}

interface IState {

}

export default class OAuth extends BaseComponent<IProps, IState> {
    popup: any;

    constructor(props: IProps) {
        super(props);
    }

    openPopup() {
        const { provider } = this.props
        const width = 800, height = 800
        const left = (window.innerWidth / 2) - (width / 2)
        const top = (window.innerHeight / 2) - (height / 2)
        const url = `/api/users/connect?provider=${provider}`;

        return window.open(url, '',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
        )
    }

    startAuth = () => {
        if (!this.props.disabled) {
            this.openPopup()
        } 
    }

    render() {
        return (
            <a 
                onClick={this.startAuth}
                className={`btn btn-${this.props.provider} m-2 ${this.props.disabled ? 'disabled' : ''}`}>
                {this.props.children}
            </a>
        )
    }
}