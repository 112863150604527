import React from 'react';
import DocumentTitle from 'react-document-title';
import IWebsite from '../interfaces/IWebsite';

interface IProps {
    title: string,
    website: IWebsite,
    children?: React.ReactNode;
}

export const WebsiteDocumentTitle = (props: IProps) => {
    let title = props.title;
    const website = props.website;

    title += ' | ' + website.name;

    return (
        <DocumentTitle title={title}>
            <div>
                {props.children}
            </div>
        </ DocumentTitle >
    );
}