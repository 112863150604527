import React from 'react';
import { Modal } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IVariable from '../../interfaces/IVariable';
import Textarea from '../inputs/textarea';
import CheckBox from '../inputs/checkBox';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';
import { FormErrorSummary } from '../forms/FormErrorSummary';

interface IState {
    variable: IVariable,
    loading: boolean,
    serverErrors: IFormError[],
}

interface IProps {
    variable: IVariable,
    onHide(): void,
}

export default class EditVariableModal extends BaseComponent<IProps, IState> {

    private inputKey: React.RefObject<InputText>;
    private inputValue: React.RefObject<Textarea>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            variable: this.props.variable,
            loading: false,
            serverErrors: []
        }

        this.inputKey = React.createRef();
        this.inputValue = React.createRef();
    }

    handleSubmit() {
        const isValid = this.validateInputs([
            this.inputKey,
            this.inputValue,
        ])

        const variableEdit = this.state.variable;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.variablesApiService.editVariable(variableEdit)
                .then(data => {
                    if (data.success) {
                        this.toastSuccess('Proměnná byla upravena');
                        this.props.onHide();
                    }
                    else {
                        this.setState({
                            loading: false,
                            serverErrors: data.formErrors
                        })
                    }
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            variable: {
                ...this.state.variable,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    render() {
        const loading = this.state.loading;
        const variableServer = this.props.variable;
        const variableEdit = this.state.variable;

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Úprava proměnné {variableServer.key}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputText
                        uid="key"
                        ref={this.inputKey}
                        label="Klíč"
                        onChange={this.handleChange.bind(this)}
                        value={variableServer.key}
                        readonly
                    />

                    <Textarea
                        uid="value"
                        ref={this.inputValue}
                        label="Hodnota"
                        onChange={this.handleChange.bind(this)}
                        value={variableEdit.value}
                        required
                        rows={3}
                    />

                    <CheckBox
                        uid="addAsParam"
                        label={`Přidat jako parametr (Site.Params.${variableEdit.key})`}
                        checked={variableEdit.addAsParam}
                        onChange={this.handleChange.bind(this)}
                    />

                    <CheckBox
                        uid="isJsonObject"
                        label={`Jedná se o JSON object`}
                        checked={variableEdit.isJsonObject}
                        onChange={this.handleChange.bind(this)}
                        
                    />

                    <FormErrorSummary errors={this.state.serverErrors} />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Uložit změny
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}