import App from "../App";
import IOptionData from "../components/inputs/IOptionData";
import IPage from "../interfaces/IPage";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class PagesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getPage(id: string): Promise<IResponseData> {
        return this.getRequest(`pages/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public deletePage(id: string): Promise<IResponseData> {
        return this.deleteRequest(`pages/${id}`, {}, {})
            .then(response => {
                App.instance.pageStore.removeById(id);
                return response.data;
            })
    }

    public editPage(id: string, page: IPage): Promise<IResponseData> {
        return this.putRequest(`pages/${id}`, page , {})
            .then(response => {
                return response.data;
            })
    }

    public getPagesOptions(websiteId: string, search: string): Promise<IOptionData[]> {
        return this.getRequest(`pages/options`, { websiteId, search})
            .then(response => {
                return response.data as unknown as IOptionData[];
            })
    }

    public uploadImage(pageId: string, file: File): Promise<IResponseData> {
        const formData = new FormData()
        formData.append('file', file, file.name)

        return this.postRequest(`pages/${pageId}/images`, formData, {})
            .then(response => {
                return response.data;
            })
    }
}