import IFormError from '../../../components/forms/IFormError';
import BaseComponent from '../../../components/baseComponent';
import { filter } from 'lodash';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import React from 'react';
import InputText from '../../../components/inputs/inputText';
import IWebsite from '../../../interfaces/IWebsite';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import { inject, observer } from 'mobx-react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import { BtnSubmit } from '../../../components/inputs/btnSubmit';
import ThemeStore from '../../../stores/ThemeStore';
import SelectBox from '../../../components/inputs/selectBox';
import ThemeHelper from '../../../helpers/themeHelper';
import { history } from './../../../App';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';
import CheckBox from '../../../components/inputs/checkBox';
import LanguageHelper from '../../../helpers/languageHelper';
import InputNumber from '../../../components/inputs/inputNumber';

interface IState {
    loading: boolean,
    serverErrors: IFormError[],
    website: IWebsite,
    loadingSubmit: boolean,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    themeStore?: ThemeStore,
}

interface IMatchParams {
    websiteId: string,
}

@inject('currentUserStore', 'websiteStore', 'themeStore')
@observer
export default class WebsiteBasicSettingsPage extends BaseComponent<IProps, IState> {

    private inputLanguage = React.createRef<SelectBox>();
    private inputName = React.createRef<InputText>();
    private inputTitle = React.createRef<InputText>();
    private inputTheme = React.createRef<SelectBox>();
    private inputItemsPerPage = React.createRef<InputNumber>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            serverErrors: [],
            website: null,
            loadingSubmit: false,
        }

        this.inputLanguage = React.createRef();
        this.inputName = React.createRef();
        this.inputTitle = React.createRef();
        this.inputTheme = React.createRef();
        this.inputItemsPerPage = React.createRef();
    }

    componentDidMount() {
        const websiteId = this.props.match.params.websiteId;

        this.websitesApiService.getWebsite(websiteId)
            .then(data => {
                this.setState({
                    loading: false,
                    website: this.props.websiteStore.getById(websiteId),
                })
            })
    }

    handleChange(value: any, key: string) {
        this.setState({
            website: {
                ...this.state.website,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleSubmit() {
        const inputs = [
            this.inputLanguage,
            this.inputName,
            this.inputTitle,
            this.inputTheme,
            this.inputItemsPerPage
        ];

        const isValid = this.validateInputs(inputs);
        const websiteId = this.props.match.params.websiteId;
        const websiteEdit = this.state.website;

        if (isValid) {
            this.setState({ loadingSubmit: true });

            this.websitesApiService.editWebsiteBasicSettings(websiteId,
                {
                    name: websiteEdit.name,
                    title: websiteEdit.title,
                    themeId: websiteEdit.themeId,
                    noIndex: websiteEdit.noIndex,
                    language: websiteEdit.language,
                    itemsPerPage: websiteEdit.itemsPerPage,
                })
                .then(data => {
                    this.setState({
                        loadingSubmit: false
                    })

                    if (data.success) {
                        history.push(`/websites/${websiteId}/manage`)
                    }
                })
        }
    }

    handleDelete() {
        const websiteId = this.props.match.params.websiteId;
        this.websitesApiService.deleteWebsite(websiteId);
    }

    render() {
        const websiteId = this.props.match.params.websiteId;
        const websiteServer = this.props.websiteStore.getById(websiteId);
        const websiteEdit = this.state.website;
        const { loading, serverErrors, loadingSubmit } = this.state;
        const themes = this.props.themeStore.list;
        const website = this.props.websiteStore.getById(websiteId);

        if (loading) {
            return <LoadingScreen />;
        }

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Obecné nastavení">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title mb-20">Obecné nastavení</h5>

                                    <SelectBox
                                        uid="language"
                                        ref={this.inputLanguage}
                                        label="Jazyk"
                                        value={websiteEdit.language}
                                        options={LanguageHelper.getLanguageOptions()}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        defaultValue="Vyberte jazyk"
                                    />

                                    <InputText
                                        uid="name"
                                        ref={this.inputName}
                                        label="Název webu (neveřejný)"
                                        value={websiteEdit.name}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                    />

                                    <InputText
                                        uid="title"
                                        ref={this.inputTitle}
                                        label="Titulek webu (veřejný)"
                                        value={websiteEdit.title}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                    />

                                    <SelectBox
                                        uid="themeId"
                                        ref={this.inputTheme}
                                        label="Šablona"
                                        value={websiteEdit.themeId}
                                        options={ThemeHelper.getThemeOptions(themes)}
                                        defaultValue="Vyberte"
                                        required
                                        onChange={this.handleChange.bind(this)}
                                    />

                                    <InputNumber
                                        uid="itemsPerPage"
                                        ref={this.inputItemsPerPage}
                                        label="Počet záznamů na stránku"
                                        value={websiteEdit.itemsPerPage}
                                        required
                                        onChange={this.handleChange.bind(this)}
                                    />

                                    <CheckBox
                                        uid="noIndex"
                                        checked={websiteEdit.noIndex}
                                        label="Zakázat indexování"
                                        onChange={this.handleChange.bind(this)}
                                    />

                                    <a onClick={this.handleDelete.bind(this)} className="btn btn-danger float-right ml-1">Smazat web</a>

                                    <BtnSubmit
                                        loading={loadingSubmit}
                                        onClick={this.handleSubmit.bind(this)}
                                        className="btn btn-primary"
                                    >
                                        Uložit změny
                                    </BtnSubmit>
                                </div>
                            </div>
                        </div>
                    </div>
                </WebsiteDocumentTitle>
            </>
        )
    }
}