import { filter, find, findIndex } from 'lodash';
import { observable, action, makeObservable } from 'mobx'
import IGaAccount from '../interfaces/IGaAccount';

export default class GaAccountStore {

     list: IGaAccount[] = [];

   
    addRecords(records: IGaAccount[]): any {
        if (records.length > 0) {
            records.forEach((record: IGaAccount) => this.addRecord(record));
        }
    }


    private addRecord(record: IGaAccount) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { userId: record.userId, name: record.name });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    public getByName(name: string): IGaAccount {
        return find(this.list, { name: name });
    }

    public getByNames(names: string[]): IGaAccount[] {
        return filter(this.list, item => {
            if (names.includes(item.name)) {
                return true;
            }
            return false;
        })
    }

    public getByUserId(userId: string): IGaAccount[] {
        return filter(this.list, { userId: userId });
    }


    public removeByName(name: string) {
        this.list = filter(this.list, item => {
            if (item.name != name) {
                return true;
            }
            return false;
        })
    }

    constructor() {
        makeObservable(this, {
            list: observable,
            addRecords: action,
            removeByName: action,
        })
    }
}