import cronstrue from "cronstrue"

interface IProps {
    cronExpression: string
}

export const CronDescription = (props: IProps) => {

    try {
        return <>{cronstrue.toString(props.cronExpression, { locale: 'cs' })}</>
    } catch (e) {
        return <>Došlo k chybě.</>
    }

}