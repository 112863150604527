import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IFormError from '../forms/IFormError';
import { filter, find } from 'lodash';
import IMenuItem from '../../interfaces/IMenuItem';
import IMenu from '../../interfaces/IMenu';
import { MenuItemType } from '../../enums/menuItemType';
import SmartSelectBox from '../inputs/smartSelectBox';
import IOptionData from '../inputs/IOptionData';

interface IState {
    loading: boolean,
    item: IMenuItem,
    serverErrors: IFormError[],
    options: IOptionData[],
}

interface IProps {
    menu: IMenu,
}

export default class CreateMenuItemPageCard extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputPage: React.RefObject<SmartSelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            item: {
                type: MenuItemType.Page,
                menuId: this.props.menu.id,
                name: '',
                categoryId: '',
                id: '',
                index: 0,
                pageId: '',
                parentMenuItemId: '',
                sectionId: '',
                url: ''
            },
            serverErrors: [],
            options: [],
        }

        this.inputPage = React.createRef();
        this.inputName = React.createRef();
    }

    handleChange(value: any, key: string) {
        let item = this.state.item;
        // @ts-ignore
        item[key] = value;

        if (key === 'pageId') {
            const option = find(this.state.options, { value: value });
            if (option) item.name = option.label;
        }

        this.setState({
            item:item,
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleSearch(value: string) {
        const menu = this.props.menu;
        this.pagesApiService.getPagesOptions(menu.websiteId, value)
            .then(options => {
                this.setState({
                    options: options,
                })
            });
    }

    handleSubmit() {
        const inputs = [
            this.inputPage,
            this.inputName,   
        ]

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            const { item } = this.state;
            const { menu } = this.props;

            this.setState({
                loading: true
            })

            this.menusApiService.createMenuItem(menu.id, item)
                .then(data => {
                    if (data.success) {
                        this.setState({
                            loading: false,
                            item: {
                                ...this.state.item,
                                name: '',
                                pageId: null
                            }
                        })
                    }
                    else {
                        this.setState({
                            serverErrors: data.formErrors,
                            loading: false,
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    render() {
        const { loading, item, options } = this.state;

        return (
            <Card className="mb-0 pb-0">
                <Accordion.Toggle as={Card.Header} className="clickable text-purple" eventKey="page">
                   Stránka <i className="fas fa-angle-down float-right mt-1"></i>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="page">
                    <Card.Body>          
                        <SmartSelectBox
                            uid="pageId"
                            ref={this.inputPage}
                            label="Stránka"
                            onChange={this.handleChange.bind(this)}
                            options={options}
                            value={item.pageId}
                            required
                            onInputChange={(value) => this.handleSearch(value)}
                            placeholder="Vyhledejte"
                        />

                        <InputText
                            uid="name"
                            ref={this.inputName}
                            label="Text odkazu"
                            value={item.name}
                            onChange={this.handleChange.bind(this)}
                            required
                        />

                        <BtnSubmit
                            className="btn btn-outline-primary float-right mb-3"
                            loading={loading}
                            onClick={this.handleSubmit.bind(this)}
                        >
                            Přidat do menu
                        </BtnSubmit>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }
}