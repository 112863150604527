import * as React from 'react';
import InputText from '../inputs/inputText';
import Textarea from '../inputs/textarea';

interface IProps {
    nameKey: string,
    value: string,
    onChange(value: any, nameKey: string): void,
    onDelete(nameKey: string): void,
}

interface IState {
    errors: string[],
}

export default class PagePropertiesTableRow extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            errors: [],
        };
    }

    render() {
        const { nameKey, value } = this.props;

        return (
            <tr>
                <td>
                    <InputText
                        key={nameKey}
                        uid={nameKey}
                        value={nameKey}
                        label=""
                        readonly
                    />
                </td>

                <td>
                    <Textarea
                        key={nameKey}
                        uid={nameKey}
                        value={value}
                        label=""
                        onChange={this.props.onChange.bind(this)}
                        rows={1}
                    />
                </td>

                <td>
                    <button
                        className="action-icon"
                        onClick={this.props.onDelete.bind(this, this.props.nameKey)}
                    >
                        <i className="zmdi zmdi-delete"></i>
                    </button>
                </td>
            </tr>
        )
    }
}