import React from 'react';
import DateTimeHelper from '../../helpers/dateTimeHelper';
import StringHelper from '../../helpers/stringHelper';

interface IProps {
    date: any
}

export const ShowDateTime = (props: IProps) => {
    return (
        <span dangerouslySetInnerHTML={{
            __html: StringHelper.replaceAllSpacesToNonBreakingSpace(DateTimeHelper.convertUTCDateToLocalDate(new Date(props.date)).toLocaleString())
        }}>
        </span>
    )
}