import { inject, observer } from 'mobx-react';
import React from 'react';
import IFormError from '../../../components/forms/IFormError';
import { BtnSubmit } from '../../../components/inputs/btnSubmit';
import DictionaryKeyValueInput from '../../../components/pages/pagePropertiesEditTable';
import InputText from '../../../components/inputs/inputText';
import SelectBox from '../../../components/inputs/selectBox';
import EditorConvertToHTML from '../../../components/others/editorConvertToHTML';
import LoadingScreen from '../../../components/screens/loadingScreen';
import SectionHelper from '../../../helpers/sectionHelper';
import IPage from '../../../interfaces/IPage';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import PageStore from '../../../stores/PageStore';
import SectionStore from '../../../stores/SectionStore';
import SourceStore from '../../../stores/SourceStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import BaseComponent from './../../../components/baseComponent';
import { history } from './../../../App';
import { filter } from 'lodash';
import InputDatePicker from '../../../components/inputs/inputDatePicker';
import MultiSelectBox from '../../../components/inputs/multiSelectBox';
import CategoryHelper from '../../../helpers/categoryHelper';
import CategoryStore from '../../../stores/CategoryStore';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';
import { SlateEditor } from '../../../components/others/SlateEditor';
import { ImagesGrid } from '../../../components/images/imagesGrid';
import ImageStore from '../../../stores/ImageStore';
import { UploadPageImageModal } from '../../../components/images/uploadPageImageModal';

interface IState {
    loading: boolean,
    serverErrors: IFormError[],
    page: IPage,
    loadingSubmit: boolean,
    createNewProperty: boolean,
    showNewUploadModal: boolean,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    sourceStore?: SourceStore,
    pageStore?: PageStore,
    sectionStore?: SectionStore,
    categoryStore?: CategoryStore,
    imageStore?: ImageStore
}

interface IMatchParams {
    websiteId: string,
    pageId: string,
}

@inject('currentUserStore', 'websiteStore', 'sourceStore', 'pageStore', 'sectionStore', 'categoryStore', 'imageStore')
@observer
export default class PageEditPage extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputSlug: React.RefObject<InputText>;

    private inputDate: React.RefObject<InputDatePicker>;
    private inputLayout: React.RefObject<InputText>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            serverErrors: [],
            page: null,
            loadingSubmit: false,
            createNewProperty: false,
            showNewUploadModal: false,
        }

        this.inputName = React.createRef();
        this.inputSlug = React.createRef();

        this.inputDate = React.createRef();
        this.inputLayout = React.createRef();
    }

    componentDidMount() {
        const pageId = this.props.match.params.pageId;

        this.pagesApiService.getPage(pageId)
            .then(data => {
                const page = data.records.pages.items[0];

                this.setState({
                    loading: false,
                    page: page
                })
            })
    }

    handleChange(value: any, key: string) {
        this.setState({
            page: {
                ...this.state.page,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleSubmit() {
        const inputs: any = [
            this.inputName,
            this.inputSlug,

            this.inputDate,
        ];

        const isValid = this.validateInputs(inputs);
        const pageEdit = this.state.page;

        if (isValid) {
            this.setState({
                loadingSubmit: true
            })

            this.pagesApiService.editPage(pageEdit.id, pageEdit)
                .then(data => {
                    if (data.success) {
                        history.push(`/websites/${pageEdit.websiteId}/pages`);
                    }
                    else {
                        this.setState({
                            loadingSubmit: false,
                            serverErrors: data.formErrors,
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    handleCreateNewProperty() {
        this.setState({
            createNewProperty: true
        })
    }

    setImageIds(ids: string[]) {
        this.setState({
            page: {
                ...this.state.page,
                imageIds: ids
            }
        })
    }

    render() {
        const websiteId = this.props.match.params.websiteId;
        const website = this.props.websiteStore.getById(websiteId);
        const pageServer = this.props.pageStore.getById(this.props.match.params.pageId);
        const pageEdit = this.state.page;
        const { loadingSubmit, loading, serverErrors } = this.state;
        const sections = this.props.sectionStore.getByWebsiteId(websiteId);
        const categories = this.props.categoryStore.getByWebsiteId(websiteId);

        if (loading) {
            return <LoadingScreen />
        }

        const selectedCategories = this.props.categoryStore.getByIds(pageEdit.categoryIds);
        const images = this.props.imageStore.getByIds(pageEdit.imageIds);
        const showNewUploadModal = this.state.showNewUploadModal;
        return (
            <>
                <WebsiteDocumentTitle website={website} title={`Editace stránky "${pageServer.name}"`}>
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className="card my-1">
                                <div className="card-body">
                                    <h5 className="mb-3">{pageServer.name}</h5>

                                    <InputText
                                        uid="name"
                                        ref={this.inputName}
                                        label="Nadpis"
                                        onChange={this.handleChange.bind(this)}
                                        serverErrors={serverErrors}
                                        value={pageEdit.name}
                                        required
                                    />

                                    <InputText
                                        uid="slug"
                                        ref={this.inputSlug}
                                        label="Slug"
                                        onChange={this.handleChange.bind(this)}
                                        serverErrors={serverErrors}
                                        value={pageEdit.slug}
                                        required
                                    />

                                    <EditorConvertToHTML
                                        uid="description"
                                        value={pageEdit.description}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="card my-1">
                                <div className="card-body">
                                    <div className="row align-items-center mb-4">
                                        <div className="col-6">
                                            <h5 className="card-title mb-10">Obrázky</h5>                
                                        </div>

                                        <div className="col-6">
                                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                                <div className="dashboard-btn-group d-flex align-items-center">
                                                    <button onClick={() => this.setState({ showNewUploadModal: true })} className="btn btn-primary ml-1 float-end">Nahrát obrázky</button>

                                                    {showNewUploadModal && <UploadPageImageModal
                                                        page={pageServer}
                                                        onHide={() => this.setState({ showNewUploadModal: false })}
                                                        onUploaded={(ids) => this.setImageIds(pageEdit.imageIds.concat(ids))}
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ImagesGrid images={images} website={website} />
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title mb-10">Custom fields</h5>

                                    <DictionaryKeyValueInput
                                        uid="properties"
                                        dictionary={pageEdit.properties}
                                        onChange={this.handleChange.bind(this)}
                                        onCreateNew={this.handleCreateNewProperty.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="editPageSidebar">
                                <div className="editPageSticky">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title mb-10">Nastavení</h5>

                                            <SelectBox
                                                uid="sectionId"
                                                label="Sekce"
                                                options={SectionHelper.getSectionOptions(sections)}
                                                onChange={this.handleChange.bind(this)}
                                                defaultValue="Vyberte"
                                                value={pageEdit.sectionId}
                                                required
                                            />

                                            <InputDatePicker
                                                uid="publishedUtc"
                                                ref={this.inputDate}
                                                date={pageEdit.publishedUtc}
                                                label="Datum"
                                                onChange={this.handleChange.bind(this)}
                                                required={true}
                                            />

                                            <MultiSelectBox
                                                uid="categoryIds"
                                                options={CategoryHelper.getCategoryOptions(categories)}
                                                selected={CategoryHelper.getCategoryOptions(selectedCategories)}
                                                label="Kategorie"
                                                onChange={this.handleChange.bind(this)}
                                            />

                                            <InputText
                                                uid="layout"
                                                ref={this.inputLayout}
                                                label="Layout"
                                                onChange={this.handleChange.bind(this)}
                                                serverErrors={serverErrors}
                                                value={pageEdit.layout}
                                                required
                                            />

                                            <InputText
                                                uid="uid"
                                                label="UID"
                                                onChange={this.handleChange.bind(this)}
                                                serverErrors={serverErrors}
                                                value={pageEdit.uid}
                                                required
                                            />

                                            <BtnSubmit
                                                className="btn btn-primary btn-block mt-3"
                                                onClick={this.handleSubmit.bind(this)}
                                                loading={loadingSubmit}
                                            >
                                                Uložit změny
                                            </BtnSubmit>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WebsiteDocumentTitle >
            </>
        )
    }
}