import BaseComponent from './../baseComponent';
import React from 'react';
import { IWizardStep } from '../../interfaces/wizard/IWizardStep';

interface IState {

}

interface IProps {
    steps: IWizardStep[],
    currentStep: IWizardStep,
    onGoNext(): void,
    onGoBack(): void,
    onGoToStep(orderNumber: number): void,
}

export default class Wizard extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = ({
            currentStepNumber: 1
        })
    }

    renderBottomNavigation(steps: IWizardStep[]) {
        const currentStep = this.props.currentStep;

        return (
            <div className="wizardStepNavigation m-1">
                {currentStep.orderNumber > 1 && 
                    <button
                        onClick={this.props.onGoBack.bind(this)}
                    disabled={currentStep.orderNumber === 1}
                        type="button"
                        className="btn btn-secondary float-left"
                    >
                        <i className="fas fa-angle-left mr-1"></i> Zpět
                     </button>}

                {currentStep.orderNumber < this.props.steps.length && this.props.currentStep.showNextStepButton && < button
                    onClick={this.props.onGoNext.bind(this)}
                    type="button"
                    className="btn btn-primary float-right"
                >
                    Pokračovat <i className="fas fa-angle-right ml-1"></i>
                </button>}
            </div>
        )
    }

    render() {
        const currentStepNumber = this.props.currentStep.orderNumber;
        const steps = this.props.steps;

        return (
            <div>
                <div className="row" style={{ marginLeft: '0px', marginRight: '0px'}}>
                    {steps.map((step: IWizardStep, index: number) => {
                        return (
                            <div key={index} style={{ minWidth: '135px', marginTop: '1px' }} className={`col p-0 ${steps.length === index + 1 ? '' : ''} ${index === 0 ? '' : ''}`}>
                                <div
                                    onClick={this.props.onGoBack.bind(this)}
                                    className={`${(currentStepNumber > index + 1 && step.allowGoToPast === true) ? 'clickable' : 'disabled'} wizardTopNav py-3 ${currentStepNumber === index + 1 ? 'active' : (currentStepNumber > index + 1 ? 'completed' : 'inactive')}`}>{index + 1}. {step.title}</div>
                            </div>)
                    })}
                </div>

                <div className="card pb-3">
                    <div className="card-body">
                        <div className="mx-2">
                            {this.props.children}
                            {/* this.renderBottomNavigation(steps) */}
                        </div>
                    </div>
                </div>
            </div>)
    }
}