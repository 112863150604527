import { filter, find, isNil } from 'lodash';
import * as React from 'react';
import IInputField from './IInputField';
import IOptionData from './IOptionData';
import Select from 'react-select'
import BaseComponent from './../baseComponent';
import IFormError from '../forms/IFormError';

interface IProps {
    uid: string,
    required?: boolean;
    label: string;
    options: IOptionData[];
    value: any,
    onChange(selectedIds: string[], key: string): void,
    serverErrors?: IFormError[],
    placeholder?: string,
    isClearable?: boolean,
    onInputChange?(value: string): void,
}

interface IState {
    errors: string[],
}

export default class SmartSelectBox extends BaseComponent<IProps, IState> implements IInputField {
    private input: React.RefObject<Select>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }


    getErrors(value = this.props.value || '') {
        let errors: string[] = [];

      /*  if (this.props.validate) {
            this.props.validate(
                this.props.uid, value || '',
                errors,
                () => this.validateInput(value, errors),
            );
            if (errors.length !== 0) {
                return errors;
            }
        }
        */
        this.validateInput(value, errors);
        return errors;
    }

    isValid() {
        return this.getErrors().length === 0;
    }

    validate(value = this.props.value || '') {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    validateInput(value: string, errors: string[]) {
        if (this.props.required === false && (this.props.value || '').length === 0) {
            return;
        }

        const serverErrors = filter(this.props.serverErrors, error => {
            if (error.key.toLowerCase() === this.props.uid.toLowerCase()) {
                return true;
            }
            return false;
        })

        serverErrors.map((error) => (errors.push(error.message)));

        if (this.props.required && (value || '').length === 0) {
            errors.push("Toto pole je povinné");
        }
    }

    focus() {
        this.input.current.focus();
    }

    handleChange(value: IOptionData) {
        if (value) {
            this.props.onChange(value.value, this.props.uid);
        }
        else {
            this.props.onChange(null, this.props.uid);
        } 
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        const { options, value, placeholder, isClearable } = this.props;

        return (
            <>
                <Select
                    key={`selected-${value}`}
                    ref={this.input}
                    isSearchable={true}
                    options={options}
                    isMulti={false}
                    value={find(options, { value: value })}
                    onChange={this.handleChange.bind(this)}
                    placeholder={placeholder || 'Vyberte'}
                    isClearable={!isNil(isClearable) ? isClearable : true}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    noOptionsMessage={() => "Žádné možnosti"}
                    className="smartSelectBox"
                    classNamePrefix="smartSelectBox"
                    onInputChange={(value, x) => this.props.onInputChange && this.props.onInputChange(value)}
                />
            </>
        );
    }

    renderLabel() {
        if ((this.props.label || '').length === 0) {
            return null;
        }

        return (
            <label className="form-control-label">
                <span dangerouslySetInnerHTML={{ __html: this.props.label }}></span> {this.props.required && this.props.label.length !== 0 ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="mb-3">
                <div className={`form-group position-relative mb-0`}>
                    {this.renderLabel()}
                    {this.renderInput()}
                </div>
                {this.renderError()}
            </div>
        );

    }
}