import BaseComponent from '../baseComponent';
import { ClipLoader } from 'react-spinners';
import React from 'react';
import IStorageFile from '../../interfaces/IStorageFile';
import StorageFileEditModal from './storageFileEditModal';
import { CronDescription } from '../others/cronDescription';
import StringHelper from '../../helpers/stringHelper';
import Swal from 'sweetalert2';

interface IState {
    loading: boolean,
    showEditModal: boolean,
}

interface IProps {
    storageFile: IStorageFile
}

export default class StorageFileTableRow extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            showEditModal: false,
        }
    }

    handleDelete() {
        const storageFile = this.props.storageFile;

        Swal.fire({
            title: `Opravdu chcete smazat soubor "${storageFile.name}"?`,
            html: `Akce je nevratná.`,
            icon: 'warning',
            confirmButtonText: 'Ano, smazat',
            cancelButtonText: 'Zrušit',
            showCancelButton: true,
            focusCancel: true,
        }).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loading: true
                })

                this.storageFilesApiService.deleteStorageFile(storageFile.id)
                    .then(data => {
                        if (!data.success) {
                            this.setState({
                                loading: false
                            })

                            Swal.fire({
                                title: 'Soubor nebyl smazán',
                                text: data.formErrors.map(s => s.message).join('. '),
                                icon: 'error',
                            });
                        }
                    })
            }
        })
    }

    handleEdit() {
        this.setState({
            showEditModal: true
        })
    }

    renderActions() {
        const loading = this.state.loading;

        if (loading) {
            return (
                <ClipLoader
                    size={30}
                    color={"#449DF7"}
                    loading={true}
                />)
        }
        else {
            return (<div className="btn-group" role="group" aria-label="Basic example">
                <button
                    className="action-icon"
                    onClick={this.handleEdit.bind(this)}
                >
                    <i className="zmdi zmdi-edit"></i>
                </button>

                <button
                    className="action-icon"
                    onClick={this.handleDelete.bind(this)}
                >
                    <i className="zmdi zmdi-delete"></i>
                </button>
            </div>)
        }
    }

    render() {
        const { storageFile } = this.props;
        const { showEditModal } = this.state;

        return (
            <>
                {showEditModal &&
                    <StorageFileEditModal
                        storageFile={storageFile}
                        onHide={() => this.setState({ showEditModal: false })}
                    />}
                <tr>
                    <td>
                        {storageFile.name}
                    </td>

                    <td>
                        <span className="text-primary">https://app.clonty.com/api/storage/files/<b>{storageFile.filename}</b></span>
                        <span className="d-block"><i>POST, GET</i></span>
                    </td>

                    <td>
                        {storageFile.url && <>
                            <a target="_blank" href={storageFile.url}>{StringHelper.truncateLongString(storageFile.url, 30)}</a>
                             {storageFile.downloadFromUrl === true && <span className="d-block"><CronDescription cronExpression={storageFile.importCronExpression} /></span>}
                        </>}
                    </td>

                    <td>
                        {this.renderActions()}
                    </td>
                </tr>
            </>)
    }
}