import IFormError from '../../../components/forms/IFormError';
import BaseComponent from '../../../components/baseComponent';
import { filter } from 'lodash';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import React from 'react';
import InputText from '../../../components/inputs/inputText';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import { inject, observer } from 'mobx-react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import { BtnSubmit } from '../../../components/inputs/btnSubmit';
import { history } from './../../../App';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';

interface IState {
    loading: boolean,
    serverErrors: IFormError[],
    loadingSubmit: boolean,
    newDomain: string,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
}

interface IMatchParams {
    websiteId: string,
}

@inject('currentUserStore', 'websiteStore')
@observer
export default class WebsiteChangeDomainPage extends BaseComponent<IProps, IState> {

    private inputNewDomain = React.createRef<InputText>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            serverErrors: [],
            newDomain: '',
            loadingSubmit: false,
        }

        this.inputNewDomain = React.createRef();
    }

    componentDidMount() {
        const websiteId = this.props.match.params.websiteId;

        this.websitesApiService.getWebsite(websiteId)
            .then(data => {
                this.setState({
                    loading: false,
                })
            })
    }

    handleChangeNewDomain(value: any) {
        this.setState({
            newDomain: value,
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== 'newDomain') return true; else return false })
        })
    }

    handleSubmit() {
        const inputs = [
            this.inputNewDomain,
        ];

        const isValid = this.validateInputs(inputs);
        const websiteId = this.props.match.params.websiteId;
        const newDomain = this.state.newDomain;

        if (isValid) {
            this.setState({ loadingSubmit: true });

            this.websitesApiService.changeCustomDomain(websiteId, newDomain)
                .then(data => {
                    this.setState({
                        loadingSubmit: false,
                        serverErrors: data.formErrors,
                    })

                    if (data.success) {
                        history.push(`/websites/${websiteId}/manage`)
                    }
                })
        }
    }

    render() {
        const websiteId = this.props.match.params.websiteId;
        const { loading, serverErrors, loadingSubmit } = this.state;
        const website = this.props.websiteStore.getById(websiteId);
        const newDomain = this.state.newDomain;

        if (loading) {
            return <LoadingScreen />;
        }

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Změna domény">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title mb-20">Změna domény</h5>

                                    <InputText
                                        uid=""
                                        label="Aktuální doména"
                                        value={website.domain}
                                        readonly
                                    />

                                    <InputText
                                        uid="newdomain"
                                        ref={this.inputNewDomain}
                                        label="Nová doména"
                                        value={newDomain}
                                        onChange={(value, key) => this.handleChangeNewDomain(value)}
                                        required
                                        serverErrors={serverErrors}
                                    />

                                    <BtnSubmit
                                        loading={loadingSubmit}
                                        onClick={this.handleSubmit.bind(this)}
                                        className="btn btn-primary"
                                    >
                                        Provést změnu domény
                                    </BtnSubmit>
                                </div>
                            </div>
                        </div>
                    </div>
                </WebsiteDocumentTitle>
            </>
        )
    }
}