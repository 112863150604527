import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../components/baseComponent";
import { FormErrorSummary } from "../../components/forms/FormErrorSummary";
import IFormError from "../../components/forms/IFormError";
import InputEmail from "../../components/inputs/inputEmail";
import { CustomDocumentTitle } from "../../utils/customDocumentTitle";

interface IState {
    form: {
        email: string,
    },
    confirmEmailResent: boolean,
    confirmEmailResentError: boolean,
    serverErrors: IFormError[]
}

interface IProps {
    
}

export default class ForgottenPasswordPage extends BaseComponent<IProps, IState> {

    private inputEmail = React.createRef<InputEmail>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            form: {
                email: '',
            },
            confirmEmailResent: false,
            confirmEmailResentError: false,
            serverErrors: []
        }

        this.inputEmail = React.createRef();
    }

    handleChange(key: any, value: any) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
        })
    }

    handleSubmit() {
        const isValid = this.validateInputs([
            this.inputEmail,
        ])

        if (isValid) {

        }
    }

    render() {
        return (
            <CustomDocumentTitle title={'Zapomenuté heslo'} >
                <div className="main-content- h-100vh">
                    <div className="container h-100">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-md-7 col-lg-5 mx-auto">
                                {/* Middle Box */}
                                <div className="middle-box">
                                    <div className="card">
                                        <div className="card-body p-4">
                                            <h4 className="my-2 text-center">Zapomenuté heslo</h4>

                                            <div className="mt-lg-2">
                                                <InputEmail
                                                    uid='email'
                                                    ref={this.inputEmail}
                                                    label='Váš email'
                                                    required={true}
                                                    value={this.state.form.email}
                                                    onChange={this.handleChange.bind(this)}
                                                />

                                                <FormErrorSummary
                                                    errors={this.state.serverErrors}
                                                />

                                                <button
                                                    className='btn btn-primary btn-block'
                                                    onClick={this.handleSubmit.bind(this)}
                                                >
                                                    Vytvořit účet
                                                </button>
                                            </div>

                                            <div className="text-center mt-2">
                                                <small>
                                                    <Link to='/login'>
                                                        <span
                                                            className="link"
                                                        >
                                                            Přihlásit se</span>
                                                    </Link>
                                            &nbsp;|&nbsp;
                                                    <Link to='/register'>
                                                        <span
                                                            className="link"
                                                        >
                                                            Vytvořit účet
                                                    </span>
                                                    </Link>
                                                </small>
                                            </div>
                                        </div> {/* end card-body */}
                                    </div>
                                    {/* end card */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        )
    }
}