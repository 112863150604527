import BaseComponent from '../baseComponent';
import IWebsite from '../../interfaces/IWebsite';
import { ShowDate } from '../others/showDate';
import WebsiteGroupStore from '../../stores/WebsiteGroupStore';
import { inject, observer } from 'mobx-react';
import { isNil, replace } from 'lodash';
import LanguageHelper from '../../helpers/languageHelper';
import { Link } from 'react-router-dom';
import { WebsiteStatus } from './websiteStatus';

interface IState {

}

interface IProps {
    website: IWebsite,
    websiteGroupStore?: WebsiteGroupStore
}

@inject('websiteGroupStore')
@observer
export default class WebsiteTableRow extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {

        }
    }

    render() {
        const website = this.props.website;
        const group = this.props.websiteGroupStore.getById(website.groupId);
        const p = replace(website.gaPropertyName, "roperties/", "");

        return (
            <tr>
                <td>
                    <ShowDate date={website.createdUtc} /> <br />
                   
                </td>

                <td>

                    <h6>{website.name} <span style={{ fontSize: '11px' }}> <WebsiteStatus website={website} /></span></h6>
                    <a target="_blank" href={`https://${website.domain}`}> {website.domain}</a>
                </td>

                <td>
                    {!isNil(group) ? group.name : '-'}
                </td>

                <td>
                     {LanguageHelper.getLanguageLabel(website.language)}
                </td>

                <td>
                    {website.gaEnabled ? <a target="_blank" href={`https://analytics.google.com/analytics/web/#/${p}/reports/reportinghub`}>
                        <img src="/images/google-analytics-icon.png" className={`${website.gaEnabled ? 'color' : 'grayscale'}`} style={{ height: '20px' }} />
                    </a> : <img src="/images/google-analytics-icon.png" className="grayscale" style={{ height: '20px' }} />}

                    <img src="/images/google-search-console-icon.png" className={`${website.isGoogleSearchSet ? 'color' : 'grayscale'}`} style={{ height: '20px' }} />
                </td>

                <td>
                    <Link to={`/websites/${website.id}/manage`} className="btn btn-outline-success btn-block">Spravovat</Link>
                </td>
            </tr>)
    }
}