import { round } from "lodash";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import ISource from "../../interfaces/ISource"
import { SourceErrorState } from "./sourceErrorState";
import { SourceInactiveState } from "./sourceInactiveState"

interface IProps {
    source: ISource
}

export const SourceState = (props: IProps) => {
    const source = props.source;
    const progressPercent = round((source.processedElements / source.totalFilteredElements) * 100,2);

    if (source.importing) {
        return <ProgressBar style={{ width: '130px' }} striped variant="primary" now={progressPercent} label={`Importováno ${progressPercent}%`} />

    }

    if (source.error) {
        return <SourceErrorState />
    }
   /* if (source.scheduled) {
        return <SourceActiveState />
    }*/
    else {
        return <SourceInactiveState />
    }
}