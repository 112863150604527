import React from 'react';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';
import IUser from '../../interfaces/IUser';
import { Alert } from 'react-bootstrap';

interface IState {
    form: {
        googleApiKey: string,
    },
    loading: boolean,
    serverErrors: IFormError[]
}

interface IProps {
    user: IUser
}

export default class GoogleApiKeySettings extends BaseComponent<IProps, IState> {

    private inputApiKey: React.RefObject<InputText>;

    constructor(props: IProps) {
        super(props);

        const user = this.props.user;

        this.state = {
            loading: false,
            form: {
                googleApiKey: user.googleApiKey
            },
            serverErrors: []
        }

        this.inputApiKey = React.createRef();
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleSubmit() {
        const inputs = [
            this.inputApiKey,
        ];
        const { form } = this.state;
        const isValid = this.validateInputs(inputs);    

        if (isValid) {
            this.setState({ loading: true });

            this.usersApiService.setGoogleApiKey(form.googleApiKey)
                .then(data => {
                    this.setState({
                        loading: false
                    })

                    if (data.success) {
                        this.toastSuccess("Uloženo");
                    }
                })
        }
    }

    render() {
        const { loading, form, serverErrors } = this.state;
        const userServer = this.props.user;

        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title mb-20">Google Translation</h5>
                    <p>Slouží pro automatické překlady obsahu.</p>

                    {userServer.isGoogleApiKeyInvalid === true && <Alert variant="danger">Google Api key je neplatný.</Alert>}

                    <InputText
                        uid="googleApiKey"
                        ref={this.inputApiKey}
                        required
                        label="Google Api Key"
                        onChange={this.handleChange.bind(this)}
                        serverErrors={serverErrors}
                        value={form.googleApiKey}
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Uložit změny
                    </BtnSubmit>
                </div>
            </div>
        )
    }
}