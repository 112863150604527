import React from 'react';
import { Modal } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IVariable from '../../interfaces/IVariable';
import Textarea from '../inputs/textarea';
import CheckBox from '../inputs/checkBox';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';
import { FormErrorSummary } from '../forms/FormErrorSummary';

interface IState {
    variable: IVariable,
    loading: boolean,
    serverErrors: IFormError[],
}

interface IProps {
    onHide(): void,
    websiteId?: string,
    onCreated?(variable: IVariable): void,
}

export default class CreateNewVariableModal extends BaseComponent<IProps, IState> {

    private inputKey: React.RefObject<InputText>;
    private inputValue: React.RefObject<Textarea>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            variable: {
                key: '',
                value: '',
                id: '',
                userId: '',
                websiteId: this.props.websiteId || '',
                addAsParam: false,
                isJsonObject: false,
            },
            loading: false,
            serverErrors: []
        }

        this.inputKey = React.createRef();
        this.inputValue = React.createRef();
    }

    handleSubmit() {
        const isValid = this.validateInputs([
            this.inputKey,
            this.inputValue,
        ])

        const variable = this.state.variable;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.variablesApiService.createVariable(variable)
                .then(data => {
                    if (data.success) {
                        this.toastSuccess('Proměnná byla vytvořena');
                        this.props.onCreated(data.records.variables.items[0]);
                        this.props.onHide();
                    }
                    else {
                        this.setState({
                            loading: false,
                            serverErrors: data.formErrors
                        })
                    }                 
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            variable: {
                ...this.state.variable,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    render() {
        const loading = this.state.loading;
        const variable = this.state.variable;

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Vytvořit novou proměnnou</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputText
                        uid="key"
                        ref={this.inputKey}
                        label="Klíč"
                        onChange={this.handleChange.bind(this)}
                        value={variable.key}
                        required
                        maxLength={30}
                    />

                    <Textarea
                        uid="value"
                        ref={this.inputValue}
                        label="Hodnota"
                        onChange={this.handleChange.bind(this)}
                        value={variable.value}
                        required
                        rows={3}
                    />

                    <CheckBox
                        uid="addAsParam"
                        label={`Přidat jako parametr (Site.Params.${variable.key})`}
                        checked={variable.addAsParam}
                        onChange={this.handleChange.bind(this)}
                    />

                    <CheckBox
                        uid="isJsonObject"
                        label={`Jedná se o JSON object`}
                        checked={variable.isJsonObject}
                        onChange={this.handleChange.bind(this)}
                    />

                    <FormErrorSummary errors={this.state.serverErrors} />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Vytvořit
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}