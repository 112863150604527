import * as React from 'react';
import InputText from '../inputs/inputText';
import PagePropertiesTableRow from './pagePropertiesTableRow';
import BaseComponent from './../baseComponent';
import Textarea from '../inputs/textarea';

interface IProps {
    uid: string,
    dictionary: Map<string, string>;
    onChange(value: Map<string, string>, key: string): void,
    onCreateNew(): void,
}

interface IState {
    errors: string[],
    createNewProperty: boolean,
    form: {
        nameKey: string,
        value: string
    }
}

export default class PagePropertiesEditTable extends BaseComponent<IProps, IState> {

    private inputNewKey = React.createRef<InputText>();
    private inputNewValue = React.createRef<Textarea>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            errors: [],
            createNewProperty: false,
            form: {
                nameKey: '',
                value: ''
            }
        };

        this.inputNewKey = React.createRef();
        this.inputNewValue = React.createRef();
    }

    handleChangeValue(value: any, key: string) {
        let dictionary = this.props.dictionary;
        // @ts-ignore
        dictionary[key] = value;
        this.props.onChange(dictionary, this.props.uid);
    }

    handleDelete(nameKey: string) {
        let dictionary = this.props.dictionary;

        Object.keys(dictionary).forEach(function (key) {
            // @ts-ignore
            if (key === nameKey) delete dictionary[key];
        });
        this.props.onChange(dictionary, this.props.uid);
    }

    handleChangeNew(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    handleSubmitProperty() {
        const inputs = [
            this.inputNewKey,
            this.inputNewValue,
        ];

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            const form = this.state.form;

            let dictionary = this.props.dictionary;
            // @ts-ignore
            dictionary[form.nameKey] = form.value;

            this.props.onChange(dictionary, this.props.uid);

            this.setState({
                form: {
                    nameKey: '',
                    value: '',
                },
                createNewProperty: false
            })
        }   
    }

    render() {
        const dictionary = this.props.dictionary;
        const createNewProperty = this.state.createNewProperty;
        const form = this.state.form;

        return (
            <div className="py-2">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th>Klíč</th>

                            <th>Hodnota</th>

                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {Object.keys(dictionary).map((key, i) => (
                            <PagePropertiesTableRow
                                key={key}
                                nameKey={key}
                                // @ts-ignore
                                value={dictionary[key]}
                                onChange={this.handleChangeValue.bind(this)}
                                onDelete={this.handleDelete.bind(this)}
                            />
                        ))}

                        {!createNewProperty &&
                            <tr>
                                <td colSpan={3}>
                                    <button
                                        className="btn btn-light float-right"
                                        onClick={() => this.setState({ createNewProperty: true })}
                                    >
                                        <i className="fas fa-plus mr-1"></i>  Vytvořit nové
                                </button>
                                </td>
                            </tr>}

                        {createNewProperty &&
                            <tr>
                            <td>
                                <InputText
                                    uid={"nameKey"}
                                    ref={this.inputNewKey}
                                    value={form.nameKey}
                                    label=""
                                    placeholder="Zadejte klíč"
                                    onChange={this.handleChangeNew.bind(this)}
                                    required
                                />
                            </td>

                            <td>
                                <Textarea
                                    uid={"value"}
                                    ref={this.inputNewValue}
                                    value={form.value}
                                    label=""
                                    placeholder="Zadejte hodnotu"
                                    onChange={this.handleChangeNew.bind(this)}
                                    required
                                    rows={1}
                                />
                            </td>

                            <td>
                                <button
                                    className="btn btn-primary btn-block mb-3"
                                    onClick={this.handleSubmitProperty.bind(this)}
                                >
                                   Vytvořit
                                </button>
                            </td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        );
    }
}