import React from "react";
import BaseComponent from './../baseComponent';
import IWebsite from "../../interfaces/IWebsite";
import { Modal } from "react-bootstrap";
import { BtnSubmit } from "../inputs/btnSubmit";
import InputText from "../inputs/inputText";
import { ShowDateTime } from "../others/showDateTime";

interface IState {
    loading: boolean,
}

interface IProps {
    website: IWebsite,
    onHide(): void,
}

export default class WebsiteSyncModal extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    handleSync() {
        const website = this.props.website;

        this.setState({
            loading: true
        })

        this.websitesApiService.syncWebsite(website.id)
            .then(data => {
                this.setState({
                    loading: false
                })
            })
    }

    render() {
        const { loading } = this.state;
        const { website } = this.props;

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Synchronizovat web "{website.name}"</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Aby váš web byl aktuální, je potřeba jej po úpravě obsahu/nastavení synchronizovat. <b>Než se změny projeví, může trvat několik hodin</b> z důvodu cache na straně serveru.</p>

                    <InputText
                        uid=""
                        label="Doména"
                        value={website.domain}
                        readonly
                    />

                    {website.awsPendingSync ?
                        <>
                            <button className="btn btn-primary btn-block" onClick={this.handleSync.bind(this)}>
                                Synchronizace bude provedena <ShowDateTime date={website.awsNextSyncUtc} />
                            </button>
                        </>
                        :
                        <BtnSubmit
                            loading={loading}
                            onClick={this.handleSync.bind(this)}
                        >
                            Synchronizovat
                    </BtnSubmit>}
                </Modal.Body>
            </Modal>
        )
    }
}