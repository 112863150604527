interface IProps {
    children?: any,
}

export const StorageFilesTable = (props: IProps) => {
    return (
        <div className="table-responsive">
            <table className="table table-centered">
                <thead className="thead-light">
                    <tr>
                        <th>Název</th>

                        <th>Api Endpoint</th>
     
                        <th>Stahování z url</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {props.children}
                </tbody>
            </table>
        </div>)
}