import BaseComponent from './../../components/baseComponent';
import { history } from './../../App';
import { Link } from "react-router-dom"
import IOptionData from "../../components/inputs/IOptionData"
import IWebsite from "../../interfaces/IWebsite"
import IUser from "../../interfaces/IUser"
import SmartSelectBox from "../../components/inputs/smartSelectBox";
import React from "react";
import WebsiteSyncButton from "../../components/websites/websiteSyncButton";

interface IState {
    showUserDropdown: boolean,
}

interface IProps {
    user: IUser,
    websites: IWebsite[],
    selectedWebsite: IWebsite,
}

export default class TopNavbar extends BaseComponent<IProps, IState> {

    private dropdown: React.RefObject<HTMLAnchorElement>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            showUserDropdown: false
        }
        this.dropdown = React.createRef();
        this.handleUserOutsideClick = this.handleUserOutsideClick.bind(this);
        this.handleUserClick = this.handleUserClick.bind(this);
    }

    handleChange(value: any) {
        if (value === 0) {
            history.push('/');
        }
        else {
            history.push(`/websites/${value}/manage`);
        }
    }


    handleUserOutsideClick(e: any) {
        // ignore clicks on the component itself
        if (this.dropdown.current.contains(e.target)) {
            return;
        }
        this.handleUserClick();
    }

    handleUserClick() {
        if (!this.state.showUserDropdown) {
            // attach/remove event handler
            document.addEventListener('click', this.handleUserOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleUserOutsideClick, false);
        }

        this.setState({
            showUserDropdown: !this.state.showUserDropdown
        })
    }

    handleLogout() {
        this.usersApiService.logout().then(data => {
            if (data.success) {
                window.location.assign("/login");
            }
        })
    }

    render() {
        const user = this.props.user;
        const websites = this.props.websites;
        const selectedWebsite = this.props.selectedWebsite;
        const showUserDropdown = this.state.showUserDropdown;

        const websiteOptions: IOptionData[] = [];
        websiteOptions.push({ label: 'Můj účet', value: 0 })

        websites.map(web => {
            websiteOptions.push({ label: `Web - ${web.name}`, value: web.id });
        })

        return (
            <nav className="navbar top-navbar col-lg-12 col-12 p-0">
                <div className="container-fluid">
                    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                        <Link to='/' className="navbar-brand brand-logo mt-2">
                            <h4 style={{ color: '#0168fa' }}>Clonty</h4>
                        </Link>

                        <a className="navbar-brand brand-logo-mini" href="index.html"><img src="img/core-img/small-logo.png" alt="logo" /></a>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                        <ul className="navbar-nav">
                            <li className="nav-item app-search d-none d-md-block mt-3" style={{ width: '200px' }}>
                                <div className="position-relative">
                                    <SmartSelectBox
                                        uid=''
                                        label=""
                                        options={websiteOptions}
                                        value={selectedWebsite ? selectedWebsite.id : 0}
                                        onChange={this.handleChange.bind(this)}
                                        isClearable={false}
                                    />

                                    {selectedWebsite && <WebsiteSyncButton website={selectedWebsite} />}
                                </div>
                            </li>
                        </ul>
                        <ul className="navbar-nav navbar-nav-right position-relative">
                            <li className="nav-item nav-profile dropdown dropdown-animate">
                                <a ref={this.dropdown} className="nav-link dropdown-toggle clickable" data-toggle="dropdown" id="profileDropdown" onClick={this.handleUserClick} >
                                    <i className="zmdi zmdi-account-circle feather feather-feather menu-icon mr-1 mb-1" style={{ fontSize: '20px' }}></i>
                                    <span>{user.email}</span>
                                </a>

                                <div className={`dropdown-menu dropdown-menu-right navbar-dropdown profile-top ${showUserDropdown && 'show'}`} aria-labelledby="profileDropdown">

                                    <Link to='/settings' className="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings profile-icon">
                                        <circle cx={12} cy={12} r={3} />
                                        <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                                    </svg> Nastavení
                                        </Link>

                                    <a href="#" onClick={this.handleLogout.bind(this)} className="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out profile-icon">
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                        <polyline points="16 17 21 12 16 7" />
                                        <line x1={21} y1={12} x2={9} y2={12} />
                                    </svg> Odhlásit se

                                        </a>
                                </div>
                            </li>
                        </ul>
                        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                            <span className="ti-menu" />
                        </button>
                    </div>
                </div>
            </nav>)
    }
}