import IOptionData from "../components/inputs/IOptionData";
import IGaAccount from "../interfaces/IGaAccount";

export default class GaAccountHelper {

    public static getGaAccountOptions(accounts: IGaAccount[]): IOptionData[] {
        const options: IOptionData[] = [];
        accounts.map(account => {
            if (account) {
                options.push({ label: account.displayName, value: account.name })
            }  
        })
        return options;
    }
}