
interface IProps {
    children?: any,
}

export const TranslationCostsTable = (props: IProps) => {

    return (
        <div className="table-responsive">
            <table className="table table-centered">
                <thead className="thead-light">
                    <tr>
                        <th>Cesta</th>

                        <th>Počet znaků</th>

                        <th>Cena za překlad</th>
                    </tr>
                </thead>

                <tbody>
                    {props.children}
                </tbody>
            </table>
        </div>)
}