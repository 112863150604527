import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IFormError from '../forms/IFormError';
import IMenuItem from '../../interfaces/IMenuItem';
import IMenu from '../../interfaces/IMenu';
import { MenuItemType } from '../../enums/menuItemType';
import { filter } from 'lodash';

interface IState {
    loading: boolean,
    item: IMenuItem,
    serverErrors: IFormError[],
}

interface IProps {
    menu: IMenu,
}

export default class CreateMenuItemHomepageCard extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            item: {
                type: MenuItemType.Homepage,
                menuId: this.props.menu.id,
                name: '',
                categoryId: '',
                id: '',
                index: 0,
                pageId: '',
                parentMenuItemId: '',
                sectionId: '',
                url: ''
            },
            serverErrors: [],
        }

        this.inputName = React.createRef();
    }


    handleChange(value: any, key: string) {
        let item = this.state.item;
        // @ts-ignore
        item[key] = value;


        this.setState({
            item: item,
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleSubmit() {
        const inputs = [
            this.inputName,
        ]

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            const { item } = this.state;
            const { menu } = this.props;

            this.setState({
                loading: true
            })

            this.menusApiService.createMenuItem(menu.id, item)
                .then(data => {
                    if (data.success) {
                        this.setState({
                            loading: false,
                            item: {
                                ...this.state.item,
                                name: '',
                                pageId: null
                            }
                        })
                    }
                    else {
                        this.setState({
                            serverErrors: data.formErrors,
                            loading: false,
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    render() {
        const { loading, item, } = this.state;

        return (
            <Card className="mb-0 pb-0">
                <Accordion.Toggle as={Card.Header} className="clickable text-purple" eventKey="homepage">
                    Hlavní stránka <i className="fas fa-angle-down float-right mt-1"></i>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="homepage">
                    <Card.Body>
                        <InputText
                            uid="name"
                            ref={this.inputName}
                            label="Text odkazu"
                            value={item.name}
                            onChange={this.handleChange.bind(this)}
                            required
                        />

                        <BtnSubmit
                            className="btn btn-outline-primary float-right mb-3"
                            loading={loading}
                            onClick={this.handleSubmit.bind(this)}
                        >
                            Přidat do menu
                        </BtnSubmit>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }
}