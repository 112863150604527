import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../components/screens/loadingScreen';
import { WebsiteTable } from '../../components/websites/websiteTable';
import WebsiteTableRow from '../../components/websites/websiteTableRow';
import CurrentUserStore from '../../stores/CurrentUserStore';
import WebsiteStore from '../../stores/WebsiteStore';
import { CustomDocumentTitle } from '../../utils/customDocumentTitle';
import BaseComponent from './../../components/baseComponent';

interface IState {
    loading: boolean,
    websiteIds: string[],
}

interface IProps {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
}

@inject('currentUserStore', 'websiteStore')
@observer
export default class WebsiteListPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            websiteIds: []
        }
    }

    componentDidMount() {
        this.websitesApiService.getWebsites()
            .then(data => {
                this.setState({
                    loading: false,
                    websiteIds: data.records.websites.items.map(s => s.id)
                })
            })
    }

    renderContent() {
        const websites = this.props.websiteStore.getByIds(this.state.websiteIds);

        return (
            <>
                <div className="card mt-4 mb-1 pb-0">
                    <div className="card-body">
                        <WebsiteTable>
                            {websites.map(web => {
                                return (
                                    <WebsiteTableRow
                                        key={web.id}
                                        website={web}
                                    />)
                            })}
                        </WebsiteTable>
                    </div>
                </div>

                <div className="card pt-0 mt-0">
                    <div>
                        <div className="table-responsive">
                            <table className="table table-centered">
                                <thead className="bg-light">
                                    <tr>
                                        <th>Počet webů</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>{websites.length}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        return (
            <>
                <CustomDocumentTitle title="Moje weby">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <div className="dashboard-header-title">
                                <h5 className="mb-0">Moje weby</h5>
                                <p className="mb-0 font-15">Správa webových stránek.</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <Link to='/websites/create' className="btn btn-primary ml-1">Vytvořit nový web</Link>
                                    {/* <a href="#" className="btn btn-primary ml-1"><i className="ti-bookmark-alt font-11" /> Export</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.loading ? <LoadingScreen /> : this.renderContent()}
                </CustomDocumentTitle>
            </>
        )
    }
}