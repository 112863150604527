import { inject, observer } from 'mobx-react';
import CurrentUserStore from '../../stores/CurrentUserStore';
import ThemeStore from '../../stores/ThemeStore';
import WebsiteStore from '../../stores/WebsiteStore';
import BaseComponent from '../../components/baseComponent';
import ITheme from '../../interfaces/ITheme';
import LoadingScreen from '../../components/screens/loadingScreen';
import { orderBy } from 'lodash';
import { CustomDocumentTitle } from '../../utils/customDocumentTitle';
import { ThemesTable } from '../../components/themes/themesTable';
import ThemeTableRow from '../../components/themes/themeTableRow';

interface IState {
    loading: boolean,
    themeIds: string[],
    showCreateNewModal: boolean,
}

interface IProps {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    themeStore?: ThemeStore,
}

@inject('currentUserStore', 'websiteStore', 'themeStore')
@observer
export default class ThemesListPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            themeIds: [],
            showCreateNewModal: false,
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        this.themesApiService.getThemes()
            .then(data => {
                this.setState({
                    loading: false,
                    themeIds: data.records.themes.items.map(s => s.id)
                })
            })
    }

    renderTable(themes: ITheme[]) {
        return (
            <>
                <div className={`card`}>
                    <div className="card-body">
                        <ThemesTable>
                            {themes.map(theme => {   
                                return (
                                    <ThemeTableRow
                                        key={theme.id}
                                        theme={theme}
                                    />
                                )
                            })}
                        </ThemesTable>
                    </div>
                </div>
            </>
        )
    }

    handleCreateNew() {
        this.setState({
            showCreateNewModal: true
        })
    }

    render() {
        const themes = orderBy(this.props.themeStore.getByIds(this.state.themeIds), ['name'], ['asc']);
        const loading = this.state.loading;

        return (
            <>
                <CustomDocumentTitle title="Šablony">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <div className="dashboard-header-title">
                                <h5 className="mb-0">Šablony</h5>
                                <p className="mb-0 font-15">Správa šablon.</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <button onClick={this.handleCreateNew.bind(this)} className="btn btn-primary ml-1">Nahrát šablonu</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    {loading && <LoadingScreen />}

                    {!loading && this.renderTable(themes)}
                </CustomDocumentTitle>
            </>
        )
    }
}