import React from 'react';
import { Modal } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from './../baseComponent';
import IStorageFile from '../../interfaces/IStorageFile';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';
import CheckBox from '../inputs/checkBox';
import { CronDescription } from '../others/cronDescription';
import InputUrl from '../inputs/inputUrl';

interface IState {
    form: {
        name: string,
        scheduled: boolean,
        importCronExpression: string,
        url: string,
        downloadFromUrl: boolean,
    },
    serverErrors: IFormError[],
    loading: boolean,
}

interface IProps {
    storageFile: IStorageFile,
    onHide(): void,
}

export default class StorageFileEditModal extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputUrl: React.RefObject<InputUrl>;
    private inputImportCronExpression = React.createRef<InputText>();

    constructor(props: IProps) {
        super(props);

        const storageFile = this.props.storageFile;

        this.state = {
            form: {
                name: storageFile.name,
                scheduled: storageFile.scheduled,
                importCronExpression: storageFile.importCronExpression,
                url: storageFile.url,
                downloadFromUrl: storageFile.downloadFromUrl
            },
            loading: false,
            serverErrors: [],
        }

        this.inputName = React.createRef();
        this.inputUrl = React.createRef();
        this.inputImportCronExpression = React.createRef();
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleSubmit() {
        const isValid = this.validateInputs([
            this.inputName,
            this.inputImportCronExpression,
        ])

        const storageFile = this.props.storageFile;
        const form = this.state.form;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.storageFilesApiService.editStorageFiles(storageFile.id, form)
                .then(data => {
                    if (data.success) {
                        this.props.onHide();
                    }
                    else {
                        this.setState({
                            loading: false,
                            serverErrors: data.formErrors,
                        })
                    }
                })
        }
    }

    render() {
        const { loading, form, serverErrors } = this.state;
        const { storageFile } = this.props;

        return (
            <Modal animation={false} size="lg" centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Úprava souboru "{storageFile.name}"</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {storageFile.id}

                    <InputText
                        uid="name"
                        ref={this.inputName}
                        label="Název"
                        onChange={this.handleChange.bind(this)}
                        value={form.name}
                        required
                        maxLength={30}
                    />

                    <InputText
                        uid="filename"
                        label="Filename"
                        onChange={this.handleChange.bind(this)}
                        value={storageFile.filename}
                        readonly
                    />

                    <h5 className="card-title mt-4">Stahování z URL adresy</h5>
                    <hr />

                    <CheckBox
                        uid="downloadFromUrl"
                        label="Stahovat z url adresy"
                        checked={form.downloadFromUrl}
                        onChange={this.handleChange.bind(this)}
                    />

                    {form.downloadFromUrl &&
                        <>
                        <InputUrl
                            uid="url"
                            ref={form.url}
                            label="URL adresa souboru"
                            value={form.url}
                            onChange={this.handleChange.bind(this)}
                            serverErrors={serverErrors}
                        />

                        <CheckBox
                            uid="scheduled"
                            label="Nastavit pravidelnou aktualizaci"
                            checked={form.scheduled}
                            onChange={this.handleChange.bind(this)}
                        />

                        {form.scheduled &&
                            <div className="mt-4">
                                <InputText
                                    uid="importCronExpression"
                                    ref={this.inputImportCronExpression}
                                    label="Jak často má probíhat import?"
                                    placeholder="* * * * *"
                                    value={form.importCronExpression}
                                    required
                                    serverErrors={serverErrors}
                                    onChange={this.handleChange.bind(this)}
                                />

                                <p>Import bude probíhat: <b><CronDescription cronExpression={form.importCronExpression} /> </b></p>
                            </div>
                        }
                        </>}

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Uložit změny
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}