import App from "../App";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class ThemesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getThemes(): Promise<IResponseData> {
        return this.getRequest(`themes`, {})
            .then(response => {
                return response.data;
            })
    }

    public getTheme(id: string): Promise<IResponseData> {
        return this.getRequest(`themes/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteTheme(id: string): Promise<IResponseData> {
        return this.deleteRequest(`themes/${id}`, {}, {})
            .then(response => {
                App.instance.themeStore.removeById(id);
                return response.data;
            })
    }

}