import { filter, find, findIndex, orderBy } from 'lodash';
import { observable, action, makeObservable } from 'mobx'
import IStorageFile from '../interfaces/IStorageFile';

export default class StorageFileStore {

     list: IStorageFile[] = [];

    addRecords(records: IStorageFile[]): any {
        if (records.length > 0) {
            records.forEach((record: IStorageFile) => this.addRecord(record));
        }
    }

    
    private addRecord(record: IStorageFile) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    public getById(id: string): IStorageFile {
        return find(this.list, { id: id });
    }

    public getByIds(ids: string[]): IStorageFile[] {
        return this.orderByName(filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        }))
    }

    public getByUserId(userId: string): IStorageFile[] {
        return this.orderByName(filter(this.list, { userId: userId }));
    }

    public removeById(id: string) {
        this.list = filter(this.list, item => {
            if (item.id != id) {
                return true;
            }
            return false;
        })
    }

    private orderByName(sources: IStorageFile[]): IStorageFile[] {
        return orderBy(sources, ['name'], ['asc']);
    }

    constructor() {
        makeObservable(this, {
            list: observable,
            addRecords: action,
            removeById: action
        })
    }
}