import React from 'react';
import BaseComponent from '../baseComponent';
import './../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface IState {
    editorState: EditorState
}

interface IProps {
    uid: string,
    value: string,
    onChange(value: any, key: string): void
}

export default class EditorConvertToHTML extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            editorState: EditorState.createEmpty()
        }
    }


    componentDidMount() {
        if (this.props.value) {
            const contentBlock = htmlToDraft(this.props.value);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            
                const editorState = EditorState.createWithContent(contentState);
                this.setState({
                    editorState: editorState
                })
            }
        }
    }


    onEditorStateChange(editorState: EditorState) {
        this.setState({
            editorState,
        });

        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        this.props.onChange(html, this.props.uid);
    }



    render() {
        let editorState = this.state.editorState;
      
        return (
            <div>
                <Editor
                    editorState={editorState}
                    wrapperClassName="wrapperClassName"
                    editorClassName="contentEditor"
                    onEditorStateChange={this.onEditorStateChange.bind(this)}
                />
            </div>
        );
    }
}

const Image = (props: any) => {
    return <img className="img-fluid" src={props.src} />;
};