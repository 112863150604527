import App from "../App";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class ImagesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public deleteImage(id: string): Promise<IResponseData> {
        return this.deleteRequest(`images/${id}`, {}, {})
            .then(response => {
                if (response.data.success) {
                    App.instance.imageStore.removeById(id)
                }
                return response.data;
            })
    }

    public setVariable(imageId: string, variableKey: string): Promise<IResponseData> {
        return this.postRequest(`images/${imageId}/variable`, { variableKey }, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteVariable(imageId: string): Promise<IResponseData> {
        return this.deleteRequest(`images/${imageId}/variable`, { }, {})
            .then(response => {
                return response.data;
            })
    }
}