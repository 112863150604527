import * as React from 'react';
import IInputField from './IInputField';
import InputText from './inputText';

export default class InputUrl extends InputText implements IInputField {
    handleChange(e: any) {
       // this.validate(e.target.value);
        this.props.onChange(e.target.value, this.props.uid);
    }

    validateInput(value: string, errors: string[]) {
        super.validateInput(value, errors);

        var pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

        if (!pattern.test(value)) {
            errors.push("Url není ve správném formátu")
        }
        if (value.includes("http://") === false && value.includes("https://") === false) {
            errors.push("Url adresa musí začínat protokolem http:// nebo https://");
        }        
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }
        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <input
                ref={this.input}
                type="url"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.handleChange.bind(this)}
                data-clarity-unmask="True"
                onKeyDown={this.handleKeyDown.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
            />
        );
    }

    render() {
        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }
}