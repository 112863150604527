import { filter, find, findIndex, orderBy } from 'lodash';
import { observable, action, makeObservable } from 'mobx'
import IWebsite from '../interfaces/IWebsite';

export default class WebsiteStore {

     list: IWebsite[] = [];

    
    addRecords(records: IWebsite[]): any {
        if (records.length > 0) {
            records.forEach((record: IWebsite) => this.addRecord(record));
        }
    }

    
    private addRecord(record: IWebsite) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    public getById(id: string) {
        return find(this.list, { id: id });
    }

    public getByIds(ids: string[]) {
        return this.orderWebsites(filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        }))
    }

    public getByUserId(userId: string) {
        return this.orderWebsites(filter(this.list, { userId: userId }));
    }

    public orderWebsites(websites: IWebsite[]): IWebsite[] {
        return orderBy(websites, ['awsPendingDistributionSetup', 'active'], ['desc', 'desc'])
    }

    constructor() {
        makeObservable(this, {
            list: observable,
            addRecords: action,
        })
    }
}