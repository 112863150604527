import { inject, observer } from 'mobx-react';
import React from 'react';
import IRouteComponentProps from '../../interfaces/IRouteComponentProps';
import CurrentUserStore from '../../stores/CurrentUserStore';
import WebsiteStore from '../../stores/WebsiteStore';
import BaseComponent from './../../components/baseComponent';
import { DefaultFooter } from './defaultFooter';
import { DefaultHeader } from './defaultHeader';

interface IState {

}

interface IProps extends IRouteComponentProps<IMatchParams>{
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
}

interface IMatchParams {
    websiteId?: string,
}

@inject('currentUserStore', 'websiteStore')
@observer
export default class DefaultLayout extends BaseComponent<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    render() {
        const currentUser = this.props.currentUserStore.user;

        if (!currentUser) {
            return null;
        }

        const websites = this.props.websiteStore.getByUserId(currentUser.id);
        const selectedWebsite = this.props.websiteStore.getById(this.props.match.params.websiteId || '0');

        return (
            <div>
                <div className="main-container-wrapper">
                    <DefaultHeader user={currentUser} selectedWebsite={selectedWebsite} websites={websites} />
                    {/* Mani Page */}
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="container-fluid">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    <DefaultFooter />
                </div>
            </div>
        );
    }
}