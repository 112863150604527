import React from 'react';
import BaseComponent from './../baseComponent';
import ISource from '../../interfaces/ISource';
import LoadingScreen from '../screens/loadingScreen';
import IWebsite from '../../interfaces/IWebsite';
import { JsonPathPicker } from 'react-json-to-html-picker';
import IPageDesign from '../../interfaces/IPageDesign';
import IFormError from '../forms/IFormError';
import { Accordion } from 'react-bootstrap';
import SourcePageDesignBasicCard from './pageDesign/sourcePageDesignBasicCard';
import { BtnSubmit } from '../inputs/btnSubmit';
import SourcePageDesignCategorizationCard from './pageDesign/sourcePageDesignCategorizationCard';
import SectionStore from '../../stores/SectionStore';
import CategoryStore from '../../stores/CategoryStore';
import { inject, observer } from 'mobx-react';
import SectionHelper from '../../helpers/sectionHelper';
import CategoryHelper from '../../helpers/categoryHelper';
import SourcePageDesignCustomFieldsCard from './pageDesign/sourcePageDesignCustomFieldsCard';
import SourcePageDesignImagesCard from './pageDesign/sourcePageDesignImagesCard';
import SourcePageDesignTranslationCard from './pageDesign/sourcePageDesignTranslationCard';

interface IState {
    loadingData: boolean,
    rootElementJson: string,
    pageDesign: IPageDesign,
    serverErrors: IFormError[],
    loadingSubmit: boolean,
}

interface IProps {
    source: ISource,
    website: IWebsite,
    goToNextStep(): void,
    sectionStore?: SectionStore,
    categoryStore?: CategoryStore,
}

@inject('sectionStore', 'categoryStore')
@observer
export default class SourceProductMapperStep extends BaseComponent<IProps, IState> {

    private basicForm: React.RefObject<SourcePageDesignBasicCard>;
    private categorizationForm: React.RefObject<SourcePageDesignCategorizationCard>;
    private customFieldsForm: React.RefObject<SourcePageDesignCustomFieldsCard>;
    private imagesForm: React.RefObject<SourcePageDesignImagesCard>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loadingData: true,
            rootElementJson: null,
            pageDesign: this.props.source.pageDesign,
            serverErrors: [],
            loadingSubmit: false,
        }

        this.basicForm = React.createRef();
        this.categorizationForm = React.createRef();
        this.customFieldsForm = React.createRef();
        this.imagesForm = React.createRef();
    }

    componentDidMount() {
        const source = this.props.source;

        this.sourcesApiService.getSourceElementJson(source.id, source.rootElementPath)
            .then(json => {
                this.setState({
                    loadingData: false,
                    rootElementJson: json
                })
            })
    }

    handleOnChoose(path: string) {
      //  console.log(path);

        path = path.replace('[', '{');
        path = path.replace(']', '}');
        //  path = path.replace(',', '[');
        path = path.replace('"', '');
        path = path.replace('"', '');


        let newPath = "";

        let count = 0;
        for (var i = 0; i < path.length; i++) {
            let char = path[i];

            if (char === ',') {

                if (count % 2 === 0) {
                    newPath += "[";
                }
                else {
                    newPath += "]";
                }
                count++;
            }
            else {
                newPath += path[i];
            }
        }

        if (newPath[newPath.length - 1] !== ']') {

        }

        navigator.clipboard.writeText(newPath);
    }

    handleChange(value: any, key: string) {
        this.setState({
            pageDesign: {
                ...this.state.pageDesign,
                [key]: value
            }
        })
    }

    handleChangeTranslation(value: any, key: string) {
        this.setState({
            pageDesign: {
                ...this.state.pageDesign,
                translation: {
                    ...this.state.pageDesign.translation,
                    [key]: value
                } 
            }
        })
    }

    handleSubmit() {
        const inputs: any = [

        ];

        const isValidBasic = this.basicForm.current.validateForm();

        const pageDesign = this.state.pageDesign;
        const source = this.props.source;

        if (isValidBasic) {
            this.setState({
                loadingSubmit: true
            })

            this.sourcesApiService.editPageDesign(source.id, pageDesign)
                .then(data => {
                    if (data.success) {
                        this.props.goToNextStep();
                    }
                    else {
                        this.setState({
                            loadingSubmit: false,
                            serverErrors: data.formErrors,
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    render() {
        const { source, website } = this.props;
        const { loadingData, serverErrors, loadingSubmit } = this.state;
        const rootElementJson = this.state.rootElementJson;
        const pageDesignServer = source.pageDesign;
        const json = JSON.stringify(rootElementJson, null, 2);
        const pageDesignEdit = this.state.pageDesign;

        const sections = this.props.sectionStore.getByWebsiteId(website.id);
        const categories = this.props.categoryStore.getByWebsiteId(website.id);

        //   const selectedCategories = this.props.categoryStore.getByIds(pageDesignEdit.categoryIds);

        if (loadingData) {
            return <LoadingScreen />;
        }

        return (
            <>
                <div className="mb-5 mt-4 text-center">
                    <h4>Vytvoření nové stránky</h4>
                    <p>Definujte, jak se má vytvářet obsah z vybraného elementu.</p>
                </div>

                <div className="row">
                    <div className="col-6 col-product-json-mapper">
                        <Accordion defaultActiveKey="basic">
                            <SourcePageDesignBasicCard
                                ref={this.basicForm}
                                onChange={this.handleChange.bind(this)}
                                pageDesignEdit={pageDesignEdit}
                                pageDesignServer={pageDesignServer}
                                serverErrors={serverErrors}
                            />

                            <SourcePageDesignCategorizationCard
                                ref={this.categorizationForm}
                                onChange={this.handleChange.bind(this)}
                                pageDesignEdit={pageDesignEdit}
                                pageDesignServer={pageDesignServer}
                                serverErrors={serverErrors}

                                sectionOptions={SectionHelper.getSectionOptions(sections)}
                                categoryOptions={CategoryHelper.getCategoryOptions(categories)}
                            />

                            <SourcePageDesignCustomFieldsCard
                                ref={this.customFieldsForm}
                                onChange={this.handleChange.bind(this)}
                                pageDesignEdit={pageDesignEdit}
                                pageDesignServer={pageDesignServer}
                                serverErrors={serverErrors}
                            />

                            <SourcePageDesignImagesCard
                                ref={this.imagesForm}
                                onChange={this.handleChange.bind(this)}
                                pageDesignEdit={pageDesignEdit}
                                pageDesignServer={pageDesignServer}
                                serverErrors={serverErrors}
                            />

                            <SourcePageDesignTranslationCard
                                onChange={this.handleChangeTranslation.bind(this)}
                                pageDesignEdit={pageDesignEdit}
                                pageDesignServer={pageDesignServer}
                                serverErrors={serverErrors}
                                source={source}
                            />
                        </Accordion>
                    </div>

                    <div className="col-6 col-product-json-mapper">
                        <JsonPathPicker
                            json={json}
                            onChoose={this.handleOnChoose.bind(this)}
                        />
                    </div>
                </div>

                <BtnSubmit
                    className="btn btn-primary float-right"
                    onClick={this.handleSubmit.bind(this)}
                    loading={loadingSubmit}
                >
                    Uložit a pokračovat
                </BtnSubmit>
            </>)
    }
}