
interface IProps {
    children?: any,
}

export const VariablesTable = (props: IProps) => {
    return (
        <div className="table-responsive">
            <table className="table table-centered">
                <thead className="thead-light">
                    <tr>
                        <th>Klíč</th>

                        <th>Hodnota</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {props.children}
                </tbody>
            </table>
        </div>)
}