import { filter, find, findIndex, orderBy } from 'lodash';
import { observable, action, makeObservable } from 'mobx'
import ISection from '../interfaces/ISection';

export default class SectionStore {

     list: ISection[] = [];
    
    addRecords(records: ISection[]): any {
        if (records.length > 0) {
            records.forEach((record: ISection) => this.addRecord(record));
        }
    }

    
    private addRecord(record: ISection) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    public getById(id: string): ISection {
        return find(this.list, { id: id });
    }

    public getByIds(ids: string[]): ISection[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }

    public getByUserId(userId: string): ISection[] {
        return this.orderByName(filter(this.list, { userId: userId }));
    }

    public getByWebsiteId(websiteId: string): ISection[] {
        return this.orderByName(filter(this.list, { websiteId: websiteId }));
    }

    private orderByName(sections: ISection[]): ISection[] {
        return orderBy(sections, ['name'], ['asc']);
    }

    public removeById(id: string) {
        this.list = filter(this.list, item => {
            if (item.id != id) {
                return true;
            }
            return false;
        })
    }

    constructor() {
        makeObservable(this, {
            list: observable,
            addRecords: action,
            removeById: action,
        })
    }
}