import { isNil } from 'lodash';
import * as React from 'react';
import IInputField from './IInputField';
import IOptionData from './IOptionData';

interface IProps {
    required?: boolean;
    label: string;
    value: any;
    onChange?(value: any, key: string): void;
    defaultValue?: string;
    options: IOptionData[];
    className?: string;
    formGroupClassName?: string
    disabled?: boolean,
    saved?: boolean,
    helpButton?: boolean,
    onHelpSubmit?: Function,
    classNameOuter?: string,
    inlineLabel?: boolean,
    uid: string,
}

interface IState {
    errors: string[],
}

export default class SelectBox extends React.Component<IProps, IState> implements IInputField {
    private input: React.RefObject<HTMLSelectElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current.focus();
    }

    getErrors(value = this.props.value) {
        let errors = [];
        /* if (this.props.validate) {
             return this.props.validate(value || '') || [];
             // errors.push(value)
         }*/
        if (this.props.required && (value === null || value === undefined || value === 0 || value === '')) {
            errors.push("Toto pole je povinné");
        }
        return errors;
    }

    isValid() {
        return this.getErrors().length === 0;
    }

    validate(value = this.props.value) {
        // console.log(value);
        this.setState({
            errors: this.getErrors(value),
        })
    }

    isNumeric(value: string) {
        return /^-{0,1}\d+$/.test(value);
    }

    handleChange(e: any) {
        let value = e.target.value;
        if (value === 'default') {
            value = 0;
        }

        this.validate(value);
        if (this.isNumeric(value)) {
            this.props.onChange((parseInt(value)), this.props.uid);
        }
        else {
            this.props.onChange(value, this.props.uid);
        }

    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <>
                <select
                    ref={this.input}
                    disabled={this.props.disabled || false}
                    onChange={this.handleChange.bind(this)}
                    value={this.props.value || 'default'}
                    className={this.props.className || `form-control`}
                    data-clarity-unmask="True"
                >
                    {this.props.defaultValue ? <option
                        value="default"
                        disabled={this.props.required}> {this.props.defaultValue}
                    </option> : null}

                    {(this.props.options || []).map((option: IOptionData, index: number) => {
                        return (<option key={index} value={option.value} disabled={option.disabled || false} dangerouslySetInnerHTML={{ __html: option.label }}></option>)
                    })
                    }
                </select>
                <div className="position-absolute selectArrow" style={{ right: this.props.helpButton ? '32px' : '0px' }}>
                    <svg width={24} height={24} fill="none" stroke="currentColor" strokeWidth={2} className="dropdown-heading-dropdown-arrow gray"><path d="M6 9L12 15 18 9" /></svg>
                </div>
            </>
        );
    }

    renderLabel() {
        if (this.props.label.length === 0) {
            return null;
        }

        return (
            <label className="form-control-label">
                <span dangerouslySetInnerHTML={{ __html: this.props.label }}></span> {this.props.required && this.props.label.length !== 0 ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        if (this.props.helpButton === true && (this.props.inlineLabel === true || isNil(this.props.inlineLabel))) {

            return (
                <div className={`input-group position-relative mb-3`}>
                    {this.renderLabel()}


                    {this.renderInput()}
                    {this.renderError()}

                    <div className="input-group-append">
                        <span className="input-group-text" style={{ padding: '7px' }}>
                            <i className="far fa-question-circle" aria-hidden="true"></i>
                        </span>
                    </div>

                </div>
            );
        }
        else if (this.props.helpButton === true && this.props.inlineLabel === false) {
            return (
                <div className="mb-3">
                    <div className={`form-group position-relative mb-0`}>
                        {this.renderLabel()}

                        <div className="input-group ">
                            {this.renderInput()}


                            <div className="input-group-append">
                                <span className="input-group-text" style={{ padding: '7px' }}>
                                    <i className="far fa-question-circle" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    { this.renderError()}
                </div>
            );
        }

        return (
            <div className="mb-3">
                {this.renderLabel()}
                <div className={`position-relative mb-0 form-group ${this.props.formGroupClassName || ''}`}>

                    {this.renderInput()}


                    {this.props.saved === true ? <small>
                        <span className="float-right text-right text-success mt-1">
                            <i className={`fas fa-check text-success tx-16 lh-0 op-6`}></i> uloženo</span>
                    </small> : ''}
                </div>
                {this.renderError()}
            </div>
        );
    }
}