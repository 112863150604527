import App from "../App";
import ICategory from "../interfaces/ICategory";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class CategoriesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public deleteCategory(id: string): Promise<IResponseData> {
        return this.deleteRequest(`categories/${id}`, {}, {})
            .then(response => {
                if (response.data.success) {
                    App.instance.menuItemStore.removeByCategoryId(id)
                    App.instance.categoryStore.removeById(id);
                }
                return response.data;
            })
    }

    public editCategory(id: string, category: ICategory): Promise<IResponseData> {
        return this.putRequest(`categories/${id}`, category , {})
            .then(response => {
                return response.data;
            })
    }
}