import * as React from 'react';
import IInputField from './IInputField';
import IOptionData from './IOptionData';
import Select from 'react-select'
import BaseComponent from './../baseComponent';

interface IProps {
    uid: string,
    required?: boolean;
    label: string;
    options: IOptionData[];
    selected: IOptionData[],
    onChange(selectedIds: string[], key: string): void,
}

interface IState {
    errors: string[],
}

export default class MultiSelectBox extends BaseComponent<IProps, IState> implements IInputField {
    private input: React.RefObject<HTMLSelectElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }
    validate(value?: any) {
        throw new Error('Method not implemented.');
    }
    isValid(): boolean {
        throw new Error('Method not implemented.');
    }

    focus() {
        this.input.current.focus();
    }

    handleChange(selected: any) {
        const ids = selected.map((s: IOptionData) => s.value);
        this.props.onChange(ids, this.props.uid);
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        const { options, selected } = this.props;

        return (
            <>
                <Select
                    options={options}
                    isMulti={true}
                    value={selected}
                    onChange={this.handleChange.bind(this)}
                //    noOptionsMessage={() => "��dn� mo�nosti"}
                    placeholder={'Vyberte'}
                />
            </>
        );
    }

    renderLabel() {
        if ((this.props.label || '').length === 0) {
            return null;
        }

        return (
            <label className="form-control-label">
                <span dangerouslySetInnerHTML={{ __html: this.props.label }}></span> {this.props.required && this.props.label.length !== 0 ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="mb-3">
                <div className={`form-group position-relative mb-0`}>
                    {this.renderLabel()}
                    {this.renderInput()}
                </div>
                { this.renderError()}
            </div>
        );
    }
}