import BaseComponent from '../baseComponent';
import Swal from "sweetalert2";
import { ClipLoader } from 'react-spinners';
import ISection from '../../interfaces/ISection';
import React from 'react';
import EditSectionModal from './editSectionModal';

interface IState {
    loading: boolean,
    showEditModal: boolean,
}

interface IProps {
    section: ISection
}

export default class SectionTableRow extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            showEditModal: false,
        }
    }

    handleDelete() {
        const section = this.props.section;

        Swal.fire({
            title: `Opravdu chcete smazat sekci "${section.name}"?`,
            html: `Sekci smažete včetně všech stránek (${section.pagesCount}) Akce je nevratná.`,
            icon: 'warning',
            confirmButtonText: 'Ano, smazat včetně obsahu',
            cancelButtonText: 'Zrušit',
            showCancelButton: true,
            focusCancel: true,
        }).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loading: true
                })

                this.sectionsApiService.deleteSection(section.id)
                    .then(data => {
                        this.setState({
                            loading: false
                        })
                    })
            }
        })
    }

    handleEdit() {
        this.setState({
            showEditModal: true
        })
    }

    renderActions() {
        const loading = this.state.loading;
        const { section } = this.props;

        if (loading) {
            return (
                <ClipLoader
                    size={30}
                    color={"#449DF7"}
                    loading={true}
                />)
        }
        else {
            if (section.pendingDelete) {
                return <p
                    className="text-center text-danger pt-2"
                    style={{ display: 'flex' }}
                >
                    Sekce bude smazána
                </p>
            }

            return (
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button
                        className="action-icon"
                        onClick={this.handleEdit.bind(this)}
                    >
                        <i className="zmdi zmdi-edit"></i>
                    </button>

                    <button
                        className="action-icon"
                        onClick={this.handleDelete.bind(this)}
                    >
                        <i className="zmdi zmdi-delete"></i>
                    </button>
                </div>
            )
        }
    }

    render() {
        const { section } = this.props;

        return (
            <tr>
                <td>
                    {section.name} {section.primary === true && '(primární)'}
                </td>

                <td>
                    {section.slug}
                </td>

                <td>
                    {section.pagesCount} stránek
                </td>

                <td>
                    {this.state.showEditModal &&
                        <EditSectionModal
                            onHide={() => this.setState({ showEditModal: false })}
                            section={section}
                        />}

                    {this.renderActions()}
                </td>
            </tr>)
    }
}