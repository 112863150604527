import * as React from 'react';
import IInputField from './IInputField';

interface IProps {
    uid: string,
    label: string;
    checked: boolean;
    disabled?: boolean;
    onChange?: Function,
    required?: boolean,
    requiredErrorMessage?: string
}

interface IState {
    errors: string[],
}

export default class CheckBox extends React.Component<IProps, IState> implements IInputField {
    private input: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);

        this.input = React.createRef();

        this.state = {
            errors: [],
        };
    }

    validateInput(value: boolean, errors: string[]) {
        if (this.props.required === false) {
            return;
        }

        if (this.props.required && this.props.checked === false) {
            errors.push(this.props.requiredErrorMessage || 'Toto pole je povinné');
        }
       
    }

    getErrors(value = this.props.checked) {
        let errors: string[] = [];

       /* if (this.props.validate) {
            this.props.validate(
                value || '',
                errors,
                () => this.validateInput(value, errors),
            );
            if (errors.length != 0) {
                return errors;
            }
        }*/

        this.validateInput(value, errors);
        return errors;
    }

    isValid() {
        return this.getErrors().length === 0;
    }

    validate(value = this.props.checked) {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    focus() {
        this.input.current.focus();
    }

    handleChange(e: any) {
        this.props.onChange(!this.props.checked, this.props.uid);
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    render() {
        return (
            <label className="ckbox position-relative">
                <input
                    ref={this.input}
                    type="checkbox"
                    disabled={this.props.disabled}
                    checked={this.props.checked}
                    onChange={this.handleChange.bind(this)}
                    data-clarity-unmask="True"
                />
                <span className="position-absolute" style={{ left: '22px', top: '-2px' }} dangerouslySetInnerHTML={{ __html: this.props.label }}></span>
                {this.renderError()}
            </label>
        );
    }
}