import BaseComponent from '../baseComponent';
import ITheme from '../../interfaces/ITheme';

interface IState {
    loading: boolean,
}

interface IProps {
    theme: ITheme,
}

export default class ThemeTableRow extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false
        }
    }

    render() {
        const { theme } = this.props;

        return (
            <tr>
                <td>
                    {theme.name}
                </td>

                <td>
                    {theme.description}
                </td>

                <td>

                </td>
            </tr>)
    }
}