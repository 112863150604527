import React from 'react';
import BaseComponent from './../baseComponent';
import ISource from '../../interfaces/ISource';
import IInputField from '../inputs/IInputField';
import IFormError from '../forms/IFormError';
import { BtnSubmit } from '../inputs/btnSubmit';
import { filter } from 'lodash';
import { Modal } from 'react-bootstrap';
import SelectBox from '../inputs/selectBox';
import StorageFileHelper from '../../helpers/storageFileHelper';
import StorageFileStore from '../../stores/StorageFileStore';
import { inject, observer } from 'mobx-react';

interface IState {
    loading: boolean,
    form: {
        storageFileId: string
    },
    serverErrors: IFormError[]
}

interface IProps {
    source: ISource,
    onHide(): void,
    goToNextStep(): void,
    storageFileStore?: StorageFileStore
}

@inject('storageFileStore')
@observer
export default class SourceSelectStorageFileModal extends BaseComponent<IProps, IState> {

    private inputFile = React.createRef<SelectBox>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            form: {
                storageFileId: ''
            },
            serverErrors: []
        }

        this.inputFile = React.createRef();
    }

    componentDidMount() {
        this.storageFilesApiService.getStorageFiles()
            .then(data => {
                this.setState({
                    loading: false
                })
            })
    }

    getInputs(): React.RefObject<IInputField>[] {
        var fields: React.RefObject<IInputField>[] = [
            this.inputFile
        ];
        return fields;
    }

    validate(): boolean {
        return this.validateInputs(this.getInputs());
    }

    handleSubmit() {
        const source = this.props.source;
        const isValid = this.validate();

        if (isValid) {
            this.setState({
                loading: true
            })
            this.sourcesApiService.importFromStorageFile(source.id, this.state.form.storageFileId)
                .then(data => {
                    this.props.goToNextStep();
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    render() {
        const source = this.props.source;
        const loading = this.state.loading;
        const form = this.state.form;
        const storageFiles = this.props.storageFileStore.getByUserId(source.userId);

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton onHide={this.props.onHide.bind(this)}>
                    <Modal.Title>
                        <h5>Vyberte zdrojový soubor</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectBox
                        ref={this.inputFile}
                        uid="storageFileId"
                        label="Zdrojový soubor"
                        defaultValue="Vyberte"
                        options={StorageFileHelper.getOptions(storageFiles)}
                        value={form.storageFileId}
                        onChange={(value, key) => this.setState({ form: { storageFileId: value } })}
                        required
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Pokračovat  <i className="fas fa-angle-double-right ml-1"></i>
                    </BtnSubmit>
                </Modal.Body>
            </Modal>)
    }
}