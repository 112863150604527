import axios, { AxiosResponse, Canceler, Method } from "axios";
import { buildUrl } from 'build-url-ts';
import IResponseData from "../interfaces/records/IResponseData";

export default class BaseApiService {

    private cancellations: Array<Function> = [];

    public cancelRequests() {
        this.cancellations.forEach(obj => {
            const cancel: any = this.cancellations.pop();
            cancel();
        })
    }

    public addToCancellationObject( cancellation: Canceler) {
        this.cancellations.push(cancellation)
    }

    public async makeRequest(url: string, method: Method, data?: object): Promise<AxiosResponse<IResponseData>> {
        return new Promise((resolve, reject) : any => {
           axios({
                method: method,
                url,
                data: data,
                cancelToken: new axios.CancelToken((c) => this.addToCancellationObject(c)),
            })
                .then(resolve).catch(reject).finally(() => {
                    // handleResponse(key, option, res, res.data);
                })
                .catch((err) => {
                    // error handling logic
                });
        });
    }

    public getRequest(path: string, queryParams: { [name: string]: string | string[] }): Promise<AxiosResponse<IResponseData>> {
        const url = buildUrl(path, {
            queryParams: queryParams,
            disableCSV: true,
        });       
        return this.makeRequest(url, 'get');  
    }

    public postRequest(path: string, data: object, queryParams: { [name: string]: string | string[] }): Promise<AxiosResponse<IResponseData>>{
        const url = buildUrl(path, {

        });
        return this.makeRequest(url, 'post', data).finally(() => {
            
        });
    }

    public deleteRequest(path: string, data: object, queryParams: { [name: string]: string | string[] }): Promise<AxiosResponse<IResponseData>> {
        const url = buildUrl(path, {

        });
        return this.makeRequest(url, 'delete', data).finally(() => {

        });
    }

    public putRequest(path: string, data: object, queryParams: { [name: string]: string | string[] }): Promise<AxiosResponse<IResponseData>> {
        const url = buildUrl(path, {

        });
        return this.makeRequest(url, 'put', data).finally(() => {

        });
    }

};