export enum Language {
    Czech = 1,
    Slovak = 2,
    English = 3,
    Polish = 4,
    German = 5,
    Italian = 6,
    French = 7,
    Spanish = 8,
    Portuguese = 9,
    Hungarian = 10,
    Croatian = 11,
}