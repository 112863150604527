import React from 'react';
import BaseComponent from './../../baseComponent';
import IPageDesign from '../../../interfaces/IPageDesign';
import IFormError from '../../forms/IFormError';
import { Card } from 'react-bootstrap';
import SelectBox from '../../inputs/selectBox';
import IOptionData from '../../inputs/IOptionData';
import Textarea from '../../inputs/textarea';
import InputText from '../../inputs/inputText';

interface IState {

}

interface IProps {
    pageDesignEdit: IPageDesign,
    pageDesignServer: IPageDesign,
    onChange(value: any, key: string): void,
    serverErrors: IFormError[],

    sectionOptions: IOptionData[],
    categoryOptions: IOptionData[],
}

export default class SourcePageDesignCategorizationCard extends BaseComponent<IProps, IState> {

    private inputSection: React.RefObject<SelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = {

        }
        this.inputSection = React.createRef();
    }

    validateForm() {
        const inputs: any = [
            this.inputSection,
        ];
        const isValid = this.validateInputs(inputs);
        return isValid;
    }

    render() {
        const { pageDesignEdit, onChange, sectionOptions } = this.props;

        return (
            <Card className="mb-0 pb-0">
                <Card.Body>
                    <SelectBox
                        uid="sectionId"
                        label="Sekce"
                        options={sectionOptions}
                        onChange={onChange.bind(this)}
                        defaultValue="Vyberte"
                        value={pageDesignEdit.sectionId}
                        required
                    />

                    <InputText
                        uid="sectionSlug"
                        label="Sekce slug"
                        onChange={onChange.bind(this)}
                        value={pageDesignEdit.sectionSlug}
                    />

                    <Textarea
                        uid="categoryNames"
                        label="Kategorie (na každý řádek jednu)"
                        value={(pageDesignEdit.categoryNames || []).map(categoryName => categoryName || '').join('\n').toString()}
                        onChange={(value: string, key: string) => onChange(value.split('\n'), key)}
                    />
                </Card.Body>
            </Card>)
    }
}