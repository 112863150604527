import * as React from 'react';
import IInputField from './IInputField';

interface IProps {
    uid: string,
    length?: number,
    maxLength?: number,
    minLength?: number,
    required?: boolean;
    icon?: string;
    placeholder?: string;
    label: string;
    value: number;
    validate?: Function;
    onChange?: Function;
    autoComplete?: string,
    allowOnlyDigits?: boolean,
    readonly?: boolean,
    minValue?: number,
    maxValue?:number
}

interface IState {
    errors: string[],
}

export default class InputNumber extends React.Component<IProps, IState> implements IInputField {

    protected input: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current.focus();
    }
    getErrors(value = this.props.value) {
        let errors: string[] = [];

        if (this.props.validate) {
            this.props.validate(
                value,
                errors,
                () => this.validateInput(value, errors),
            );
            if (errors.length != 0) {
                return errors;
            }
        }

        this.validateInput(value, errors);
        return errors;
    }

    validateInput(value: number, errors: string[]) {
        if (this.props.required === false && !value && value !== 0) {
            return;
        }

        if (this.props.required && !value && value !== 0) {
            errors.push("Toto pole je povinné");
        }
        if (this.props.minLength && (value.toString() || '').length < this.props.minLength) {
            errors.push("Zadejte alespoň " + this.props.minLength + " číslic");
        }
        if (this.props.length && (value.toString() || '').length !== this.props.length) {
            errors.push("Pole musí obsahovat " + this.props.length + " číslic");
        }
        if (this.props.minValue && value < this.props.minValue) {
            errors.push("Minimální hodnota je " + this.props.minValue);
        }
        if (this.props.maxValue && value > this.props.maxValue) {
            errors.push("Maximální hodnota je " + this.props.maxValue);
        }
    }


    isValid() {
        return this.getErrors().length == 0;
    }

    validate(value = this.props.value) {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    handleOnBlur() {
        this.validate();
    }

    handleChange(e: any) {
        this.props.onChange(parseInt(e.target.value), this.props.uid);
    }

    renderError() {
        if (this.state.errors.length == 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <input
                ref={this.input}
                type="text"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={this.props.placeholder}
                value={!this.props.value && this.props.value !== 0 ? '' : this.props.value}
                onChange={this.handleChange.bind(this)}
                data-clarity-unmask="True"
                onBlur={this.handleOnBlur.bind(this)}
            />
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        if (this.props.icon) {
            return this.renderWithIcon();
        }

        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }

    renderWithIcon() {
        return (
            <div>
                {this.renderLabel()}
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><i className={`icon ion-${this.props.icon} tx-16 lh-0 op-6`}></i></span>
                    </div>
                    {this.renderInput()}
                </div>
                {this.renderError()}
            </div>
        );
    }
}