import { inject, observer } from 'mobx-react';
import BaseComponent from './../../components/baseComponent';
import React from 'react';
import StorageFileStore from '../../stores/StorageFileStore';
import CurrentUserStore from '../../stores/CurrentUserStore';
import IStorageFile from '../../interfaces/IStorageFile';
import { CustomDocumentTitle } from '../../utils/customDocumentTitle';
import LoadingScreen from '../../components/screens/loadingScreen';
import { StorageFilesTable } from '../../components/storage/storageFilesTable';
import StorageFileTableRow from '../../components/storage/storageFileTableRow';

interface IState {
    loading: boolean,
    fileIds: string[],
    showCreateNewModal: boolean,
}

interface IProps {
    currentUserStore?: CurrentUserStore,
    storageFileStore?: StorageFileStore,
}

@inject('currentUserStore', 'storageFileStore')
@observer
export default class StorageFilesListPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            fileIds: [],
            showCreateNewModal: false,
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        this.storageFilesApiService.getStorageFiles()
            .then(data => {
                this.setState({
                    loading: false,
                    fileIds: data.records.storageFiles.items.map(s => s.id)
                })
            })
    }

    renderTable(files: IStorageFile[]) {
        return (
            <>
                <div className={`card`}>
                    <div className="card-body">
                        <StorageFilesTable>
                            {files.map(file => {
                                return (
                                    <StorageFileTableRow
                                        key={file.id}
                                        storageFile={file}
                                    />
                                )
                            })}
                        </StorageFilesTable>
                    </div>
                </div>
            </>
        )
    }

    handleCreateNew() {
        this.setState({
            showCreateNewModal: true
        })
    }

    handleCreated(file: IStorageFile) {
        let fileIds = this.state.fileIds;
        fileIds.push(file.id);

        this.setState({
            fileIds: fileIds
        })
    }

    render() {
        const files = this.props.storageFileStore.getByIds(this.state.fileIds);
        const loading = this.state.loading;

        return (
            <>
                <CustomDocumentTitle title="Zdrojové soubory">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <div className="dashboard-header-title">
                                <h5 className="mb-0">Zdrojové soubor</h5>
                                <p className="mb-0 font-15">Soubory, ze kterých se vytváří obsah.</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <button onClick={this.handleCreateNew.bind(this)} className="btn btn-primary ml-1">Přidat soubor</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading && <LoadingScreen />}

                    {!loading && this.renderTable(files)}
                </CustomDocumentTitle>
            </>
        )
    }
}