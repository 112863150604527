import React from 'react';
import { Modal } from 'react-bootstrap';
import IWebsite from '../../interfaces/IWebsite';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from './../baseComponent';
import { history } from './../../App';

interface IState {
    name: string,
    loading: boolean,
}

interface IProps {
    website: IWebsite,
    onHide(): void,
}

export default class CreateNewSourceModal extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            name: '',
            loading: false,
        }

        this.inputName = React.createRef();
    }

    handleSubmit() {
        const isValid = this.validateInputs([
            this.inputName,
        ])

        const website = this.props.website;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.websitesApiService.createSource(website.id, this.state.name)
                .then(data => {
                    const source = data.records.sources.items[0];

                    if (data.success) {
                        history.push(`/websites/${website.id}/sources/${source.id}/settings`);
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                    }            
                })
        }
    }

    render() {
        const loading = this.state.loading;
        const name = this.state.name;

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Přidat nový zdroj dat</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputText
                        uid="name"
                        ref={this.inputName}
                        label="Pojmenujte si zdroj dat"
                        onChange={(value) => this.setState({ name: value })}
                        value={name}
                        required
                        maxLength={30}
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Pokračovat  <i className="fas fa-angle-double-right ml-1"></i>
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}