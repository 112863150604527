import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';
import IMenuItem from '../../interfaces/IMenuItem';
import { MenuItemType } from '../../enums/menuItemType';
import InputUrl from '../inputs/inputUrl';
import SelectBox from '../inputs/selectBox';
import SectionStore from '../../stores/SectionStore';
import { inject, observer } from 'mobx-react';
import SectionHelper from '../../helpers/sectionHelper';
import CategoryStore from '../../stores/CategoryStore';
import CategoryHelper from '../../helpers/categoryHelper';

interface IState {
    loadingDelete: boolean,
    loadingEdit: boolean,
    item: IMenuItem,
    serverErrors: IFormError[],
}

interface IProps {
    item: IMenuItem,
    handler: React.ReactNode,
    sectionStore?: SectionStore,
    categoryStore?: CategoryStore,
}

@inject('sectionStore', 'categoryStore')
@observer
export default class MenuItem extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputUrl: React.RefObject<InputUrl>;
    private accordion: React.RefObject<any>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loadingDelete: false,
            loadingEdit: false,
            item: this.props.item,
            serverErrors: [],
        }

        this.inputName = React.createRef();
        this.inputUrl = React.createRef();
        this.accordion = React.createRef();
    }

    handleChange(value: any, key: string) {
        this.setState({
            item: {
                ...this.state.item,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleDelete() {
        const { item } = this.props;
        this.setState({
            loadingDelete: true
        })
        this.menusApiService.deleteMenuItem(item.menuId, item.id)
            .then(data => {
                if (data.success) {
                    this.toastSuccess('Smazáno');
                }
            })
    }

    handleEditSubmit() {
        const inputs = [
            this.inputName,
            this.inputUrl,
        ]

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            const itemEdit = this.state.item;

            this.setState({
                loadingEdit: true
            })

            this.menusApiService.editMenuItem(itemEdit.menuId, itemEdit.id, itemEdit)
                .then(data => {
                    if (data.success) {
                        this.setState({
                            loadingEdit: false
                        })
                        this.toastSuccess('Uloženo');
                        this.accordion.current.click();
                    }
                    else {
                        this.setState({
                            serverErrors: data.formErrors,
                            loadingEdit: false,
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    renderEditUserLink() {
        const itemEdit = this.state.item;

        return (
            <>
                <InputText
                    uid="name"
                    ref={this.inputName}
                    value={itemEdit.name}
                    label="Text odkazu"
                    onChange={this.handleChange.bind(this)}
                />

                <InputUrl
                    uid="url"
                    ref={this.inputUrl}
                    label="Odkaz"
                    onChange={this.handleChange.bind(this)}
                    value={itemEdit.url}
                    required
                />
            </>)
    }

    renderEditSectionLink() {
        const itemEdit = this.state.item;
        const section = this.props.sectionStore.getById(itemEdit.sectionId);

        return (
            <>
                <InputText
                    uid="name"
                    ref={this.inputName}
                    value={itemEdit.name}
                    label="Text odkazu"
                    onChange={this.handleChange.bind(this)}
                />

                <SelectBox
                    uid="sectionId"
                    label="Sekce"
                    onChange={this.handleChange.bind(this)}
                    value={itemEdit.sectionId}
                    options={SectionHelper.getSectionOptions([section])}
                    disabled
                />
            </>)
    }

    renderEditCategoryLink() {
        const itemEdit = this.state.item;
        const category = this.props.categoryStore.getById(itemEdit.categoryId);

        return (
            <>
                <InputText
                    uid="name"
                    ref={this.inputName}
                    value={itemEdit.name}
                    label="Text odkazu"
                    onChange={this.handleChange.bind(this)}
                />

                <SelectBox
                    uid="categoryId"
                    label="Kategorie"
                    onChange={this.handleChange.bind(this)}
                    value={itemEdit.categoryId}
                    options={CategoryHelper.getCategoryOptions([category])}
                    disabled
                />
            </>)
    }

    renderLinkTypeLabel() : string {
        const { item } = this.props;

        switch (item.type) {

            case MenuItemType.Category:
                return 'Kategorie'

            case MenuItemType.Section:
                return 'Sekce'

            case MenuItemType.UserLink:
                return 'Uživatelský odkaz'

            case MenuItemType.Page:
                return 'Stránka'

            case MenuItemType.Homepage:
                return 'Hlavní stránka'
        }
    }

    renderEditHomepageLink() {
        const itemEdit = this.state.item;

        return (
            <>
                <InputText
                    uid="name"
                    ref={this.inputName}
                    value={itemEdit.name}
                    label="Text odkazu"
                    onChange={this.handleChange.bind(this)}
                />
            </>)
    }

    render() {
        const { item, handler } = this.props;
        const { loadingEdit, loadingDelete } = this.state;

        return (
            <Card className="mb-0 pb-0">
                <Accordion.Toggle as={Card.Header} ref={this.accordion} className="clickable menuItemNestableItem user-select-none" eventKey={item.id}>
                    {handler} {item.name} <span className="float-right">{this.renderLinkTypeLabel()}<i className="fas fa-angle-down ml-2 mt-1"></i></span>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={item.id}>
                    <Card.Body>
                        {item.type === MenuItemType.UserLink && this.renderEditUserLink()}
                        {item.type === MenuItemType.Section && this.renderEditSectionLink()}
                        {item.type === MenuItemType.Category && this.renderEditCategoryLink()}
                        {item.type === MenuItemType.Homepage && this.renderEditHomepageLink()}

                        <div className="float-right">
                            <BtnSubmit
                                className="btn btn-outline-primary mb-3"
                                loading={loadingEdit}
                                onClick={this.handleEditSubmit.bind(this)}
                            >
                                Uložit změny
                            </BtnSubmit>

                            <BtnSubmit
                                className="btn btn-outline-danger mb-3"
                                onClick={this.handleDelete.bind(this)}
                                loading={loadingDelete}
                            >
                                Smazat
                            </BtnSubmit>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }
}