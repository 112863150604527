import React from 'react';
import { Modal } from 'react-bootstrap';
import IWebsite from '../../interfaces/IWebsite';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import CheckBox from '../inputs/checkBox';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';

interface IState {
    form: {
        name: string,
        slug: string,
        primary: boolean,
        layout: string,
    }
    loading: boolean,
    serverErrors: IFormError[]
}

interface IProps {
    website: IWebsite,
    onHide(): void,
}

export default class CreateNewSectionModal extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputSlug: React.RefObject<InputText>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            form: {
                name: '',
                slug: '',
                primary: false,
                layout: 'default',
            },
            loading: false,
            serverErrors: []
        }

        this.inputName = React.createRef();
        this.inputSlug = React.createRef();
    }

    handleSubmit() {
        const inputs = [
            this.inputName,
            this.inputSlug
        ];

        const isValid = this.validateInputs(inputs);
        const website = this.props.website;
        const form = this.state.form;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.websitesApiService.createSection(website.id, form.name, form.slug, form.primary, form.layout)
                .then(data => {
                    if (data.success) {
                        this.props.onHide();
                    }
                    else {
                        this.setState({
                            loading: false,
                            serverErrors: data.formErrors
                        })
                        this.validateInputs(inputs);
                    }      
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    render() {
        const { loading, form, serverErrors } = this.state;

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Vytvořit novou sekci</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputText
                        uid="name"
                        ref={this.inputName}
                        label="Název"
                        onChange={this.handleChange.bind(this)}
                        value={form.name}
                        required
                        maxLength={30}
                        serverErrors={serverErrors}
                    />

                    <InputText
                        uid="slug"
                        ref={this.inputSlug}
                        label="Cesta url"
                        onChange={this.handleChange.bind(this)}
                        value={form.slug}
                        required
                        maxLength={30}
                        serverErrors={serverErrors}
                    />

                    <InputText
                        uid="layout"
                        value={form.layout}
                        label="Layout"
                        onChange={this.handleChange.bind(this)}
                    />

                    <CheckBox
                        uid="primary"
                        label="Primární"
                        checked={form.primary}
                        onChange={this.handleChange.bind(this)}
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Vytvořit
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}