import React from "react"

export const DefaultFooter = () => {
    return <div className="footer-area footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    {/* Footer Area */}
                    <div className="d-sm-flex align-items-center justify-content-between">
                        {/* Copywrite Text */}
                        <div className="copywrite-text">
                            <p>Clonty © {new Date().getFullYear()}</p>
                        </div>
                        {/* Footer Nav */}
                        <ul className="footer-nav d-flex align-items-center justify-content-center">
                            <li><a href="#">Kontakt</a></li>
                            <li><a href="#">Obchodní podmínky</a></li>
                            <li><a href="#">Ochrana osobních údajů</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}