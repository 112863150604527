import IOptionData from "../components/inputs/IOptionData";
import { Language } from "../enums/language";

export default class LanguageHelper {

    public static getLanguageOptions(): IOptionData[] {
        const options: IOptionData[] = [];
        options.push({ label: this.getLanguageLabel(Language.Czech), value: Language.Czech });
        options.push({ label: this.getLanguageLabel(Language.Slovak), value: Language.Slovak });
        options.push({ label: this.getLanguageLabel(Language.English), value: Language.English });
        options.push({ label: this.getLanguageLabel(Language.Polish), value: Language.Polish });
        options.push({ label: this.getLanguageLabel(Language.Croatian), value: Language.Croatian });
        options.push({ label: this.getLanguageLabel(Language.French), value: Language.French });
        options.push({ label: this.getLanguageLabel(Language.German), value: Language.German });
        options.push({ label: this.getLanguageLabel(Language.Hungarian), value: Language.Hungarian });
        options.push({ label: this.getLanguageLabel(Language.Italian), value: Language.Italian });
        options.push({ label: this.getLanguageLabel(Language.Portuguese), value: Language.Portuguese });
        options.push({ label: this.getLanguageLabel(Language.Spanish), value: Language.Spanish });
        return options;
    }

    public static getLanguageLabel(language: Language) {
        switch (language) {

            case Language.Czech:
                return 'Čeština';

            case Language.Slovak:
                return 'Slovenština';

            case Language.English:
                return 'Angličtina';

            case Language.Polish:
                return 'Polština';

            case Language.Croatian:
                return 'Chorvatština';

            case Language.French:
                return 'Francouzština';

            case Language.German:
                return "Němčina";

            case Language.Hungarian:
                return "Maďarština";

            case Language.Italian:
                return "Italština";

            case Language.Portuguese:
                return "Portugalština";

            case Language.Spanish:
                return "Španělština";
        }
    }
}