import React from 'react';
import BaseComponent from './../baseComponent';
import ISource from '../../interfaces/ISource';
import LoadingScreen from '../screens/loadingScreen';
import IWebsite from '../../interfaces/IWebsite';
import Dropzone from 'react-dropzone';
import IFormError from '../forms/IFormError';
import SourceDownloadFromUrlModal from './sourceDownloadFromUrlModal';
import SourceSelectStorageFileModal from './sourceSelectStorageFileModal';
import IStorageFile from '../../interfaces/IStorageFile';
import { isEmpty, isNil } from 'lodash';

interface IState {
    uploadLoading: boolean,
    downloadFromUrl: boolean,
    selectFromStorage: boolean,
    serverErrors: IFormError[],
    urlSubmitted: boolean,
}

interface IProps {
    source: ISource,
    website: IWebsite,
    goToNextStep(): void,
    storageFile: IStorageFile
}

export default class SourceTypeStep extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            uploadLoading: false,
            downloadFromUrl: false,
            selectFromStorage: false,
            serverErrors: [],
            urlSubmitted: false,
        }
    }

    componentDidUpdate() {
        const { source, storageFile } = this.props;
        if ((this.state.downloadFromUrl) && !isNil(storageFile) && !isEmpty(source.storageFileId) && this.state.urlSubmitted) {
            if (storageFile.downloading === false) {
                this.props.goToNextStep();
            }
        }
    }

    handleDownloadFromUrl() {
        this.setState({
            downloadFromUrl: true
        })
    }

    handleSelectFromStorageFiles() {
        this.setState({
            selectFromStorage: true
        })
    }

    handleSelectFileToUpload(e: React.ChangeEvent<HTMLInputElement>) {
        this.handleUploadFile(e.target.files[0]);
    }

    handleUploadFile(file: File) {
        this.setState({
            uploadLoading: true
        })
        const source = this.props.source;

        this.sourcesApiService.uploadSourceFile(source.id, file)
            .then(data => {
                this.props.goToNextStep();
            })
    }

    render() {
        const { source, storageFile } = this.props;
        const { uploadLoading, downloadFromUrl, selectFromStorage } = this.state;

        if (uploadLoading) {
            return <>
                <div className="mt-4 text-center">
                    <h4 className="text-primary">Probíhá nahrávání/zpracovávání</h4>
                    <p>Nezavírejte tuto kartu.</p>
                </div>
                <LoadingScreen />
            </>
        }

        if (storageFile ? storageFile.downloading : false) {
            return <>
                <div className="mt-4 text-center">
                    <h4 className="text-primary">Probíhá zpracovávání</h4>
                    <p>Stránku můžete zavřít a vrátit se později. Jakmile bude připraveno, stránka se automaticky obnoví.</p>
                </div>
                <LoadingScreen />
            </>
        }
        else {
            return (
                <div className="text-center">
                    <Dropzone onDrop={(files) => this.handleUploadFile(files[0])} noClick={true}>
                        {({ getRootProps, getInputProps, }) => (
                            <div className="my-5">
                                <div style={{ height: '100%', width: '100%', zIndex: 100 }} {...getRootProps()}>
                                    <input {...getInputProps()} accept="application/json,application/xml,text/xml,text/csv" />

                                    <h4>Vyberte zdroj dat</h4>
                                    <p>Podporujeme JSON, XML a CSV soubory až do velikosti 100 MB.</p>

                                    <div className="btn-group mt-4" role="group" aria-label="Basic example">
                                        <button
                                            type="button"
                                            className="btn btn-outline-info mr-2"
                                            style={{ width: '180px', height: '60px' }}
                                            onClick={this.handleSelectFromStorageFiles.bind(this)}
                                        >
                                            <i className="zmdi zmdi-folder-outline mr-2"></i>
                                            Vybrat z úložiště
                                        </button>

                                        <label htmlFor="upload" className="btn btn-outline-info clickable mr-2" style={{ width: '180px', height: '60px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                            <div><i className="fas fa-paperclip mr-1"></i> Nahrát soubor</div>
                                            <input type="file" id="upload" className="d-none" multiple={false} onChange={this.handleSelectFileToUpload.bind(this)} accept="application/json,application/xml,text/xml,text/csv" />
                                        </label>

                                        <button
                                            type="button"
                                            className="btn btn-outline-info"
                                            style={{ width: '180px', height: '60px' }}
                                            onClick={this.handleDownloadFromUrl.bind(this)}
                                        >
                                            <i className="fas fa-link mr-2"></i>
                                            Stáhnout z URL
                                        </button>

                                        {downloadFromUrl &&
                                            <SourceDownloadFromUrlModal
                                                onHide={() => this.setState({ downloadFromUrl: false })}
                                                source={source}
                                                urlSubmitted={() => this.setState({ urlSubmitted: true })}
                                            />
                                        }

                                        {selectFromStorage &&
                                            <SourceSelectStorageFileModal
                                                onHide={() => this.setState({ selectFromStorage: false })}
                                                source={source}
                                                goToNextStep={this.props.goToNextStep.bind(this)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>)
        }
    }
}