import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IFormError from '../forms/IFormError';
import { filter, find } from 'lodash';
import IMenuItem from '../../interfaces/IMenuItem';
import IMenu from '../../interfaces/IMenu';
import { MenuItemType } from '../../enums/menuItemType';
import SmartSelectBox from '../inputs/smartSelectBox';
import IOptionData from '../inputs/IOptionData';

interface IState {
    loading: boolean,
    item: IMenuItem,
    serverErrors: IFormError[]
}

interface IProps {
    menu: IMenu,
    categoryOptions: IOptionData[]
}

export default class CreateMenuItemCategoryCard extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputCategory: React.RefObject<SmartSelectBox>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            item: {
                type: MenuItemType.Category,
                menuId: this.props.menu.id,
                name: '',
                categoryId: '',
                id: '',
                index: 0,
                pageId: '',
                parentMenuItemId: '',
                sectionId: '',
                url: ''
            },
            serverErrors: []
        }

        this.inputCategory = React.createRef();
        this.inputName = React.createRef();
    }

    handleChange(value: any, key: string) {
        let item = this.state.item;
        // @ts-ignore
        item[key] = value;

        if (key === 'categoryId') {
            const category = find(this.props.categoryOptions, { value: value });
            if(category) item.name = category.label;
        }

        this.setState({
            item:item,
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleSubmit() {
        const inputs = [
            this.inputCategory,
            this.inputName,   
        ]

        const isValid = this.validateInputs(inputs);

        if (isValid) {
            const { item } = this.state;
            const { menu } = this.props;

            this.setState({
                loading: true
            })

            this.menusApiService.createMenuItem(menu.id, item)
                .then(data => {
                    if (data.success) {
                        this.setState({
                            loading: false,
                            item: {
                                ...this.state.item,
                                name: '',
                                categoryId: null
                            }
                        })
                    }
                    else {
                        this.setState({
                            serverErrors: data.formErrors,
                            loading: false,
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    render() {
        const { loading, item } = this.state;
        const { categoryOptions } = this.props;

        return (
            <Card className="mb-0 pb-0">
                <Accordion.Toggle as={Card.Header} className="clickable text-purple" eventKey="category">
                   Kategorie <i className="fas fa-angle-down float-right mt-1"></i>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="category">
                    <Card.Body>          
                        <SmartSelectBox
                            uid="categoryId"
                            ref={this.inputCategory}
                            label="Kategorie"
                            onChange={this.handleChange.bind(this)}
                            options={categoryOptions}
                            value={item.categoryId}
                            required
                        />

                        <InputText
                            uid="name"
                            ref={this.inputName}
                            label="Text odkazu"
                            value={item.name}
                            onChange={this.handleChange.bind(this)}
                            required
                        />

                        <BtnSubmit
                            className="btn btn-outline-primary float-right mb-3"
                            loading={loading}
                            onClick={this.handleSubmit.bind(this)}
                        >
                            Přidat do menu
                        </BtnSubmit>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }
}