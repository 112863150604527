import App from "../App";
import IPageDesign from "../interfaces/IPageDesign";
import ISourceElement from "../interfaces/ISourceElement";
import ITranslationCosts from "../interfaces/ITranslationCosts";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class SourcesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getSource(sourceId: string): Promise<IResponseData> {
        return this.getRequest(`sources/${sourceId}`, {})
            .then(response => {
                return response.data;
            })
    }

    public uploadSourceFile(sourceId: string, file: File): Promise<IResponseData> {
        const formData = new FormData()
        formData.append('file', file, file.name)

        return this.postRequest(`sources/${sourceId}/file`, formData, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteSource(id: string): Promise<IResponseData> {
        return this.deleteRequest(`sources/${id}`, {}, {})
            .then(response => {
                if (response.data.success === true) {
                    App.instance.sourceStore.removeById(id);
                }

                return response.data;
            })
    }

    public getSourceElements(sourceId: string): Promise<ISourceElement[]> {
        return this.getRequest(`sources/${sourceId}/elements`, {})
            .then(data => {
                return data.data as unknown as ISourceElement[];
            })
    }

    public getSourceElementJson(sourceId: string, path: string): Promise<string> {
        return this.getRequest(`sources/${sourceId}/object`, { path: path })
            .then(data => {
                return data.data as unknown as string;
            })
    }

    public setSourceRootElement(sourceId: string, rootElementPath: string): Promise<IResponseData> {
        return this.postRequest(`sources/${sourceId}/rootElement`, { path: rootElementPath }, {})
            .then(response => {
                return response.data;
            })
    }

    public downloadFileFromUrl(sourceId: string, url: string): Promise<IResponseData> {
        return this.postRequest(`sources/${sourceId}/url`, { url: url }, {})
            .then(response => {
                return response.data;
            })
    }

    public editPageDesign(sourceId: string, pageDesign: IPageDesign) {
        return this.postRequest(`sources/${sourceId}/design`, { ...pageDesign }, {})
            .then(response => {
                return response.data;
            })
    }

    public editImportSettings(sourceId: string,
        allowCreateNewPages: boolean,
        allowDeletePages: boolean,
        allowUpdatePages: boolean,
    ) {
        return this.postRequest(`sources/${sourceId}/import`, { allowCreateNewPages, allowDeletePages, allowUpdatePages }, {})
            .then(response => {
                return response.data;
            })
    }

    public runImport(sourceId: string, redownload: boolean) {
        return this.postRequest(`sources/${sourceId}/import/run`, { redownload } , {})
            .then(response => {
                return response.data;
            })
    }

    public cancelImport(sourceId: string) {
        return this.deleteRequest(`sources/${sourceId}/import/run`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public calculateTranslationCosts(sourceId: string, paths: string[]): Promise<ITranslationCosts> {
        return this.getRequest(`sources/${sourceId}/translation/costs`, { paths })
            .then(response => {
                var translationCosts = response.data.records.sources.others as unknown as ITranslationCosts;
                return translationCosts;
            })
    }

    public importFromStorageFile(sourceId: string, storageFileId: string): Promise<IResponseData> {
        return this.postRequest(`sources/${sourceId}/storageFileId`, { storageFileId: storageFileId }, {})
            .then(response => {
                return response.data;
            })
    }
}