import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import IInputField from './IInputField';
import InputText from './inputText';

export default class InputEmail extends InputText implements IInputField {

    handleChange(e: any) {
        //   this.validate(e.target.value);
        this.props.onChange(e.target.value, this.props.uid);
    }

    handleOnBlur() {
        this.validate();
    }

    validateInput(value: string, errors: string[]) {
        
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(value.replace('+', ''))) {
            errors.push("Email není ve správném formátu")
        } 
        super.validateInput(value, errors);
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }
        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInputWithTooltip() {
       return ( <OverlayTrigger
            placement={'top'}
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {this.props.tooltip}
                    </Tooltip>
            }
        >
            {this.renderInput()}
        </OverlayTrigger>)
    }

    renderInput() {
        return (    
            <input
                ref={this.input}
                type="email"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={this.props.placeholder}
                value={this.props.value || ''}
                onChange={this.handleChange.bind(this)}
                readOnly={this.props.readonly}
                disabled={this.props.readonly}
                data-clarity-unmask="True"
                onBlur={this.handleOnBlur.bind(this)}
                onKeyDown={this.handleKeyDown.bind(this)}
                />
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        if (this.props.icon) {
            return this.renderWithIcon();
        }

        return (
            <div className="form-group">
                {this.renderLabel()}
                {(this.props.tooltip || '').length > 0 ? this.renderInputWithTooltip() : this.renderInput()}
                {this.renderError()}
            </div>
        );
    }

    renderWithIcon() {
        return (
            <div>
                {this.renderLabel()}
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className={`icon ion-${this.props.icon} tx-16 lh-0 op-6`}></i></span>
                    </div>
                    {this.renderInput()}
                </div>
                {this.renderError()}
            </div>
        );
    }
}