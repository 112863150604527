import { round } from "lodash";
import ITranslationPathCost from "../../interfaces/ITranslationPathCost"
import { SeparateThousends } from "../others/separateThousends";

interface IProps {
    translationPathCost: ITranslationPathCost
}

export const TranslationPathCostsRow = (props: IProps) => {
    const translationPathCost = props.translationPathCost;

    return (
        <tr>
            <td>{`{${translationPathCost.path}}`}</td>

            <td>
                <SeparateThousends number={translationPathCost.charactersCount} />
            </td>

            <td>
                <SeparateThousends number={round(translationPathCost.estimatedCost, 1)} /> USD (<SeparateThousends number={round(translationPathCost.estimatedCost * 21.6, 0)} /> CZK)
            </td>
        </tr>)
}