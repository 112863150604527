import { flatten } from "lodash";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import Dropzone from "react-dropzone";
import IPage from "../../interfaces/IPage";
import PagesApiService from "../../services/pagesApiService";
import LoadingScreen from "../screens/loadingScreen";

interface IProps {
    page: IPage,
    onHide(): void,
    onUploaded(imageIds: string[]): void,
}

const pagesApiService = new PagesApiService();

export const UploadPageImageModal = (props: IProps) => {

    const { onHide, page, onUploaded } = props;
    const [uploading, setUploading] = useState(false);

    useEffect(() => {

        return pagesApiService.cancelRequests();
    }, [])

    async function handleUploadFile(files: File[]) {

 //       let ids: string[] = [];

        await Promise.all(files.map(file => {
            setUploading(true);

            return pagesApiService.uploadImage(page.id, file)
                .then(data => {
                    const images = data.records.images.items;
                    return images;
                });
        })
        ).then((imagesImages) => {
            const images = flatten(imagesImages);

            setUploading(false);
            onUploaded(images.map(image => image.id));
            onHide();
        })
    }

    return (
        <Modal animation={false} size="xl" centered={true} show onHide={onHide}>
            <Modal.Header closeButton onHide={onHide.bind(this)}>
                <Modal.Title>
                    <h5>Nahrát obrázky</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {uploading ? <LoadingScreen withCard={false} />
                    :
                    <Dropzone onDrop={(files) => handleUploadFile(files)}>
                        {({ getRootProps, getInputProps, }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} accept="image/x-png,image/jpeg" />
                                    <div className="dropzone-area my-4">
                                        <h3>Vybrat obrázky</h3>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>}
            </Modal.Body>
        </Modal>)
}