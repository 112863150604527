import * as React from 'react';
import IInputField from './IInputField';
import InputText from './inputText';

export default class InputPassword extends InputText implements IInputField {
    handleChange(e: any) {
        this.props.onChange(e.target.value, this.props.uid);
    }

    handleOnBlur() {
        this.validate();
     /*   if (this.isValid() && this.props.onBlur) {
            this.props.onBlur();
        }*/
    }

    validateInput(value: string, errors: string[]) {
        super.validateInput(value, errors);
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }
        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <input
                ref={this.input}
                type="password"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.handleChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
                onKeyDown={this.handleKeyDown.bind(this)}
            />
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }
}