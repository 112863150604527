import { inject, observer } from 'mobx-react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import BaseComponent from '../../../components/baseComponent';
import { orderBy } from 'lodash';
import { CustomPagination } from '../../../components/others/customPagination';
import InputText from '../../../components/inputs/inputText';
import CategoryStore from '../../../stores/CategoryStore';
import ICategory from '../../../interfaces/ICategory';
import CreateNewCategoryModal from '../../../components/categories/createNewCategoryModal';
import { NoCategoriesScreen } from '../../../components/categories/noCategoriesScreen';
import CategoryTableRow from '../../../components/categories/categoryTableRow';
import { CategoriesTable } from '../../../components/categories/categoriesTable';
import { PageTitle } from '../../../components/others/pageTitle';
import React from 'react';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';

interface IState {
    loading: boolean,
    categoryIds: string[],
    showCreateNewModal: boolean,
    pageNumber: number,
    totalCount: number,
    searchForm: {
        search: string,
    }
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    categoryStore?: CategoryStore,
}

interface IMatchParams {
    websiteId: string,
}

const itemsPerPage = 30;

@inject('currentUserStore', 'websiteStore', 'categoryStore')
@observer
export default class CategoriesListPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            categoryIds: [],
            showCreateNewModal: false,
            pageNumber: 1,
            totalCount: 0,
            searchForm: {
                search: '',
            }
        }
    }

    componentDidMount() {
        this.getCategories(this.state.pageNumber);
    }

    getCategories(pageNumber: number) {
        const websiteId = this.props.match.params.websiteId;
        const searchForm = this.state.searchForm;

        this.setState({
            loading: true
        })

        this.websitesApiService.getCategories(websiteId, (pageNumber - 1) * itemsPerPage, itemsPerPage, searchForm.search)
            .then(data => {
                this.setState({
                    loading: false,
                    categoryIds: data.records.categories.items.map(s => s.id),
                    pageNumber: pageNumber,
                    totalCount: data.records.pages.others.totalCount
                })
            })
    }

    handleChangePage(pageNumber: number) {
        this.getCategories(pageNumber);
    }

    handleFilterOnChange(value: any, key: string) {
        this.setState({
            searchForm: {
                ...this.state.searchForm,
                [key]: value
            }
        })
    }

    renderFiltering() {
        const form = this.state.searchForm;

        return (
            <div className="row">
                <div className="col-lg-6">
                    <div className={`card mb-1`}>
                        <div className="card-body pb-0 pt-3">
                            <div className="row">
                                <div className="col">
                                    <InputText
                                        uid="search"
                                        label=""
                                        value={form.search}
                                        placeholder="Klíčové slovo"
                                        onChange={this.handleFilterOnChange.bind(this)}
                                    />
                                </div>
                                <div className="col">
                                    <button
                                        disabled={this.state.loading}
                                        className="btn btn-light btn-block"
                                        onClick={() => this.getCategories(1)}
                                    >
                                        <i className="fas fa-search mr-1"></i> Hledat
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    renderTable(categories: ICategory[]) {
        return (
            <>
                {this.renderFiltering()}
                <div className={`card`}>
                    <div className="card-body">
                        <CategoriesTable>
                            {categories.map(page => {
                                return (
                                    <CategoryTableRow
                                        key={page.id}
                                        category={page}
                                    />
                                )
                            })}
                        </CategoriesTable>

                        <div className="text-center">
                            {categories.length === 0 && <h6 className="py-3">Nic nenalezeno</h6>}
                        </div>

                        <CustomPagination
                            currentPageNumber={this.state.pageNumber}
                            itemsPerPage={itemsPerPage}
                            loading={this.state.loading}
                            onChangePage={this.handleChangePage.bind(this)}
                            totalItemsCount={this.state.totalCount}
                        />
                    </div>
                </div>
            </>
        )
    }

    handleCreateNew() {
        this.setState({
            showCreateNewModal: true
        })
    }

    render() {
        const categories = orderBy(this.props.categoryStore.getByIds(this.state.categoryIds), ['name'], ['asc']);
        const loading = this.state.loading;
        const websiteId = this.props.match.params.websiteId;
        const website = this.props.websiteStore.getById(websiteId);

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Kategorie">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <PageTitle
                                title="Kategorie"
                                subtitle="Každá stránka může mít několik kategorií."
                            />
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <button onClick={this.handleCreateNew.bind(this)} className="btn btn-primary ml-1">Vytvořit kategorii</button>
                                    {/* <a href="#" className="btn btn-primary ml-1"><i className="ti-bookmark-alt font-11" /> Export</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showCreateNewModal &&
                        <CreateNewCategoryModal
                            website={website}
                            onHide={() => this.setState({ showCreateNewModal: false })}
                            onCreated={() => this.getCategories(1)}
                        />}

                    {loading && <LoadingScreen />}

                    {!loading && website.categoriesCount > 0 && this.renderTable(categories)}

                    {!loading && website.categoriesCount === 0 &&
                        <NoCategoriesScreen
                            onCreateNew={this.handleCreateNew.bind(this)}
                        />}
                </WebsiteDocumentTitle>
            </>
        )
    }
}