import React from "react";
import Pagination from 'react-js-pagination';
import BeatLoader from 'react-spinners/BeatLoader';

interface IProps {
    loading: boolean,
    currentPageNumber: number,
    itemsPerPage:number, 
    totalItemsCount: number,
    onChangePage(pageNumber: number): void,
}

export const CustomPagination = (props: IProps) => {

    const { loading, currentPageNumber, itemsPerPage, totalItemsCount, onChangePage } = props;
    const totalPages = Math.ceil(totalItemsCount / itemsPerPage);

    if (loading) {
        return (
            <div className="text-center mt-3">
                <BeatLoader
                    size={20}
                    margin={15}
                    color={"#449DF7"}
                    loading={true}
                />
            </div>)
    }

    if (totalPages > 1) {
        return (
            <div className="text-center">
                <Pagination
                    pageRangeDisplayed={10}
                    activePage={currentPageNumber}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalItemsCount}
                    onChange={onChangePage.bind(this)}
                    linkClass="page-link"
                    itemClass="page-item"
                    innerClass="pagination justify-content-center mt-3"
                    hideDisabled={true}
                    hideFirstLastPages={true}
                />
            </div>)
    }
    return null;
}