import { find, isEmpty, isNil } from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import IFormError from '../../../components/forms/IFormError';
import IInputField from '../../../components/inputs/IInputField';
import Wizard from '../../../components/others/wizard';
import LoadingScreen from '../../../components/screens/loadingScreen';
import SourceImportStep from '../../../components/sources/sourceImportStep';
import SourceProductMapperStep from '../../../components/sources/sourceProductMapperStep';
import SourceSelectElementStep from '../../../components/sources/sourceSelectElementStep';
import SourceTypeStep from '../../../components/sources/SourceTypeStep';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import { IWizardStep } from '../../../interfaces/wizard/IWizardStep';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import SourceStore from '../../../stores/SourceStore';
import StorageFileStore from '../../../stores/StorageFileStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';
import BaseComponent from './../../../components/baseComponent';

interface IState {
    loading: boolean,
    serverErrors: IFormError[],
    currentStep: IWizardStep,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    sourceStore?: SourceStore,
    storageFileStore?: StorageFileStore,
}

interface IMatchParams {
    websiteId: string,
    sourceId: string,
}

@inject('currentUserStore', 'websiteStore', 'sourceStore', 'storageFileStore')
@observer
export default class SourceSettingsPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            serverErrors: [],
            currentStep: null,
        }
    }

    componentDidMount() {
        const sourceId = this.props.match.params.sourceId;
        this.sourcesApiService.getSource(sourceId)
            .then(data => {
                const source = this.props.sourceStore.getById(sourceId);

                let currentStep = this.getStepByOrderNumber(1);

                if (source.isPageDesignSet === true && source.isImportSet === false) {
                    currentStep = this.getStepByOrderNumber(4);
                }
                else if (source.isPageDesignSet === true && source.isImportSet == true) {
                    currentStep = this.getStepByOrderNumber(3);
                }
                else if (source.rootElementSelected === true) {
                    currentStep = this.getStepByOrderNumber(3);
                }
                else if (!isNil(source.storageFileId) && !isEmpty(source.storageFileId)) {
                    currentStep = this.getStepByOrderNumber(2);
                }

                this.setState({
                    loading: false,
                    currentStep: currentStep
                })
            })
    }


    handleGoBack() {
        const currentStepOrderNumber = this.state.currentStep.orderNumber;

        if (currentStepOrderNumber > 1 && this.state.currentStep.allowGoToPast) {
            this.setState({
                currentStep: this.getStepByOrderNumber(currentStepOrderNumber - 1)
            })
        }
    }

    handleGoNext() {
        const currentStepOrderNumber = this.state.currentStep.orderNumber;
        const nextStep = this.getStepByOrderNumber(currentStepOrderNumber + 1);

        if (nextStep && this.isValidCurrentStep()) {
            this.setState({
                currentStep: nextStep
            })
        }
    }

    getSteps(): IWizardStep[] {
        const steps: IWizardStep[] = [
            { title: 'Zdroj dat', componentName: 'SourceTypeStep', orderNumber: 1, showNextStepButton: false, allowGoToPast: true },
            { title: 'Výběr elementu', componentName: 'SourceSelectElementStep', orderNumber: 2, showNextStepButton: false, allowGoToPast: true },
            // { title: 'Filtrace', componentName: '', orderNumber: 3, showNextStepButton: true },
            { title: 'Mapování', componentName: 'SourceProductMapperStep', orderNumber: 3, showNextStepButton: true, allowGoToPast: true },
            { title: 'Import', componentName: 'SourceImportStep', orderNumber: 4, allowGoToPast: true },
        ];
        return steps;
    }

    getStepByOrderNumber(orderNumber: number): IWizardStep {
        const step = find(this.getSteps(), { orderNumber: orderNumber });
        return step;
    }

    isValidCurrentStep(): boolean {

        const currentStep = this.state.currentStep;

        /* if (currentStep.componentName === TireBasicInfoStep.name) {
             if (this.basicInfoStep.current.validate()) {
                 return this.basicInfoStep.current.validate()
             }
         }*/
        return true;
    }

    handleSubmit() {
        let inputs: React.RefObject<IInputField>[] = [

        ]
        const isValid = this.validateInputs(inputs);

        if (isValid) {
            this.setState({
                loading: true
            })
        }
    }

    render() {
        const loading = this.state.loading;
        if (loading) {
            return <LoadingScreen />
        }

        const serverErrors = this.state.serverErrors;
        const currentStep = this.state.currentStep;
        const source = this.props.sourceStore.getById(this.props.match.params.sourceId);
        const websiteId = this.props.match.params.websiteId;
        const website = this.props.websiteStore.getById(websiteId);
        const storageFile = this.props.storageFileStore.getById(source.storageFileId);

        return (

            <>
                <WebsiteDocumentTitle website={website} title={`Zdroj dat "${source.name}"`}>
                    <div className="page-title-box">
                        <div className="page-title">
                            Zdroj dat "{source.name}"
                        </div>
                    </div>

                    <Wizard
                        steps={this.getSteps()}
                        currentStep={currentStep}
                        onGoToStep={(orderNumber: number) => this.setState({ currentStep: this.getStepByOrderNumber(orderNumber) })}
                        onGoBack={this.handleGoBack.bind(this)}
                        onGoNext={this.handleGoNext.bind(this)}
                    >
                        {currentStep.componentName === 'SourceTypeStep' &&
                            <SourceTypeStep
                                source={source}
                                website={website}
                                goToNextStep={this.handleGoNext.bind(this)}
                                storageFile={storageFile}
                            />
                        }

                        {currentStep.componentName === 'SourceSelectElementStep' &&
                            <SourceSelectElementStep
                                source={source}
                                website={website}
                                goToNextStep={this.handleGoNext.bind(this)}
                            />
                        }

                        {currentStep.componentName === 'SourceProductMapperStep' &&
                            <SourceProductMapperStep
                                source={source}
                                website={website}
                                goToNextStep={this.handleGoNext.bind(this)}
                            />
                        }

                        {currentStep.componentName === 'SourceImportStep' &&
                            <SourceImportStep
                                source={source}
                                website={website}
                                goToStepBack={this.handleGoBack.bind(this)}
                            />
                        }
                    </Wizard>
                </WebsiteDocumentTitle>
            </>
        )
    }
}