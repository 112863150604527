import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap"
import IImage from "../../interfaces/IImage";
import ImagesApiService from "../../services/imagesApiService";
import { BtnSubmit } from "../inputs/btnSubmit";
import InputText from "../inputs/inputText";

interface IProps {
    image: IImage,
    onHide(): void,
}

const imagesApiService = new ImagesApiService();

export const SetImageVariableModal = (props: IProps) => {

    const { onHide, image } = props;
    const [loading, setLoading] = useState(false);

    const [variableKey, setVariableKey] = useState(image.variableKey || '');

    useEffect(() => {
        return imagesApiService.cancelRequests();
    }, [])

    const textInput = useRef<InputText>(null);

    function handleSubmit() {
        textInput.current.validate();
        const isValid = textInput.current.isValid();

        if (isValid) {
            setLoading(true);

            imagesApiService.setVariable(image.id, variableKey)
                .then(data => {
                    if (data.success) {
                        setLoading(false);
                        onHide();
                    }
                })
        }
    }

    function handleDelete() {
        setLoading(true);

        imagesApiService.deleteVariable(image.id)
            .then(data => {
                if (data.success) {
                    setLoading(false);
                    onHide();
                }
            })
    }

    return (
        <Modal animation={false} centered={true} show onHide={onHide}>
            <Modal.Header closeButton onHide={onHide.bind(this)}>
                <Modal.Title>
                    <h5>Nastavení obrázku jako proměnné</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputText
                    ref={textInput}
                    uid=""
                    label="Klíč"
                    onChange={(value) => setVariableKey(value)}
                    value={variableKey}
                    required
                />

                {variableKey.length > 0 && <small className="mb-4">V šabloně použijete jako <b>{`{{.Site.Params.${variableKey}}}`}</b></small>}

                <div className="btn-group w-100 mt-1">
                    <BtnSubmit className="btn btn-primary w-100"loading={loading} onClick={handleSubmit}>
                        Uložit změny
                    </BtnSubmit>

                    {image.isVariable && <BtnSubmit className="btn btn-danger w-100" loading={loading} onClick={handleDelete}>
                        Smazat
                    </BtnSubmit>}
                </div>
            </Modal.Body>
        </Modal>)
}