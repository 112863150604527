import { filter, find, findIndex, orderBy, remove } from 'lodash';
import { observable, action, makeObservable } from 'mobx'
import ISource from '../interfaces/ISource';

export default class SourceStore {

     list: ISource[] = [];

    addRecords(records: ISource[]): any {
        if (records.length > 0) {
            records.forEach((record: ISource) => this.addRecord(record));
        }
    }

    
    private addRecord(record: ISource) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    public getById(id: string): ISource {
        return find(this.list, { id: id });
    }

    public getByIds(ids: string[]): ISource[] {
        return this.orderByName(filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        }))
    }

    public getByUserId(userId: string): ISource[] {
        return this.orderByName(filter(this.list, { userId: userId }));
    }

    public removeById(id: string) {
        this.list = filter(this.list, item => {
            if (item.id != id) {
                return true;
            }
            return false;
        })
    }

    public getByWebsiteId(websiteId: string): ISource[] {
        return this.orderByName(filter(this.list, { websiteId: websiteId }));
    }

    private orderByName(sources: ISource[]): ISource[] {
        return orderBy(sources, ['name'], ['asc']);
    }

    constructor() {
        makeObservable(this, {
            list: observable,
            addRecords: action,
            removeById: action
        })
    }
}