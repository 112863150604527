import IOptionData from "../components/inputs/IOptionData";
import ICategory from "../interfaces/ICategory";

export default class CategoryHelper {

    public static enumerationFromCategory(categories: ICategory[]) {
        {
            let items: JSX.Element[] = [];

            categories.map((item: ICategory, index) => 
                items.push(
                    <span key={item.id}>
                        {item.name} {index != categories.length - 1 ? "," : ""}
                    </span>
                )
            )
            return items;
        }
    }

    public static getCategoryOptions(categories: ICategory[]): IOptionData[] {
        const categoryOptions: IOptionData[] = [];
        categories.map(category => {
            if (category) {
                categoryOptions.push({ label: category.name, value: category.id })
            }  
        })
        return categoryOptions;
    }
}