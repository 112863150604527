import { filter, find, findIndex, orderBy } from 'lodash';
import { observable, action, makeObservable } from 'mobx'
import IMenuItem from '../interfaces/IMenuItem';

export default class MenuItemStore {

    list: IMenuItem[] = [];

    addRecords(records: IMenuItem[]): any {
        if (records.length > 0) {
            records.forEach((record: IMenuItem) => this.addRecord(record));
        }
    }

    private addRecord(record: IMenuItem) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    public getById(id: string): IMenuItem {
        return find(this.list, { id: id });
    }

    public getByIds(ids: string[]): IMenuItem[] {
        return filter(this.list, item => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
    }

    public getByMenuId(menuId: string): IMenuItem[] {
        return orderBy(filter(this.list, { menuId: menuId }), ['index'], ['asc']);
    }


    public removeById(id: string) {
        this.list = filter(this.list, item => {
            if (item.id != id) {
                return true;
            }
            return false;
        })
    }


    public removeChildrenItems(parentMenuItemId: string) {
        this.list = filter(this.list, item => {
            if (item.parentMenuItemId != parentMenuItemId) {
                return true;
            }
            return false;
        })
    }

 
    public removeByCategoryId(categoryId: string) {
        this.list = filter(this.list, item => {
            if (item.categoryId != categoryId) {
                return true;
            }
            return false;
        })
    }

    
    public removeByMenuId(menuId: string) {
        this.list = filter(this.list, item => {
            if (item.menuId != menuId) {
                return true;
            }
            return false;
        })
    }

    constructor() {
        makeObservable(this, {
            list: observable,
            addRecords: action,
            removeById: action,
            removeChildrenItems: action,
            removeByCategoryId: action,
            removeByMenuId: action,
        })
    }
}