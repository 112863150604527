import { Link } from 'react-router-dom';

export const AccountBottomNavbar = () => {
    return (
        <nav className="bottom-navbar">
            <div className="container-fluid">
                <ul className="nav page-navigation">
                    <li className="nav-item">
                        <Link to='/websites' className="nav-link">
                            <i className="zmdi zmdi-view-web feather feather-feather menu-icon mr-1" style={{ fontSize: '20px' }}></i>
                            <span className="menu-title">Moje weby</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/themes' className="nav-link">
                            <i className="zmdi zmdi-invert-colors feather feather-feather menu-icon mr-1" style={{ fontSize: '20px' }}></i>
                            <span className="menu-title">Šablony</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link" >
                        <i className="zmdi zmdi-wrench feather feather-feather menu-icon mr-1" style={{ fontSize: '18px' }}></i>
                            <span className="menu-title">Nástroje</span>
                        </div>
                        <div className="submenu">
                            <ul className="submenu-item">
                                <li className="nav-item">
                                    <Link
                                        to={`/tools/variables`}
                                        className='nav-link'
                                    >
                                        Globální proměnné
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <Link to='/storage' className="nav-link">
                            <i className="zmdi zmdi-folder-outline feather feather-feather menu-icon mr-1" style={{ fontSize: '19px' }}></i>
                            <span className="menu-title">Zdrojové soubory</span>
                        </Link>
                    </li>
                    {/*  <li className="nav-item">
                        <a href="#" className="nav-link">
                            <i className=" zmdi zmdi-help-outline feather feather-feather menu-icon mr-1" style={{ fontSize: '20px' }}></i>
                            <span className="menu-title">FAQ</span>
                        </a>
                    </li>
                    */}
                </ul>
            </div>
        </nav>)
}