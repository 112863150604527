import * as React from 'react';
import IInputField from './IInputField';

interface IProps {
    uid?: string,
    maxLength?: number,
    minLength?: number,
    required?: boolean;
    placeholder?: string;
    label: string;
    value: string;
    onChange?(value: any, key: string): void;
    rows?: number, 
    labelClassName?: string
    showTopCountOfChars?: boolean,
    onBlur?: Function,
}

interface IState {
    errors: string[],
}

export default class Textarea extends React.Component<IProps, IState> implements IInputField {
    private input: React.RefObject<HTMLTextAreaElement>;
    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();

        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current.focus();
    }

    handleOnBlur() {
        this.validate();
        if (this.isValid() && this.props.onBlur) {
            this.props.onBlur();
        }

    }

    getErrors(value = this.props.value) {
        let errors = [];
        if (this.props.required && (value || '').length === 0) {
            errors.push("Toto pole je povinné");
        }
        if (this.props.minLength && (value || '').length < this.props.minLength) {
            errors.push("Zadejte alespoň " + this.props.minLength + " znaků");
        }
        if (this.props.maxLength && (value || '').length > this.props.maxLength) {
            errors.push("Pole může obsahovat maximálně " + this.props.maxLength + " znaků");
        }
        if (this.props.maxLength && (value || '').length > this.props.maxLength) {

            value = value.slice(0, this.props.maxLength);
        }
        return errors;
    }

    isValid() {
        return this.getErrors().length === 0;
    }

    validate(value = this.props.value) {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    handleChange(e: any) {
        this.validate(e.target.value);
        this.props.onChange(e.target.value, this.props.uid);
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderCountOfChars() {
        if (this.props.maxLength) {
            return (
                <small>
                    Napsáno: {this.props.value != null ? this.props.value.length : 0} (max: {this.props.maxLength} znaků)
                </small>
            );
        }
    }

    renderInput() {
        return (
            <div>
                <textarea
                    ref={this.input}
                    maxLength={this.props.maxLength}
                    onChange={this.handleChange.bind(this)}
                    value={this.props.value || ''}
                    rows={this.props.rows || 3}
                    className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                    placeholder={this.props.placeholder}
                    data-clarity-unmask="True"
                    onBlur={this.handleOnBlur.bind(this)}
                />

                {!this.props.showTopCountOfChars ? this.renderCountOfChars() : null}
            </div>
        );
    }

    renderLabel() {

        if (!this.props.label) {
            return null;
        }

        return (
            <label className={this.props.labelClassName || 'form-control-label'} >
                <span dangerouslySetInnerHTML={{ __html: this.props.label }}></span> {this.props.required && this.props.label ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {

        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.props.showTopCountOfChars ? <div className="mt-0">{this.renderCountOfChars()}</div> : null}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }


}
