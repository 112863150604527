import React from 'react';
import StringHelper from '../../helpers/stringHelper';

interface IProps {
    number: any
}

export const SeparateThousends = (props: IProps) => {
    return (
        <span dangerouslySetInnerHTML={{
            __html: StringHelper.separateThousands(props.number)
        }}>
        </span>
    )
}