import React from "react";
import BaseComponent from './../baseComponent';
import IWebsite from "../../interfaces/IWebsite";
import WebsiteSyncModal from "./websiteSyncModal";

interface IState {
    loading: boolean,
    showModal: boolean,
}

interface IProps {
    website: IWebsite,
}

export default class WebsiteSyncButton extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            showModal: false,
        }
    }

    handleShowModal() {
        this.setState({
            showModal: true
        })
    }

    handleHideModal() {
        this.setState({
            showModal: false
        })
    }

    renderIcons() {
        const website = this.props.website;

        if (website.awsPendingSync) {
            return (
                <>
                    <i className="far fa-clock text-warning"></i>

                    <i className="fas fa-sync text-primary position-absolute sync-website-small-icon"></i>
                </>)
        }
        else if (website.isUpToDate) {
            return (
                <>
                    <i className="fas fa-sync text-primary"></i>

                    <i className="fas fa-check text-success position-absolute sync-website-small-icon"></i>
                </>)
        }
        else {
            return (
                <>
                    <i className="fas fa-sync text-primary"></i>
                </>)
        }
    }

    render() {
        const { showModal } = this.state;
        const { website } = this.props;

        return (
            <div className="position-absolute sync">
                {showModal && <WebsiteSyncModal website={website} onHide={this.handleHideModal.bind(this)} />}

                <button
                    className="btn btn-sync position-relative"
                    onClick={this.handleShowModal.bind(this)}
                >
                    {this.renderIcons()}
                </button>
            </div>
        )
    }
}