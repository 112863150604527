import { inject, observer } from 'mobx-react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import { orderBy } from 'lodash';
import { PageTitle } from '../../../components/others/pageTitle';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';
import ImageStore from '../../../stores/ImageStore';
import { ImagesGrid } from '../../../components/images/imagesGrid';
import { UploadImageModal } from '../../../components/images/uploadImageModal';
import { useEffect, useState } from 'react';
import WebsitesApiService from '../../../services/websitesApiService';

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    imageStore?: ImageStore,
}

interface IMatchParams {
    websiteId: string,
}

const websitesApiService = new WebsitesApiService();

export const ImageManagerPage = inject('currentUserStore', 'websiteStore', 'imageStore')(observer((props: IProps) => {

    const websiteId = props.match.params.websiteId;

    const [loading, setLoading] = useState(true);
    const [imageIds, setImageIds] = useState<string[]>([]);
    const [showNewUploadModal, setShowUploadModal] = useState(false);

    useEffect(() => {
        websitesApiService.getImages(websiteId)
            .then(data => {
                setImageIds(data.records.images.items.map(s => s.id));
                setLoading(false);
            })

        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])

    const images = orderBy(props.imageStore.getByIds(imageIds), ['uploadedUtc'], ['desc']);
    const website = props.websiteStore.getById(websiteId);

    function handleUploadNew(state: boolean) {
        setShowUploadModal(state);
    }

    return (<WebsiteDocumentTitle website={website} title="Správa obrázků">
        <div className="row align-items-center mb-4">
            <div className="col-6">
                <PageTitle
                    title="Správa obrázků"
                    subtitle="Vaše webová galerie."
                />
            </div>
            <div className="col-6">
                <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                    <div className="dashboard-btn-group d-flex align-items-center">
                        <button onClick={() => handleUploadNew(true)} className="btn btn-primary ml-1">Nahrát obrázky</button>
                        {/* <a href="#" className="btn btn-primary ml-1"><i className="ti-bookmark-alt font-11" /> Export</a> */}

                        {showNewUploadModal && <UploadImageModal
                            website={website}
                            onHide={() => handleUploadNew(false)}
                            onUploaded={(ids) => setImageIds(imageIds.concat(ids))}
                        />}
                    </div>
                </div>
            </div>
        </div>

        {loading && <LoadingScreen />}

        {!loading && images.length > 0 && 
            <div className={`card`}>
                <div className="card-body">
                    <ImagesGrid images={images} website={website} />

                    <div className="text-center">
                        {images.length === 0 && <h6 className="py-3">Nic nenalezeno</h6>}
                    </div>
                {/*
                    <CustomPagination
                        currentPageNumber={this.state.pageNumber}
                        itemsPerPage={30}
                        loading={this.state.loading}
                        onChangePage={this.handleChangePage.bind(this)}
                        totalItemsCount={this.state.totalCount}
                    />*/}
                </div>
            </div>}

        {/*!loading && website.categoriesCount === 0 &&
                        <NoCategoriesScreen
                            onCreateNew={this.handleUploadNew.bind(this)}
                        />*/}
    </WebsiteDocumentTitle>)
}))