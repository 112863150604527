import BaseComponent from '../baseComponent';
import Swal from "sweetalert2";
import IPage from "../../interfaces/IPage";
import { ShowDate } from "../others/showDate";
import ICategory from '../../interfaces/ICategory';
import ISource from '../../interfaces/ISource';
import { Link } from 'react-router-dom';
import CategoryHelper from '../../helpers/categoryHelper';
import { ClipLoader } from 'react-spinners';
import IWebsite from '../../interfaces/IWebsite';
import ISection from '../../interfaces/ISection';
import { isNil } from 'lodash';

interface IState {
    loading: boolean,
}

interface IProps {
    page: IPage,
    categories: ICategory[],
    source: ISource,
    website: IWebsite,
    section: ISection,
}

export default class PageTableRow extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false
        }
    }

    handleDelete() {
        const page = this.props.page;

        Swal.fire({
            title: `Opravdu chcete smazat stránku "${page.name}"?`,
            html: `Akce je nevratná.`,
            icon: 'warning',
            confirmButtonText: 'Ano, smazat',
            cancelButtonText: 'Zrušit',
            showCancelButton: true,
            focusCancel: true,
        }).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loading: true
                })

                this.pagesApiService.deletePage(page.id)
                    .then(data => {
                        this.setState({
                            loading: false
                        })
                    })
            }
        })
    }

    render() {
        const { page, categories, source, website, section } = this.props;

        return (
            <tr>
                <td>
                    <ShowDate date={page.publishedUtc} />
                </td>

                <td>
                    {page.name}
                </td>

                <td>
                    {!isNil(section) ? `${section.name}` : ''}
                </td>

                <td>
                    {CategoryHelper.enumerationFromCategory(categories)}
                </td>

                <td>
                    {source && `${source.name}`}
                </td>

                <td>
                    {this.state.loading ?
                        <ClipLoader
                            size={30}
                            color={"#449DF7"}
                            loading={true}
                        /> :
                        < div className="btn-group" role="group" aria-label="Basic example">
                            <Link
                                to={`/websites/${website.id}/pages/${page.id}/edit`}
                                className="action-icon mr-1"
                            >
                                <i className="zmdi zmdi-edit"></i>
                            </Link>

                            <button
                                className="action-icon"
                                onClick={this.handleDelete.bind(this)}
                            >
                                <i className="zmdi zmdi-delete"></i>
                            </button>
                        </div>}
                </td>
            </tr>)
    }
}