import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../components/baseComponent";
import { FormErrorSummary } from "../../components/forms/FormErrorSummary";
import IFormError from "../../components/forms/IFormError";
import { BtnSubmit } from "../../components/inputs/btnSubmit";
import InputEmail from "../../components/inputs/inputEmail";
import InputPassword from "../../components/inputs/inputPassword";
import { CustomDocumentTitle } from "../../utils/customDocumentTitle";
import { history } from './../../App';

interface IState {
    loginForm: {
        email: string,
        password: string,
    },
    serverErrors: IFormError[],
    loading: boolean,
}

interface IProps {

}

export default class LoginPage extends BaseComponent<IProps, IState> {

    private inputEmail = React.createRef<InputEmail>();
    private inputPassword = React.createRef<InputPassword>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            loginForm: {
                email: '',
                password: ''
            },
            serverErrors: [],
            loading: false,
        }

        this.inputEmail = React.createRef();
        this.inputPassword = React.createRef();
    }

    handleChange(value: any, key: string) {
        this.setState({
            loginForm: {
                ...this.state.loginForm,
                [key]: value
            }
        })
    }

    handleSubmit() {
        const isValid = this.validateInputs([
            this.inputEmail,
            this.inputPassword,
        ])

        const loginForm = this.state.loginForm;
        if (isValid) {
            this.setState({
                loading: true
            })

            this.usersApiService.login(loginForm.email, loginForm.password)
                .then(data => {
                    if (data.success === true) {
                        history.push('/');
                    }
                    else {
                        this.setState({
                            serverErrors: data.formErrors,
                            loading: false,
                        })
                    }
                });
        }
    }

    render() {
        return (
            <CustomDocumentTitle title={'Přihlášení'} >
                <div className="main-content- h-100vh">
                    <div className="container h-100">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-md-7 col-lg-5 mx-auto">
                                {/* Middle Box */}
                                <div className="middle-box">
                                    <div className="card">
                                        <div className="card-body p-4">
                                            <h4 className="my-2 text-center">Přihlášení do aplikace</h4>

                                            <div className="mt-lg-2">
                                                <InputEmail
                                                    uid='email'
                                                    ref={this.inputEmail}
                                                    label='Email'
                                                    required={true}
                                                    value={this.state.loginForm.email}
                                                    onChange={this.handleChange.bind(this)}
                                                />

                                                <InputPassword
                                                    uid='password'
                                                    ref={this.inputPassword}
                                                    label='Heslo'
                                                    required={true}
                                                    value={this.state.loginForm.password}
                                                    onChange={this.handleChange.bind(this)}
                                                    minLength={8}
                                                    onEnter={this.handleSubmit.bind(this)}
                                                />

                                                <FormErrorSummary
                                                    errors={this.state.serverErrors}
                                                />

                                                <BtnSubmit
                                                    loading={this.state.loading}
                                                    onClick={this.handleSubmit.bind(this)}
                                                >
                                                    Přihlásit se
                                                </BtnSubmit>
                                            </div>

                                            <div className="text-center mt-2">
                                                <small>
                                                    {/*     <Link to='/register'>
                                                        <span
                                                            className="link"
                                                        >
                                                            Vytvořit účet</span>
                                                    </Link> */}
                                            &nbsp;|&nbsp;
                                                    <Link to='/forgotten-password'>
                                                        <span
                                                            className="link"
                                                        >
                                                            Zapomenuté heslo
                                                    </span>
                                                    </Link>
                                                </small>
                                            </div>
                                        </div> {/* end card-body */}
                                    </div>
                                    {/* end card */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        )
    }
}