import React from 'react';
import DocumentTitle from 'react-document-title';

interface IProps {
    title: string,
    children?: React.ReactNode;
}

export const CustomDocumentTitle = (props: IProps) => {
    let title = props.title;
    title += ' | Clonty';

    return (
        <DocumentTitle title={title}>
            <div>
                {props.children}
            </div>
        </ DocumentTitle >
    );
}