import IOptionData from "../components/inputs/IOptionData";
import ISection from "../interfaces/ISection";

export default class SectionHelper {

    public static getSectionOptions(sections: ISection[]): IOptionData[] {
        const options: IOptionData[] = [];
        sections.map(section => 
            options.push({ label: section.name, value: section.id })
        )
        return options;
    }
}