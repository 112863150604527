import React from 'react';
import BaseComponent from './../baseComponent';
import ISource from '../../interfaces/ISource';
import IInputField from '../inputs/IInputField';
import IFormError from '../forms/IFormError';
import InputUrl from '../inputs/inputUrl';
import { BtnSubmit } from '../inputs/btnSubmit';
import { filter } from 'lodash';
import { Modal } from 'react-bootstrap';

interface IState {
    loading: boolean,
    form: {
        url: string
    },
    serverErrors: IFormError[],
}

interface IProps {
    source: ISource,
    onHide(): void,
    urlSubmitted(): void,
}

export default class SourceDownloadFromUrlModal extends BaseComponent<IProps, IState> {

    private inputUrl = React.createRef<InputUrl>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            form: {
                url: ''
            },
            serverErrors: []
        }
        this.inputUrl = React.createRef();
    }

    getInputs(): React.RefObject<IInputField>[] {
        var fields: React.RefObject<IInputField>[] = [
            this.inputUrl
        ];
        return fields;
    }

    validate(): boolean {
        return this.validateInputs(this.getInputs());
    }

    handleSubmit() {
        const source = this.props.source;
        const isValid = this.validate();

        if (isValid) {
            this.setState({
                loading: true
            })

            this.sourcesApiService.downloadFileFromUrl(source.id, this.state.form.url)
                .then(data => {
                    if (!data.success) {
                        this.setState({
                            serverErrors: data.formErrors,
                            loading: false,
                        })
                        this.validate();
                    }
                    else {
                        this.props.urlSubmitted();
                    }
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    render() {
        const loading = this.state.loading;
        const form = this.state.form;
        const source = this.props.source;

        return (
            <Modal animation={false} centered={true} show onHide={this.props.onHide.bind(this)}>
                <Modal.Header closeButton onHide={this.props.onHide.bind(this)}>
                    <Modal.Title>
                        <h5>Stáhnout soubor z URL adresy</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Podporujeme JSON, XML a CSV soubory až do velikosti 100 MB.</p>

                    <InputUrl
                        uid="url"
                        ref={this.inputUrl}
                        label=""
                        value={form.url}
                        placeholder="Vložte url adresu"
                        onChange={this.handleChange.bind(this)}
                        required
                        serverErrors={this.state.serverErrors}
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Pokračovat  <i className="fas fa-angle-double-right ml-1"></i>
                    </BtnSubmit>
                </Modal.Body>
            </Modal>)
    }
}