import React from 'react';
import axios, { AxiosResponse } from 'axios';
import Swal from 'sweetalert2';
import { toast, ToastContent, ToastPosition } from 'react-toastify';
import IInputField from './inputs/IInputField';
import IResponseData from '../interfaces/records/IResponseData';
import UsersApiService from '../services/usersApiService';
import PagesApiService from '../services/pagesApiService';
import SourcesApiService from '../services/sourcesApiService';
import WebsitesApiService from '../services/websitesApiService';
import CategoriesApiService from '../services/categoriesApiService';
import ThemesApiService from '../services/themesApiService';
import VariablesApiService from '../services/variablesApiService';
import SectionsApiService from '../services/sectionsApiService';
import MenusApiService from '../services/menusApiService';
import ImagesApiService from '../services/imagesApiService';
import StorageFilesApiService from '../services/storageFilesApiService';

export default class Base<P, S> extends React.Component<P, S> {

    protected usersApiService: UsersApiService;
    protected pagesApiService: PagesApiService;
    protected sourcesApiService: SourcesApiService;
    protected websitesApiService: WebsitesApiService;
    protected categoriesApiService: CategoriesApiService;
    protected themesApiService: ThemesApiService;
    protected variablesApiService: VariablesApiService;
    protected sectionsApiService: SectionsApiService;
    protected menusApiService: MenusApiService;
    protected imagesApiService: ImagesApiService;
    protected storageFilesApiService: StorageFilesApiService;

    constructor(props: P) {
        super(props);

        this.usersApiService = new UsersApiService();
        this.pagesApiService = new PagesApiService();
        this.sourcesApiService = new SourcesApiService();
        this.websitesApiService = new WebsitesApiService();
        this.categoriesApiService = new CategoriesApiService();
        this.themesApiService = new ThemesApiService();
        this.variablesApiService = new VariablesApiService();
        this.sectionsApiService = new SectionsApiService();
        this.menusApiService = new MenusApiService();
        this.imagesApiService = new ImagesApiService();
        this.storageFilesApiService = new StorageFilesApiService();
    }

    componentWillUnmount() {
        this.usersApiService.cancelRequests();
        this.pagesApiService.cancelRequests();
        this.sourcesApiService.cancelRequests();
        this.websitesApiService.cancelRequests();
        this.categoriesApiService.cancelRequests();
        this.themesApiService.cancelRequests();
        this.variablesApiService.cancelRequests();
        this.sectionsApiService.cancelRequests();
        this.menusApiService.cancelRequests();
        this.imagesApiService.cancelRequests();
        this.storageFilesApiService.cancelRequests();
    }

    validateInputs(inputs: React.RefObject<IInputField>[]) {
        let firstInvalid: IInputField;
        for (var i = 0; i < inputs.length; i++) {
            const input: IInputField = inputs[i].current;

            if (input) {
                input.validate();
                if (!input.isValid() && !firstInvalid) {
                    firstInvalid = input;
                    input.focus();
                }
            }
        }

        if (firstInvalid) {
            return false;
        }
        return true;
    }

    protected toastSuccess(content: ToastContent, position?: ToastPosition) {
        toast.success(<div>
            <i className="fas fa-check mx-2 text-success" />
            {content}
        </div>, {
            position: position || "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        });
    }

    protected toastError(content: ToastContent, position?: ToastPosition) {
        toast.error(<div>
            <i className="fas fa-times mx-2 text-danger" />
            {content}
        </div>, {
            position: position || "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        });
    }

    protected post(url: string, data: object, loading: boolean, loadingClose: boolean = true): Promise<AxiosResponse<IResponseData>> {
        if (loading) {
            Swal.fire({
                title: 'Načítám',
                willOpen: () => {
                    Swal.showLoading();
                },
                showConfirmButton: false,
            })
        }

        return new Promise((resolve, reject): any => {
            axios.post(url, data, {

                //cancelToken: new axios.CancelToken((c) => this.cancellations.push(c)),
            }).then(resolve).catch(reject).finally(() => {
                if (loading && loadingClose) {
                    if (Swal.isVisible()) {
                        Swal.close();
                    }
                }
            })
        });
    }

     protected put(url: string, data: object, loading: boolean, loadingClose: boolean = true): Promise<AxiosResponse<IResponseData>> {
        if (loading) {
            Swal.fire({
                title: 'Načítám',
                willOpen: () => {
                    Swal.showLoading();
                },
                showConfirmButton: false,
            })
        }

        return new Promise((resolve, reject): any => {
            axios.put(url, data, {

            //    cancelToken: new axios.CancelToken((c) => this.cancellations.push(c)),
            }).then(resolve).catch(reject).finally(() => {
                if (loading && loadingClose) {
                    if (Swal.isVisible()) {
                        Swal.close();
                    }
                }
            })
        });
    }

    protected patch(url: string, data: object, loading: boolean): Promise<AxiosResponse<IResponseData>> {
        if (loading) {
            Swal.fire({
                title: 'Načítám',
                willOpen: () => {
                    Swal.showLoading();
                },
                showConfirmButton: false,
            })
        }

        return new Promise((resolve, reject): any => {
            axios.patch(url, data, {
                //cancelToken: new axios.CancelToken((c) => this.cancellations.push(c)),
            }).then(resolve).catch(reject).finally(() => {
                if (loading) {
                    if (Swal.isVisible()) {
                        Swal.close();
                    }
                }
            })
        });
    }

    protected delete(url: string, loading: boolean, loadingClose: boolean = true): Promise<AxiosResponse<IResponseData>> {
        if (loading) {
            Swal.fire({
                title: 'Načítám',
                willOpen: () => {
                    Swal.showLoading();
                },
                showConfirmButton: false,
            })
        }

        return new Promise((resolve, reject): any => {
            axios.delete(url, {
             //   cancelToken: new axios.CancelToken((c) => this.cancellations.push(c)),
            }).then(resolve).catch(reject).finally(() => {
                if (loading && loadingClose) {
                    if (Swal.isVisible()) {
                        Swal.close();
                    }
                }
            })
        });
    }
}