import { inject, observer } from 'mobx-react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import BaseComponent from '../../../components/baseComponent';
import SectionStore from '../../../stores/SectionStore';
import ISection from '../../../interfaces/ISection';
import { PageTitle } from '../../../components/others/pageTitle';
import React from 'react';
import { NoSectionsScreen } from '../../../components/sections/noSectionsScreen';
import SectionTableRow from '../../../components/sections/sectionTableRow';
import { SectionsTable } from '../../../components/sections/sectionsTable';
import CreateNewSectionModal from '../../../components/sections/createNewSectionModal';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';

interface IState {
    loading: boolean,
    showCreateNewModal: boolean,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    sectionStore?: SectionStore
}

interface IMatchParams {
    websiteId: string,
}

@inject('currentUserStore', 'websiteStore', 'sectionStore')
@observer
export default class SectionsListPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            showCreateNewModal: false,
        }
    }

    componentDidMount() {
        const websiteId = this.props.match.params.websiteId;
        this.websitesApiService.getSections(websiteId)
            .then(data => {
                this.setState({
                    loading: false
                })
            })
    }

    renderTable(sections: ISection[]) {
        return (
            <>
                <div className={`card`}>
                    <div className="card-body">
                        <SectionsTable>
                            {sections.map(section => {
                                return (
                                    <SectionTableRow
                                        key={section.id}
                                        section={section}
                                    />
                                )
                            })}
                        </SectionsTable>

                        <div className="text-center">
                            {sections.length === 0 && <h6 className="py-3">Nic nenalezeno</h6>}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    handleCreateNew() {
        this.setState({
            showCreateNewModal: true
        })
    }

    render() {     
        const loading = this.state.loading;
        const websiteId = this.props.match.params.websiteId;
        const website = this.props.websiteStore.getById(websiteId);
        const sections = this.props.sectionStore.getByWebsiteId(websiteId);

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Sekce">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <PageTitle
                                title="Sekce"
                                subtitle="Sekce je skupina stránek stejného typu obsahu."
                            />
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <button onClick={this.handleCreateNew.bind(this)} className="btn btn-primary ml-1">Vytvořit sekci</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showCreateNewModal &&
                        <CreateNewSectionModal
                            website={website}
                            onHide={() => this.setState({ showCreateNewModal: false })}
                        />}

                    {loading && <LoadingScreen />}

                    {!loading && sections.length > 0 && this.renderTable(sections)}

                    {!loading && sections.length === 0 &&
                        <NoSectionsScreen
                            onCreateNew={this.handleCreateNew.bind(this)}
                        />}
                </WebsiteDocumentTitle>
            </>
        )
    }
}