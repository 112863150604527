import { inject, observer } from 'mobx-react';
import { orderBy } from 'lodash';
import BaseComponent from './../../../components/baseComponent';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import VariableStore from '../../../stores/VariableStore';
import IVariable from '../../../interfaces/IVariable';
import { CustomDocumentTitle } from '../../../utils/customDocumentTitle';
import LoadingScreen from '../../../components/screens/loadingScreen';
import { VariablesTable } from '../../../components/variables/variablesTable';
import VariableTableRow from '../../../components/variables/variableTableRow';
import React from 'react';
import CreateNewVariableModal from '../../../components/variables/createNewVariableModal';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';

interface IState {
    loading: boolean,
    variableIds: string[],
    showCreateNewModal: boolean,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    variableStore?: VariableStore,
}

interface IMatchParams {
    websiteId: string,
}

@inject('currentUserStore', 'variableStore')
@observer
export default class WebsiteVariablesListPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            variableIds: [],
            showCreateNewModal: false,
        }
    }

    componentDidMount() {
        const websiteId = this.props.match.params.websiteId;

        this.websitesApiService.getVariables(websiteId)
            .then(data => {
                this.setState({
                    loading: false,
                    variableIds: data.records.variables.items.map(s => s.id)
                })
            })
    }

    renderTable(variables: IVariable[]) {
        return (
            <>
                <div className={`card`}>
                    <div className="card-body">
                        <VariablesTable>
                            {variables.map(variable => {
                                return (
                                    <VariableTableRow
                                        key={variable.id}
                                        variable={variable}
                                    />
                                )
                            })}
                        </VariablesTable>
                    </div>
                </div>
            </>
        )
    }

    handleCreateNew() {
        this.setState({
            showCreateNewModal: true
        })
    }

    handleCreated(variable: IVariable) {
        let variableIds = this.state.variableIds;
        variableIds.push(variable.id);

        this.setState({
            variableIds: variableIds
        })
    }

    render() {
        const variables = orderBy(this.props.variableStore.getByIds(this.state.variableIds), ['key'], ['asc']);
        const loading = this.state.loading;
        const websiteId = this.props.match.params.websiteId;

        return (
            <>
                <CustomDocumentTitle title="Proměnné">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <div className="dashboard-header-title">
                                <h5 className="mb-0">Proměnné</h5>
                                <p className="mb-0 font-15">Definujte jednou, použíjte mnohokrát v rámci tohoto webu.</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <button onClick={this.handleCreateNew.bind(this)} className="btn btn-primary ml-1">Přidat proměnnou</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showCreateNewModal &&
                        <CreateNewVariableModal
                            onHide={() => this.setState({ showCreateNewModal: false })}
                            onCreated={this.handleCreated.bind(this)}
                            websiteId={websiteId}
                        />}

                    {loading && <LoadingScreen />}

                    {!loading && this.renderTable(variables)}
                </CustomDocumentTitle>
            </>
        )
    }
}