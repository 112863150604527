import IImage from "../../interfaces/IImage";
import IWebsite from "../../interfaces/IWebsite";
import { ImageGridItem } from "./imageGridItem";

interface IProps {
    images: IImage[],
    website: IWebsite
}

export const ImagesGrid = (props: IProps) => {
    const { images, website } = props;

    return (
        <div className="row">
            {images.map(image => {
                return <ImageGridItem key={image.id} image={image} />
            })}
        </div>)
}