import IOptionData from "../components/inputs/IOptionData";
import IStorageFile from "../interfaces/IStorageFile";

export default class StorageFileHelper {

    public static getOptions(storageFiles: IStorageFile[]): IOptionData[] {
        const options: IOptionData[] = [];
        storageFiles.map(file =>
            options.push({ label: file.name, value: file.id })
        )
        return options;
    }
}