import { inject, observer } from 'mobx-react';
import LoadingScreen from '../../../components/screens/loadingScreen';
import IRouteComponentProps from '../../../interfaces/IRouteComponentProps';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import WebsiteStore from '../../../stores/WebsiteStore';
import BaseComponent from '../../../components/baseComponent';
import { PageTitle } from '../../../components/others/pageTitle';
import { WebsiteDocumentTitle } from '../../../utils/websiteDocumentTitle';
import Textarea from '../../../components/inputs/textarea';
import { BtnSubmit } from '../../../components/inputs/btnSubmit';

interface IState {
    loading: boolean,
    showCreateNewModal: boolean,
    css: string,
    saving: boolean,
}

interface IProps extends IRouteComponentProps<IMatchParams> {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
}

interface IMatchParams {
    websiteId: string,
}

@inject('currentUserStore', 'websiteStore')
@observer
export default class WebsiteEditCustomCss extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            showCreateNewModal: false,
            css: '',
            saving: false,
        }
    }

    componentDidMount() {
        const websiteId = this.props.match.params.websiteId;
        this.websitesApiService.getCss(websiteId)
            .then(css => {
                this.setState({
                    loading: false,
                    css: css
                })
            })
    }

    handleSave() {
        this.setState({
            saving: true
        })

        const websiteId = this.props.match.params.websiteId;
        this.websitesApiService.setCss(websiteId, this.state.css)
            .then(data => {
                this.setState({
                    saving: false
                })

                this.toastSuccess('Změny uloženy');
            })
    }

    renderContent() {
        return (
            <div className={`card`}>
                <div className="card-body">

                    <Textarea
                        value={this.state.css}
                        label=""
                        onChange={(value) => this.setState({ css: value })}
                        rows={30}
                    />

                </div>
            </div>)
    }

    render() {
        const loading = this.state.loading;
        const websiteId = this.props.match.params.websiteId;
        const website = this.props.websiteStore.getById(websiteId);

        return (
            <>
                <WebsiteDocumentTitle website={website} title="Vlastní CSS">
                    <div className="row align-items-center mb-4">
                        <div className="col-6">
                            <PageTitle
                                title="Vlastní CSS"
                                subtitle="V tomto souboru můžete přidat css styly do vašeho webu"
                            />
                        </div>
                        <div className="col-6">
                            <div className="dashboard-infor-mation d-flex flex-wrap align-items-center">
                                <div className="dashboard-btn-group d-flex align-items-center">
                                    <BtnSubmit loading={this.state.saving} disabled={loading} onClick={this.handleSave.bind(this)} className="btn btn-primary ml-1" > Uložit změny</BtnSubmit>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading ? <LoadingScreen /> : this.renderContent()}
                   
                </WebsiteDocumentTitle>
            </>
        )
    }
}