import { inject, observer } from "mobx-react";
import React from "react";
import GoogleApiKeySettings from "../../components/account/googleApiKeySettings";
import GoogleAuthSettings from "../../components/account/googleAuthSettings";
import BaseComponent from "../../components/baseComponent";
import InputText from "../../components/inputs/inputText";
import { PageTitle } from "../../components/others/pageTitle";
import LoadingScreen from "../../components/screens/loadingScreen";
import CurrentUserStore from "../../stores/CurrentUserStore";
import { CustomDocumentTitle } from "../../utils/customDocumentTitle";

interface IState {
   loading: boolean,
}

interface IProps {
    currentUserStore?: CurrentUserStore
}

@inject('currentUserStore')
@observer
export default class SettingsPage extends BaseComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading:true
        }
    }

    componentDidMount() {
        this.usersApiService.getCurrentUser()
            .then(data => {
                this.setState({
                    loading: false
                })
            })
    }

    render() {
        const user = this.props.currentUserStore.user;

        if (this.state.loading) {
            return <LoadingScreen />
        }

        return (
            <CustomDocumentTitle title={'Nastavení'} >
                <PageTitle title="Nastavení" subtitle="Vašeho účtu" />

                <div className="row mt-4">
                    <div className="col-6">
                        <GoogleAuthSettings user={user} />
                    </div>
                    <div className="col-6">
                        <GoogleApiKeySettings user={user} />
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title mb-20">Clonty Api</h5>
                                <InputText
                                    uid="gapiKey"
                                    label="Clonty Api Key"
                                    value={user.apiKey}
                                    readonly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        )
    }
}