import React from 'react';
import { Modal } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import ISection from '../../interfaces/ISection';
import CheckBox from '../inputs/checkBox';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';
import EditorConvertToHTML from '../others/editorConvertToHTML';

interface IState {
    section: ISection,
    loading: boolean,
    serverErrors: IFormError[]
}

interface IProps {
    section: ISection,
    onHide(): void,
}

export default class EditSectionModal extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputSlug: React.RefObject<InputText>;
    private inputLayout: React.RefObject<InputText>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            section: this.props.section,
            loading: false,
            serverErrors: []
        }

        this.inputName = React.createRef();
        this.inputSlug = React.createRef();
        this.inputLayout = React.createRef();
    }

    handleSubmit() {
        const inputs = [
            this.inputName,
            this.inputSlug
        ];

        const isValid = this.validateInputs(inputs);

        const sectionEdit = this.state.section;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.sectionsApiService.editSection(sectionEdit)
                .then(data => {
                    if (data.success) {
                        this.toastSuccess('Sekce byla upravena');
                        this.props.onHide();
                    }
                    else {
                        this.setState({
                            loading: false,
                            serverErrors: data.formErrors
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            section: {
                ...this.state.section,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleDoNothing() {

    }


    render() {
        const loading = this.state.loading;
        const sectionServer = this.props.section;
        const sectionEdit = this.state.section;
        const serverErrors = this.state.serverErrors;

        return (
            <Modal animation={false} centered={true} show size="lg" onHide={this.handleDoNothing.bind(this)}>
                <Modal.Header closeButton onHide={this.props.onHide.bind(this)}>
                    <Modal.Title>
                        <h5>Úprava sekce {sectionServer.name}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputText
                        uid="name"
                        ref={this.inputName}
                        label="Název"
                        onChange={this.handleChange.bind(this)}
                        value={sectionEdit.name}
                        maxLength={30}
                        serverErrors={serverErrors}
                    />

                    <InputText
                        uid="slug"
                        ref={this.inputSlug}
                        label="Část url"
                        onChange={this.handleChange.bind(this)}
                        value={sectionEdit.slug}
                        required
                        maxLength={30}
                        serverErrors={serverErrors}
                    />

                    <CheckBox
                        uid="primary"
                        label="Primární"
                        checked={sectionEdit.primary}
                        onChange={this.handleChange.bind(this)}
                    />

                    <EditorConvertToHTML
                        uid="description"
                        value={sectionEdit.description}
                        onChange={this.handleChange.bind(this)}
                    />

                    <InputText
                        uid="layout"
                        ref={this.inputLayout}
                        value={sectionEdit.layout}
                        label="Layout"
                        onChange={this.handleChange.bind(this)}
                    />

                    <InputText
                        uid="uid"
                        value={sectionEdit.uid}
                        label="UID"
                        onChange={this.handleChange.bind(this)}
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Uložit změny
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}