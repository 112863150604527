import React from 'react';
import { Modal } from 'react-bootstrap';
import { BtnSubmit } from '../inputs/btnSubmit';
import InputText from '../inputs/inputText';
import BaseComponent from '../baseComponent';
import IFormError from '../forms/IFormError';
import { filter } from 'lodash';
import EditorConvertToHTML from '../others/editorConvertToHTML';
import ICategory from '../../interfaces/ICategory';

interface IState {
    category: ICategory,
    loading: boolean,
    serverErrors: IFormError[]
}

interface IProps {
    category: ICategory,
    onHide(): void,
}

export default class EditCategoryModal extends BaseComponent<IProps, IState> {

    private inputName: React.RefObject<InputText>;
    private inputSlug: React.RefObject<InputText>;


    constructor(props: IProps) {
        super(props);

        this.state = {
            category: this.props.category,
            loading: false,
            serverErrors: []
        }

        this.inputName = React.createRef();
        this.inputSlug = React.createRef();
    }

    handleSubmit() {
        const inputs = [
            this.inputName,
            this.inputSlug
        ];

        const isValid = this.validateInputs(inputs);

        const categoryEdit = this.state.category;
        const categoryServer = this.props.category;

        if (isValid) {
            this.setState({
                loading: true
            })

            this.categoriesApiService.editCategory(categoryServer.id, categoryEdit)
                .then(data => {
                    if (data.success) {
                        this.toastSuccess('Kategorie byla upravena');
                        this.props.onHide();
                    }
                    else {
                        this.setState({
                            loading: false,
                            serverErrors: data.formErrors
                        })
                        this.validateInputs(inputs);
                    }
                })
        }
    }

    handleChange(value: any, key: string) {
        this.setState({
            category: {
                ...this.state.category,
                [key]: value
            },
            serverErrors: filter(this.state.serverErrors, error => { if (error.key !== key) return true; else return false })
        })
    }

    handleDoNothing() {

    }


    render() {
        const loading = this.state.loading;
        const categoryServer = this.props.category;
        const categoryEdit = this.state.category;
        const serverErrors = this.state.serverErrors;

        return (
            <Modal animation={false} centered={true} show size="lg" onHide={this.handleDoNothing.bind(this)}>
                <Modal.Header closeButton onHide={this.props.onHide.bind(this)}>
                    <Modal.Title>
                        <h5>Úprava kategorie {categoryServer.name}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputText
                        uid="name"
                        ref={this.inputName}
                        label="Název"
                        onChange={this.handleChange.bind(this)}
                        value={categoryEdit.name}
                        maxLength={30}
                        serverErrors={serverErrors}
                    />

                    <InputText
                        uid="slug"
                        ref={this.inputSlug}
                        label="Část url"
                        onChange={this.handleChange.bind(this)}
                        value={categoryEdit.slug}
                        required
                        maxLength={30}
                        serverErrors={serverErrors}
                    />

                    <EditorConvertToHTML
                        uid="description"
                        value={categoryEdit.description}
                        onChange={this.handleChange.bind(this)}
                    />

                    <BtnSubmit
                        loading={loading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        Uložit změny
                    </BtnSubmit>
                </Modal.Body>
            </Modal>
        )
    }
}