

export const WebsitePendingSetupScreen = () => {
    return (
        <div className="card mt-0">
            <div className="card-body">
                <div className="py-5">
                    <div className="text-center">
                        <h3>Probíhá nastavování vašeho webu</h3>
                    </div>
                    <div className="text-center">
                        <p>Proces může trvat několik minut. Prosím, vraťte se později.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}