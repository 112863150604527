import { observable, action,  makeObservable } from 'mobx'
import IUser from '../interfaces/IUser';

export default class CurrentUserStore {

      user: IUser;

    setCurrentUser(user: IUser) {
        if (user) {
            this.user = user;
            // ErrorReporter.setUser(user);
        }
    }

    logout() {
        this.user = null;
    }

    constructor() {
        makeObservable(this, {
            user: observable,
            setCurrentUser: action,
            logout: action
        })
    }
}